export * from "./AuthContext";
export * from "./MainContext";
export * from "./PageContext";

// overrides WeakMapping of Object "private" properties and exposes them
export type PublicInterface<T> = {
  [P in keyof T]: T[P];
};

export function formatUnderscoreText(text: string, isDashName: boolean) {
  if (text) {
    let formattedText = text
      .split("_")
      .map((word: string | undefined) => (word ? ((word = word[0].toUpperCase() + word.slice(1)), word) : word));

    if (isDashName) {
      return formattedText.slice(1).join("");
    } else {
      return formattedText.join(" ");
    }
  } else {
    return "";
  }
}

export enum WidgetType {
  BUTTON = "button",
  CARD = "card",
  CARD_LIST = "cardList",
  INFO_CARD = "infoCard",
  E_CHART = "echart",
  CHART_CONTAINER = "chartContainer",
  CONTROL_GROUP = "controlGroup",
  CALENDAR = "calendar",
  DATE_PICKER = "datepicker",
  DEID_ACTION_BUILDER = "deidActionBuilder",
  DEID_FILTER_BUILDER = "deidFilterBuilder",
  DIVIDER = "divider",
  DRAWER = "drawer",
  EDITOR = "editor",
  ERROR = "error",
  ERROR_WIDGET = "errorWidget",
  FORM = "form",
  FLOWCHART = "flowchart",
  CRUD_FORM = "crudForm",
  HIDDEN = "hidden",
  HTML = "html",
  HTML2 = "html2",
  MARKDOWN = "markdown",
  MENUS = "menus",
  NAVBAR = "navBar",
  NUMBER_RANGE_PICKERS = "numberRangePicker",
  DIAGRAM = "diagram",
  PYTHON = "python",
  QUERY_BUILDER = "queryBuilder",
  VALIDATION_BUILDER = "validationBuilder",
  JSON = "json",
  SEARCH = "search",
  SELECT = "select",
  MULTI_SELECT = "multiselect",
  SLIDER = "slider",
  HTMLTABLE = "simpleTable",
  STATISTIC = "statistic",
  SWITCH = "SWITCH",
  TABLE = "TABLE",
  TITLE = "title",
  TIMELINE = "timeline",
  TOKEN_INPUT = "tokenInput",
  VARIABLES = "variables",
  WIDGETS = "widgets",
  WORKFLOW = "workflow",
  URL = "url",
  OVERLAY = "overlay",
  DIALOG = "dialog",
  GRAPHVIZ = "graphViz",
  SOCKET = "socket",
  TREE = "tree",
  TREEGRAPH = "treegraph",
  ROUTER_RULE_CONDITIONS = "routerRuleConditions",
  TOKEN = "token",
  NAMESPACE = "namespace",
}

export interface ILayout {
  i: string;
  x: number;
  y: number;
  w: number;
  h: number;
}

export interface IDashboardCell {
  book: string;
  tag: string;
  layout?: ILayout;
  gridName?: string;
  title?: string;
  footer?: Object[];
  content?: any;
  type?: WidgetType;
  variableNames?: string[];
  publisherName?: string;
  id?: number;
  widgetView?: boolean;
  loading: boolean;
  error?: string | null;
  style?: any;
}
