import React from "react";
import { Tabs, Tab } from "@blueprintjs/core";

import { WidgetLoader } from "../WidgetController";
import { useUpdateURLTabParam } from "../../routing";

export const HtmlTab = (key: string, attrs: any, children: Object[]) => {
  const updateURLTabParam = useUpdateURLTabParam();
  console.log("tab attrs: ", { key, attrs, children });

  const items = children.filter((item: any) => item["type"] === "tag");
  const panel = (attrs: any) => {
    if (attrs.notebook && attrs.cell) {
      return <WidgetLoader notebook={attrs.notebook} cell={attrs.cell} params={attrs.params} />;
    }
    return <b>Not Implemented Yet!!!</b>;
  };

  return (
    <Tabs key={key} id={attrs.id} selectedTabId={attrs["activeTabId"]} onChange={updateURLTabParam}>
      {items.map((t: any, idx: number) => {
        // TODO: clean up this widget loading pattern, 2 widget loader calls per tab render and rerenders entire page....
        const { activeTabId } = attrs
        const { id: currentTabId, params: eicDataAttrs } = t.attribs
        const loadedPanel = activeTabId === currentTabId ? panel(t.attribs) : <div>Loading</div>;
        return <Tab key={key + idx} id={t.attribs.id} title={t.attribs.title} panel={loadedPanel} data-eic-attrs={eicDataAttrs}/>;
      })}
    </Tabs>
  );
};
