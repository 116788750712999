import React, { useState } from "react";
import { Card, Intent, Elevation, FormGroup, InputGroup } from "@blueprintjs/core";
import { useHistory } from "react-router-dom";

import { useAuthDispatch, useAuthState, TLoginDispatchAction, LoginActions } from "../state";
import { versionNum, buildName } from "./";
import logo from "../images/eicon_atom_circ_logo.svg";
import { AppToaster } from "../App";
import { KeyedButton } from "../widgets";

// rename to login
// display error message
// make submit into redirect instead of programmatically routing?
export const SignOn = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [hidden] = useState(true);

  const { client } = useAuthState();
  const dispatch = useAuthDispatch();

  const history = useHistory();

  const handleNameChange = (e: any) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e: any) => {
    setPassword(e.target.value);
  };

  const handleSignOn = () => {
    client
      .login(username, password)
      .then(({ data }: any) => {
        const ACCEPT_LOGIN: TLoginDispatchAction = { type: LoginActions.LOGIN, payload: data };
        dispatch(ACCEPT_LOGIN);
        // TODO: Pull home page from API for user
        history.push("/Page/Staging:Cans_Resoundant/Cans");
      })
      .catch((err: Error) => {
        const REJECT_LOGIN: TLoginDispatchAction = { type: LoginActions.ERROR, payload: err };
        dispatch(REJECT_LOGIN);
        AppToaster.show({ message: "Incorrect Username or Password", intent: Intent.DANGER });
      });
  };

  return (
    <section className="form-wrapper">
      <Card interactive={false} elevation={Elevation.TWO} className="form-component">
        <div className="eic-card-logo-wrapper">
          <img className="eic-logo-img" src={logo} alt="eicon" />
          <div className="eic-logo-text-wrapper">
            <div className="eic-logo-text">
              <span className="e-footer-logo">RE</span>ACH
            </div>
          </div>
        </div>
        <div className="eic-card-form-component">
          <div className="eic-card-form-container">
            <FormGroup
              // helperText="Please input your username"
              // label="Username"
              labelFor="username-input"
              labelInfo="*"
            >
              <InputGroup
                value={username}
                onChange={handleNameChange}
                id="username-input"
                placeholder="Username"
                leftIcon="user"
                intent="primary"
                className="eic-inp-text"
              />
            </FormGroup>
            <FormGroup
              // helperText="Please input your username"
              // label="Password"
              labelFor="password-input"
              labelInfo="*"
            >
              <InputGroup
                value={password}
                type={hidden ? "password" : "text"}
                onChange={handlePasswordChange}
                id="password-input"
                placeholder="Password"
                leftIcon="key"
                intent="primary"
                className="eic-inp-text"
              />
            </FormGroup>
            <KeyedButton
              intent={Intent.PRIMARY}
              fill={true}
              large={true}
              text={"SIGN IN"}
              type="submit"
              className="eic-h3-text"
              onClick={() => handleSignOn()}
              actionLabel="Log In"
              keySequence="enter"
              allowInInput={true}
              isAnchor={true}
            />
            {/* <Button
              intent={Intent.PRIMARY}
              fill={true}
              large={true}
              text={"SIGN IN"}
              type="submit"
              className="eic-h3-text"
              onClick={() => handleSignOn()}
              disabled={password !== "" && username !== "" ? false : true}
            /> */}
          </div>
          <div style={{ textAlign: "center" }}>
            <span style={{ fontWeight: 600 }}>
              <span style={{ color: "#06A9F4" }}>RE</span>ACH
            </span>{" "}
            Ver. {versionNum} | BUILD: {buildName} &copy;{" "}
            <span style={{ fontWeight: 500 }}>
              <span style={{ color: "#06A9F4" }}>E</span>ICON
            </span>{" "}
            2022
          </div>
        </div>
      </Card>
    </section>
  );
};
