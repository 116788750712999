import React, { useState } from "react";
import classNames from "classnames";
import { Overlay, Classes } from "@blueprintjs/core";

export interface IOverlayProps {
  id: string;
  content: IOverlayContent;
}

export interface IOverlayContent {
  title?: string;
  widgets: Object[];
}

export interface IOverlayState {
  autoFocus: boolean;
  canEscapeKeyClose: boolean;
  canOutsideClickClose: boolean;
  enforceFocus: boolean;
  hasBackdrop: boolean;
  isOpen: boolean;
  usePortal: boolean;
  useTallContent: boolean;
}

export function OverlayWidget(prop: IOverlayProps) {
  const [state] = useState({
    autoFocus: true,
    canEscapeKeyClose: true,
    canOutsideClickClose: true,
    enforceFocus: true,
    hasBackdrop: false,
    isOpen: true,
    usePortal: true,
    useTallContent: false,
  });

  const classes = classNames(Classes.POPOVER);

  return (
    <Overlay className={Classes.OVERLAY_SCROLL_CONTAINER} {...state}>
      <div className={classes}>{prop.content.widgets}</div>
    </Overlay>
  );
}
