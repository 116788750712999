import React, { useEffect, useMemo } from "react";
import { Button, Classes, Drawer, IconName, IDrawerProps, MaybeElement } from "@blueprintjs/core";

import {
  ElementStateLookup,
  Theme,
  useAuthState,
  useMainState,
  usePageNamespaceData,
  usePageNamespaceDataCB,
} from "../state";
import { WidgetController } from "./WidgetController";
import { CardListWidget, useTableContextData } from ".";

import "./styling/ListStyling.css";
import { ButtonWidget } from "./ButtonWidget";
import { useQuery } from "react-query";

interface DrawerOptions {
  position: IDrawerProps["position"];
  size: IDrawerProps["size"];
}

interface NBCallback {
  notebook: string;
  cell: string;
  params: any;
}

interface DataproviderID {
  dataProviderType: ElementStateLookup;
  dataProviderID: string;
}

interface DrawerComponentBody extends DataproviderID, NBCallback {
  data?: any[];
}

export interface DrawerComponentContent {
  title: any[] | string;
  body: DrawerComponentBody;
  footer: any[];
  defaultIsOpen: boolean;
  options?: Partial<DrawerOptions>;
  style?: Partial<React.CSSProperties>;
  titleIcon?: IconName | MaybeElement;
}

export interface DrawerComponentProps {
  id: string;
  content: Partial<DrawerComponentContent>;
}

function useDrawerData(id: string, notebook: string, cell: string, params: any, dataProviderData: any) {
  const { client } = useAuthState();
  const drawerData = useQuery(["drawerData", id, notebook, cell, params, dataProviderData], async () => {
    return await client.newExecNb(notebook, cell, { params, data: dataProviderData["data"]["data"] });
  });
  return drawerData;
}

export function DrawerComponent({ id, content }: DrawerComponentProps) {
  const { theme } = useMainState();
  const namespaceDatumGetter = usePageNamespaceDataCB();
  const { options, defaultIsOpen, style, title, footer, body, titleIcon } = content;

  // // for dev purposes:
  // const [data] = useTableContextData("Results", "")
  // const listDataEntries: any = Object.entries(data)
  const { notebook, cell, params, data: rawData, dataProviderID, dataProviderType } = body!;
  let dataProviderData = useMemo(() => {
    const rc: any = { data: undefined };
    try {
      rc["data"] = namespaceDatumGetter<any>(dataProviderID, dataProviderType)[0];
    } catch (err: any) {
      rc["data"] = rawData;
    }
    return rc;
  }, [dataProviderID, dataProviderType, namespaceDatumGetter, rawData]);

  const { data, isLoading, status } = useDrawerData(id, notebook, cell, params, dataProviderData);

  console.log("drawer:", { id, data, status });
  const [drawerOpen, setDrawerState, destroyState] = usePageNamespaceData<boolean>(
    id,
    ElementStateLookup.DRAWER_STATE,
    !!defaultIsOpen,
  );
  useEffect(() => () => destroyState, [destroyState]);

  return (
    <Drawer
      className={theme === Theme.DARK ? Classes.DARK : undefined}
      isOpen={drawerOpen}
      position={options?.position}
      size={options?.size}
      style={style}
      title={typeof title === "string" ? title : undefined}
      icon={titleIcon}
      onClose={() => setDrawerState(!drawerOpen)}
    >
      {typeof title === "object" && (Array.isArray(title) ? title.map(WidgetController) : WidgetController(title))}
      {/* {typeof body !== "undefined" && (
        <section className={Classes.DRAWER_BODY}>
          {body.map((el) => WidgetController({ cell: el }))}
        </section>
      )} */}
      <section className={Classes.DRAWER_BODY + " Sidebar"} style={{ display: "block" }}>
        {data && !isLoading && Array.isArray(data) && data.map((d: any, idx: number) => WidgetController({ cell: d }))}
      </section>
      {/* <section className={Classes.DRAWER_BODY + " Sidebar"} style={{ display: "block" }}>
        {listDataEntries.map(([queryId, selectedRows]: [string, any], idx: number) => (
          <CardListWidget
            id="test"
            content={{
              listHeader: {
                content: "Cohorts " + (idx + 1),
              },
              listItems: selectedRows,
              sortable: true,
              cardHeight: 140,
              options: { minimal: true },
              wrapperOptions: { visibleItemCount: 2 }
            }}
          />
        ))}
      </section> */}
      {/* <section className={Classes.DRAWER_BODY + " Sidebar"}>
        <div className="ListContainer">
          <div className="ListHeader"></div>
          <div className="ListBody">
            <div className="Card-1">test</div>
            <div className="Card-2">test</div>
            <div className="Card-3">test</div>
          </div>
        </div>
      </section> */}
      {typeof footer !== "undefined" && (
        <footer className={Classes.DRAWER_FOOTER}>
          {typeof footer === "object" &&
            (Array.isArray(footer) ? footer.map(WidgetController) : WidgetController(footer))}
        </footer>
      )}
    </Drawer>
  );
}
