import { useCallback, useState } from "react";

/** Event handler that exposes the target element's value as a boolean. */
export function handleBooleanChange(handler: (checked: boolean) => void) {
  return (event: React.FormEvent<HTMLElement>) => handler((event.target as HTMLInputElement).checked);
}

/** Event handler that exposes the target element's value as a string. */
export function handleStringChange(handler: (value: string) => void) {
  return (event: React.FormEvent<HTMLElement>) => handler((event.target as HTMLInputElement).value);
}

/** Event handler that exposes the target element's value as an inferred generic type. */
export function handleValueChange<T>(handler: (value: T) => void) {
  return (event: React.FormEvent<HTMLElement>) => handler(((event.target as HTMLInputElement).value as unknown) as T);
}

/** Event handler that exposes the target element's value as a number. */
export function handleNumberChange(handler: (value: number) => void) {
  return handleStringChange((value) => handler(+value));
}

export function checkObjNotEmpty(obj: Record<string, any>) {
  for (let i in obj) return true;
  return false;
}

// Hook
export function useLocalStorage<T>(key: string, initialValue: T) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = useCallback(
    (value: any) => {
      try {
        // Save state
        setStoredValue(value);
        // Save to local storage
        window.localStorage.setItem(key, JSON.stringify(value));
      } catch (error) {
        // A more advanced implementation would handle the error case
        console.log(error);
      }
    },
    [key],
  );

  return [storedValue, setValue];
}

export function useClearUIStateLocalStorage() {
  // TODO: implement configurable include/exclude from destruction
  return () => {
    const keys = Object.keys(window.localStorage);
    console.log("lcl storage: ", keys);

    keys.forEach((k) => {
      if (k.includes(":")) {
        window.localStorage.removeItem(k);
      }
    });
    console.log("lcl storage after cleanup: ", Object.keys(window.localStorage));
  };
}

export function ConditionalWrapper({ condition, wrapper, children }: any) {
  return condition ? wrapper(children) : children;
}
