import React from "react";
import { useImmer } from "use-immer";
import { Tree, ITreeNode, Label, Classes } from "@blueprintjs/core";
export interface ITreeProps {
  content: ITreeContent;
  id: string;
}
export interface ITreeContent {
  contents: ITreeNode[];
  defaultOpenNodes?: number[][];
  onNodeClick: boolean;
  callback?: string | Function;
  field: string;
  title?: string;
  className: string;
  multiselect: boolean;
}
export function TreeWidget(props: ITreeProps) {
  const [selectedNode, setSelectedNode] = useImmer({} as ITreeNode);
  const [treeState, setTreeState] = useImmer(props.content.contents);
  const forEachNode = (nodes: ITreeNode[] | undefined, callback: (node: ITreeNode) => void) => {
    if (nodes === null) {
      return;
    } else {
      if (nodes !== undefined) {
        for (const node of nodes) {
          callback(node);
          forEachNode(node.childNodes, callback);
        }
      }
    }
  };

  const handleNodeExpand = (nodeData: ITreeNode, flag: boolean) => {
    setTreeState((draftState) => {
      forEachNode(draftState, (node) => {
        if (node.id === nodeData.id) {
          node.isExpanded = flag;
        }
      });
    });
  };

  function onNodeClick(nodeData: ITreeNode) {
    setSelectedNode((draft) => nodeData);
    setTreeState((draftState) => {
      forEachNode(draftState, (node) => {
        if (node.id === nodeData.id) {
          node.isSelected = true;
        } else {
          node.isSelected = false;
        }
      });
    });
  }

  return (
    <div className="eic-tree">
      <Label>
        {selectedNode ? selectedNode.id : ""}
        <Tree
          className={Classes.ELEVATION_1}
          contents={treeState}
          onNodeClick={onNodeClick}
          onNodeExpand={(node) => handleNodeExpand(node, true)}
          onNodeCollapse={(node) => handleNodeExpand(node, false)}
        />
      </Label>
    </div>
  );
}
