import React, { ReactNode } from "react";
import { MenuItem, Intent, Button } from "@blueprintjs/core";
import { MultiSelect } from "@blueprintjs/select";
import { useImmer } from "use-immer";

export interface IMultiSelectOptions {
  allowCreate: boolean;
  createdItems: [];
  fill: boolean;
  hasInitialContent: boolean;
  intent: boolean;
  items: [];
  openOnKeyDown: boolean;
  popoverMinimal: boolean;
  resetOnSelect: boolean;
  tagMinimal: boolean;
}

export interface IMultiselectProps {
  content: IMultiselectContent;
  id: string;
  title?: string;
}

export interface IMultiselectContent {
  field: string;
  items: any[];
  label?: string;
  cb?: any;
  value?: any;
  placeholder?: string;
  // add options as an optional field here
  // properties that can be sent from server:
  // fill -> boolean
  // intent -> Intent | string
  // popoverMinimal -> boolean
  // tagMinimal -> boolean
  // resetOnSelect -> boolean
  // hasInitialContent -> boolean
}

const defaultOptions: IMultiSelectOptions = {
  allowCreate: false,
  createdItems: [],
  fill: false,
  hasInitialContent: false,
  intent: false,
  items: [],
  openOnKeyDown: false,
  popoverMinimal: true,
  resetOnSelect: true,
  tagMinimal: false,
};

export function MultiSelectWidget(props: IMultiselectProps) {
  const {
    id,
    content: { items: propsItems, value, placeholder, cb},
  } = props;
  const formattedValue = value !== undefined ? JSON.parse(value) : undefined;
  const [items, setItems] = useImmer(formattedValue !== undefined ? formattedValue : ([] as any[]));
  // const [createdItems, setCreatedItems] = useImmer([])

  const { allowCreate, hasInitialContent, tagMinimal, popoverMinimal, ...restOptions } = defaultOptions;

  // const allowCreate = propsCreateFlag !== undefined ? propsCreateFlag : defaultCreateFlag


  function handleItemSelect(item: any) {
    if (!isItemSelected(item)) {
      selectItem(item);
    } else {
      deselectItem(item);
    }
  }

  function handleClearSelect() {
    setItems((draft) => {
      draft = [];
      return draft;
    });
    if (cb !== undefined) {
      cb([]);
    }
  }

  function getSelectedItemIndex(item: any) {
    let selectedIdx = -1;
    for (let i = 0; i < items.length; i++) {
      if (items[i].key === item.key) {
        selectedIdx = i;
        break;
      }
    }
    return selectedIdx;
  }

  function isItemSelected(item: any) {
    return getSelectedItemIndex(item) !== -1;
  }

  function selectItem(item: any) {
    setItems((draft) => {
      draft.push(item);
      if (cb !== undefined) {
        cb(JSON.parse(JSON.stringify(draft)));
      }
    });
  }

  function deselectItem(item: any) {
    setItems((draft) => {
      draft = draft.filter((currentItem: any) => currentItem.key !== item.key);
      if (cb !== undefined) {
        cb(JSON.parse(JSON.stringify(draft)));
      }
      return draft;
    });
  }

  function renderTag(item: any) {
    return item.value;
  }

  function handleTagRemove(tag_value: ReactNode, index: number) {
    deselectItem(items[index]);
  }

  const getTagProps = (_value: any, index: any) => ({
    intent: Intent.PRIMARY,
    minimal: true,
  });

  const clearButton = items.length > 0 ? <Button icon="cross" minimal={true} onClick={handleClearSelect} /> : undefined;

  // const searchIcon = items.length === 0 ? <Icon icon="search" /> : undefined

  function renderItem(item: any, { modifiers, handleClick }: any) {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    return (
      <MenuItem
        active={modifiers.active}
        icon={isItemSelected(item) ? "tick" : "blank"}
        key={item.key}
        label={item.label}
        onClick={handleClick}
        text={item.value}
        shouldDismissPopover={false}
      />
    );
  }

  return (
    <MultiSelect
      {...restOptions}
      itemRenderer={renderItem}
      items={propsItems}
      noResults={<MenuItem disabled={true} text="No results." />}
      onItemSelect={handleItemSelect}
      popoverProps={{ minimal: popoverMinimal }}
      tagRenderer={renderTag}
      tagInputProps={{
        onRemove: handleTagRemove,
        rightElement: clearButton,
        tagProps: getTagProps,
        // leftIcon: searchIcon
      }}
      selectedItems={items}
      fill
      placeholder={placeholder}
    />
  );
}
