import React from "react";
import { Link, useHistory } from "react-router-dom";

interface LinkWrapperProps {
  text: any;
  attribs: any;
}

export function LinkWrapper({ attribs: propsAttribs, text }: LinkWrapperProps) {
  const history = useHistory();
  const attribs = { ...propsAttribs };
  if (propsAttribs.to === "history://back") {
    attribs.href = propsAttribs.to;
    attribs.to = undefined;
    attribs.onClick = (e: any) => {
      e.preventDefault();
      history.goBack();
    };
  }

  return <Link {...attribs}>{text}</Link>;
}
