/* eslint-disable no-useless-escape */
import { Icon, IconName, Intent, Menu, MenuItem, Tag, Tooltip } from "@blueprintjs/core";
import { ISelectProps, ItemListRenderer, ItemPredicate, ItemRenderer, Select, Suggest } from "@blueprintjs/select";
import { FixedSizeList as List } from "react-window";
import React, { CSSProperties } from "react";

export type AutoCompleteTuple = [string, string];
export const StringTupleAutoComplete = Suggest.ofType<AutoCompleteTuple>();

// string tuple array autocomplete suggest type utils
export function areTagsEqual(tagA: AutoCompleteTuple, tagB: AutoCompleteTuple) {
  // Compare only the titles (ignoring case) just for simplicity.
  if (
    tagA[0].toLowerCase() === tagB[0].toLowerCase() &&
    tagA[0] !== "Unstructured Search Term" &&
    tagA.indexOf("Private Tag") === -1
  ) {
    return true;
  }
  if (tagA[1].toLowerCase() === tagB[0].toLowerCase()) {
    return true;
  }
  return false;
}

export function arrayContainsItem(items: AutoCompleteTuple[], itemToFind: AutoCompleteTuple): boolean {
  return items.some((item) => areTagsEqual(item, itemToFind));
}

export const filterItem: ItemPredicate<AutoCompleteTuple> = (query, item, _index, exactMatch) => {
  const normalizedTitle = item[1].toLowerCase();
  const normalizedTagName = item[0].toLowerCase();
  const normalizedQuery = query.toLowerCase();
  if (exactMatch) {
    return normalizedTitle === normalizedQuery || normalizedTagName === normalizedQuery;
  }
  return `${normalizedTagName} ${normalizedTitle}`.indexOf(normalizedQuery) >= 0;
};
export function escapeRegExpChars(text: string) {
  return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
}

export function highlightText(text: string, query: string) {
  let lastIndex = 0;
  const words = query
    .split(/\s+/)
    .filter((word) => word.length > 0)
    .map(escapeRegExpChars);
  if (words.length === 0) {
    return [text];
  }
  const regexp = new RegExp(words.join("|"), "gi");
  const tokens: React.ReactNode[] = [];
  while (true) {
    const match = regexp.exec(text);
    if (!match) {
      break;
    }
    const length = match[0].length;
    const before = text.slice(lastIndex, regexp.lastIndex - length);
    if (before.length > 0) {
      tokens.push(before);
    }
    lastIndex = regexp.lastIndex;
    tokens.push(
      <strong key={lastIndex} style={{ color: "#F4EA49", fontWeight: 800 }}>
        {match[0]}
      </strong>,
    );
  }
  const rest = text.slice(lastIndex);
  if (rest.length > 0) {
    tokens.push(rest);
  }
  return tokens;
}

// select, suggest, etc renderers
export const renderItem: ItemRenderer<AutoCompleteTuple> = (itemTuple, { handleClick, modifiers, query, index }) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }
  return (
    <MenuItem
      active={modifiers.active}
      disabled={modifiers.disabled}
      labelElement={highlightText(itemTuple[0], query)}
      // key={itemTuple.join("_") + "_" + index}
      onClick={handleClick}
      text={highlightText(itemTuple[1], query)}
      style={{ fontWeight: 400 }}
    />
  );
};

export const renderMenu: ItemListRenderer<AutoCompleteTuple> = (itemListProps) => {
  const { filteredItems, itemsParentRef, query, renderItem: renderFn, renderCreateItem } = itemListProps;
  return (
    <Menu ulRef={itemsParentRef}>
      <MenuItem
        disabled={true}
        text={`Found ${filteredItems.length} items` + (query !== "" ? ` matching "${query}"` : "")}
      />
      <List
        height={filteredItems.length > 5 ? 150 : 30 * filteredItems.length}
        itemCount={filteredItems.length}
        itemSize={30}
        width={300}
      >
        {({ index, style }: any) => <div style={style}>{renderFn(filteredItems[index], index)}</div>}
      </List>
      {query !== "" && renderCreateItem()}
    </Menu>
  );
};

export function renderInputValue(itemTuple: [string, string]) {
  if (itemTuple[1] === "") {
    return itemTuple[0];
  }
  return itemTuple[1];
}

export function renderCreateNewItem(query: string, active: boolean, handleClick: React.MouseEventHandler<HTMLElement>) {
  console.log("in renderCreateNewItem", { handleClick });
  return (
    <>
      <MenuItem
        icon="add"
        text={`Create "${query}"`}
        active={active}
        onClick={handleClick}
        shouldDismissPopover={true}
      />
    </>
  );
}

// vanilla Select dropdown with tag option renderer

export interface SelectOption {
  text: string;
  value?: any;
  disabled?: boolean;
  intent?: Intent;
  bgColor?: string;
  style?: Partial<CSSProperties>
  info?: string;
  icon?: IconName;
  large?: boolean;
  minimal?: boolean;
  round?: boolean;
  outlined?: boolean;
  fill?: boolean;
}

export interface BaseSelectWidgetStyleOptions extends Partial<ISelectProps<SelectOption>> {
  large?: boolean;
  minimal?: boolean;
  outlined?: boolean;
  intent?: Intent;
  icon?: IconName;
  rightIcon?: IconName;
  fill?: boolean;
}

export const SelectPrimitive = Select.ofType<SelectOption>();


export const renderSelectOption: ItemRenderer<SelectOption> = (selectOption, itemProps) => {
  const { intent, text, value, bgColor, minimal, large, fill, info } = selectOption;
  const { handleClick, modifiers } = itemProps;
  return (
    <MenuItem
      active={modifiers.active}
      disabled={modifiers.disabled}
      key={"SELECT_MENU_OPTION__" + (value ?? text)}
      onClick={() => handleClick(selectOption as any)}
      style={{ padding: "5px 12px" }}
      labelElement={
        typeof info !== "undefined" ? (
          <Tooltip content={info} intent={intent} placement="bottom" minimal={minimal} usePortal={true}>
            <Icon icon="info-sign" style={{ paddingTop: "4px", paddingLeft: "2px" }} />
          </Tooltip>
        ) : undefined
      }
      text={
        <Tag
          intent={intent}
          minimal={minimal}
          large={large}
          fill={fill}
          style={{ backgroundColor: bgColor, textAlign: "center" }}
        >
          {text}
        </Tag>
      }
    />
  );
};

export const renderSelectMenu: ItemListRenderer<SelectOption> = ({ items, itemsParentRef, renderItem }) => {
  const renderedItems = items.map(renderItem).filter((item) => item != null);
  const large = items[0].large;
  return (
    <Menu ulRef={itemsParentRef} large={large} style={{ minWidth: large ? "120px" : "96px", width: "max-content" }}>
      {renderedItems}
    </Menu>
  );
};