import React, { useCallback, useEffect } from "react";
import { useTable, usePagination, useRowSelect, useSortBy, Row } from "react-table";
import { HTMLTable, Classes, Icon, Spinner, Intent } from "@blueprintjs/core";

import { ElementStateLookup, Theme, usePage, usePageDispatch, usePageNamespaceData } from "../state";
import { useHistory } from "react-router";
import produce from "immer";
import { checkObjNotEmpty, useLocalStorage } from "../Utils";
import { useReplaceParams } from "../routing";
import { ErrorWidget } from ".";
import { MemodCheckbox, PaginationToolbar, renderCell } from "./table/TableTools";

import "./styling/Table.css";

interface SelectedRowIds {
  [key: number]: boolean;
}

interface ServerTableMetadata {
  pageSize: number;
  pageIndex: number;
  totalItems: number;
  pageCount: number;
  notebook: string;
  cell: string;
  sortBy?: any;
}

export interface TableWidgetProps {
  cols: any;
  rows: any;
  name?: string;
  callback?: any;
  theme: Theme;
  checkbox?: boolean;
  defaultcheckall?: boolean;
  id?: string;
  pagingData?: ServerTableMetadata;
  uniqueIdColumnName?: string;
}

const onCellClick = (cell: any, callback: any, history: any) => {
  if (callback) {
    let queryParams = "?";
    if (callback.type === "row") {
      queryParams = queryParams + callback.accessors.map((a: string) => a + "=" + cell.row.original[a]).join("&");
    }
    history.push(callback.notebook + "/" + callback.cell + queryParams);
  }
};

const onRowClick = (row: any, history: any) => {
  console.log("row!", row);
  if (row.original["onrow"]) {
    history.push(row.original["onrow"]);
  }
};

function TableComponent({
  setControlledTableMetadataLocalStorage,
  updateTableMetadataServerState,
  controlledTableMetadata,
  uniqueIdColumnName,
  columns,
  data,
  style,
  bodyStyle,
  callback,
  theme,
  checkbox,
  id,
  updateSelectedRows,
  selectedRows,
  setTableStateContext,
}: any) {
  const tableData: any = {
    columns,
    data,
    initialState: {
      selectedRowIds:
        checkbox && id && selectedRows
          ? selectedRows.reduce((acc: Record<string, true>, el: any) => {
              acc[el["origRowIdx"]] = true;
              return acc;
            }, {})
          : {},
    },
  };

  if (checkObjNotEmpty(controlledTableMetadata)) {
    console.log("controlled table meta: ", controlledTableMetadata);
    Object.entries(controlledTableMetadata).forEach(([tMetadataKey, tMetadataVal]) => {
      if (["pageIndex", "pageSize"].includes(tMetadataKey)) {
        tableData.initialState[tMetadataKey] = tMetadataVal;
      } else if (tMetadataKey === "sortBy" && Array.isArray(tMetadataVal) && tMetadataVal.length > 0) {
        tableData.initialState[tMetadataKey] = tMetadataVal;
      } else {
        tableData[tMetadataKey] = tMetadataVal;
      }
      console.log("setting pagination: ", tMetadataKey, tMetadataVal);
    });
    if (!controlledTableMetadata["clientPagination"]) {
      tableData["autoResetPage"] = false;
      tableData["autoResetSortBy"] = false;
    }
    // if (controlledTableMetadata["clientSortBy"]) {
    //   tableData["manualSortBy"] = false;
    //   tableData["autoResetSortBy"] = false;
    // }
    console.log("pre table init, table meta: ", tableData)
  }

  const checkBoxTable =
    checkbox && id
      ? [
          useRowSelect,
          (hooks: any) => {
            hooks.visibleColumns.push((columns: any) => [
              {
                id: "_selection",
                Header: ({ getToggleAllRowsSelectedProps }: any) => (
                  <div>
                    <MemodCheckbox {...getToggleAllRowsSelectedProps()} />
                  </div>
                ),
                Cell: ({ row }: any) => (
                  <div>
                    <MemodCheckbox {...row.getToggleRowSelectedProps()} />
                  </div>
                ),
              },
              ...columns,
            ]);
          },
        ]
      : [];

  const history = useHistory();
  const tableInst = useTable<any>(tableData, useSortBy, usePagination, ...checkBoxTable);

  console.log("post init, table meta:", tableInst)
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, state } = tableInst;
  const {pageSize, pageIndex} = state as any;
  const tableOpts = tableInst as any;
  let page = tableOpts.page as Row[];
  const startRowIdx = pageIndex * pageSize;
  const endRowIdx = startRowIdx + pageSize;
  if (controlledTableMetadata["clientPagination"]) {
    const sortBy = (state as any)["sortBy"][0];
    if (sortBy) {
      page = page.sort((a: any, b: any) => {
        const aVal = a.original[sortBy.id]
        const bVal = b.original[sortBy.id]
        if (typeof aVal === "string" && typeof bVal === "string") {
            let comparatorVal = aVal.localeCompare(bVal)
            if (sortBy.desc) {
              comparatorVal = comparatorVal * -1
            }
            return comparatorVal
        }
        return 0        
      })
    }
    page = page.slice(startRowIdx, endRowIdx)
  }
  const {
    state: { selectedRowIds, sortBy },
  } = tableInst as any;

  const sortByCB = useCallback(
    (sortBy: any) => async (curriedState: any) => {
      curriedState.controlledTableMetadata["sortBy"] = sortBy;
      setControlledTableMetadataLocalStorage(curriedState.controlledTableMetadata);
      updateTableMetadataServerState({ controlledTableMetadata: curriedState.controlledTableMetadata });
      return curriedState;
    },
    [setControlledTableMetadataLocalStorage, updateTableMetadataServerState],
  );

  useEffect(() => {
    if (JSON.stringify(tableData.initialState.selectedRowIds) !== JSON.stringify(selectedRowIds)) {
      console.log("running effect?", tableData.initialState.selectedRowIds, selectedRowIds);
      updateSelectedRows(selectedRowIds);
    }
  }, [selectedRowIds, tableData.initialState.selectedRowIds, updateSelectedRows]);

  useEffect(() => {
    if (
      tableOpts.manualSortBy &&
      JSON.stringify(tableData.initialState.sortBy) !== JSON.stringify(sortBy) &&
      !(typeof tableData.initialState.sortBy === "undefined" && !checkObjNotEmpty(sortBy))
    ) {
      setTableStateContext(sortByCB(sortBy));
      // TODO: fix selected rows if server paginated and sort changes
      // CHECK: check selected row state when pagination changes
      // ADD: select all button across entire ES query (unpaginated)
// ui driven state
      // {
//     "autoResetPage": true,
//     "autoResetSortBy": true,
//     "pageCount": 3,
//     "pageIndex": 0,
//     "pageSize": 10,
//     "totalItems": 24,
//     "manualPagination": true,
//     "manualSortBy": true
// }
// server driven
// {
//   "pageSize": 10,
//   "pageIndex": 0,
//   "notebook": "Staging:ElasticSearch_Exclude",
//   "cell": "Index",
//   "sortBy": [],
//   "totalItems": 835,
//   "pageCount": 84,
//   "manualPagination": true,
//   "manualSortBy": true
// }
    }
  }, [setTableStateContext, sortBy, sortByCB, tableData.initialState.sortBy, tableOpts.manualSortBy]);

  return (
    <div style={{ overflowX: "scroll" }}>
      <HTMLTable
        {...getTableProps()}
        bordered
        interactive
        striped
        style={style}
        className={theme === Theme.DARK ? Classes.DARK : ""}
      >
        <thead>
          {headerGroups.map((headerGroup, idx) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any, hColIdx: number) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={
                    "header " +
                    (!checkbox || (checkbox && hColIdx !== 0) ? "column" : "checkbox") +
                    (theme === Theme.DARK ? " header-bg-dark" : "")
                  }
                  style={{
                    top: idx * 40,
                    left: idx === 0 ? 0 : undefined,
                    width: checkbox && hColIdx === 0 ? "16px" : undefined,
                  }}
                >
                  {column.render("Header")}
                  <span>
                    {column.isSorted && (
                      <Icon
                        icon={column.isSortedDesc ? "sort-desc" : "sort-asc"}
                        style={{ paddingLeft: "12px" }}
                        intent={Intent.PRIMARY}
                      />
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} style={bodyStyle}>
          {page.length > 0 && 
            page.map((row, i) => {
              const cpRow = { ...row };
              if (i === 0) {
                console.log("page is:", page)
              }
              console.log({ cpRow });
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} onClick={() => onRowClick(row, history)}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()} onClick={(ev) => onCellClick(cell, callback, history)}>
                        {renderCell(cell, uniqueIdColumnName, setTableStateContext)}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
        </tbody>
        {(data.length > 10 || (tableOpts.manualPagination && controlledTableMetadata.totalItems > 10)) &&
        controlledTableMetadata.hidePagination !== true ? (
          <tfoot>
            <tr>
              <td colSpan={columns.length + 1} style={{ padding: "2px 0px 2px 0px" }}>
                {PaginationToolbar(
                  tableOpts,
                  setTableStateContext,
                  setControlledTableMetadataLocalStorage,
                  updateTableMetadataServerState,
                  id,
                )}
              </td>
            </tr>
          </tfoot>
        ) : null}
      </HTMLTable>
      {page.length === 0 && (
        <div style={{ width: "100%", paddingTop: "20px", paddingBottom: "20px" }}>
          <ErrorWidget
            id={id}
            content={{
              icon: <Icon icon="info-sign" iconSize={Spinner.SIZE_LARGE} />,
              title: "No Results",
              // description: `Error loading from path: ${notebook}/${cell}`
            }}
          />
        </div>
      )}
    </div>
  );
}

// redesign state manager to contain additional table state
// e.g. should contain pagination data
// should contain sorts
// should contain initialization data
export function useTableContextData(tName: string | undefined, id: string | undefined) {
  const { data } = usePage();
  const dispatch = usePageDispatch();
  const tableName = ElementStateLookup.TABLE + tName;

  const setTableData = (value: any) => {
    const UPDATE_PAGE_DATA = { type: "data", payload: { field: tableName, value } };
    dispatch(UPDATE_PAGE_DATA);
  };

  // implement other data than checkbox
  // // // implement a data provider that will create arbitrary state namespace.... longer term
  const defaultTableData = { [id as string]: [] };
  const tableData = typeof data[tableName] !== "undefined" ? data[tableName] : defaultTableData;

  return [tableData, setTableData];
}

export function Table({
  cols,
  rows,
  callback,
  theme,
  checkbox,
  defaultcheckall,
  id,
  name,
  uniqueIdColumnName,
  pagingData: serverTableMetadata,
}: TableWidgetProps) {
  const updateURL = useReplaceParams();
  const columns = React.useMemo(() => cols, [cols]);
  const memodRows = React.useMemo(() => rows, [rows]);
  // properly type
  let defaultTableServerState: any = { pageSize: 10, pageIndex: 0, sortBy: [] };
  if (serverTableMetadata) {
    defaultTableServerState = { ...serverTableMetadata, manualPagination: true, manualSortBy: true };
    // implement server sort
  }

  const [controlledTableMetadata, setControlledTableMetadataLocalStorage] = useLocalStorage(
    "tableServerState:" + id,
    defaultTableServerState,
  );
  const [tData, setTData, destroyState] = usePageNamespaceData<any>(id || "", ElementStateLookup.TABLE, {
    columns,
    rows: memodRows,
    controlledTableMetadata,
  });
  // catch all for server driven table data -> pagination, sort, and potentially filters
  const [selectedRows, setSelectedRows] = useTableContextData(name, id);
  console.log({ controlledTableMetadata });

  useEffect(() => {
    return () => destroyState();
  }, [destroyState]);

  useEffect(() => {
    console.log("tdata in useffect: ", tData)
    Promise.resolve(tData).then((td: any) => {
      setTData(
        produce(td, (draftState: any) => {
          draftState.columns = columns;
          draftState.rows = memodRows;
        }),
      );
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns, memodRows]);

  useEffect(() => {
    const matchKey = uniqueIdColumnName ?? "id";
    if (id && !selectedRows[id]) {
      console.log("selectedRow data: ", selectedRows[id]);
      const matchingRows = Object.entries(selectedRows)
        .flatMap((selectedRowStateFragment: any) => selectedRowStateFragment[1])
        .reduce(
          (matchingRows: any, previouslySelectedRow: any) => {
            console.log({ matchKey });
            const hitIdx = memodRows.findIndex((row: any) => row[matchKey] === previouslySelectedRow[matchKey]);
            let hit = memodRows[hitIdx];
            if (hit) {
              hit = { ...hit, origRowIdx: "" + hitIdx };
              matchingRows[id].push(hit);
            }
            return matchingRows;
          },
          { [id as string]: [] },
        );
      console.log("matching rows are: ", matchingRows);
      if (matchingRows[id].length > 0) {
        console.log("setting selected rows");
        setSelectedRows({ ...selectedRows, ...matchingRows });
      }
    }
  }, [name, id, uniqueIdColumnName, selectedRows, memodRows, setSelectedRows]);

  console.log("table name is: ", name);
  console.log("table id is: ", id);
  console.log("selected rows obj from page context", selectedRows);

  const updateSelectedRows = (selectedRowIds: SelectedRowIds) => {
    const selectedIds = Object.keys(selectedRowIds);
    var selectedRowsData = selectedIds
      .map((selectedRowId) => {
        const rc = { ...memodRows[selectedRowId] };
        if (rc) {
          rc["origRowIdx"] = selectedRowId;
        }
        return rc;
      })
      .filter((selectedRow) => selectedRow !== null);
    setSelectedRows({ ...selectedRows, [id as string]: selectedRowsData });
  };

  // const updateSelectedRows = (selectedRowIds: SelectedRowIds) => {
  //   const nextSelectedRows = Object.entries(selectedRowIds).reduce((rows, [selectedRowIndex, isChecked]) => {
  //     console.log("selectedrows: ", { selectedRowIndex, isChecked })
  //     if (isChecked) {
  //       const hit = { ...memodRows[selectedRowIndex] }
  //       if (hit) {
  //         hit["origRowIdx"] = selectedRowIndex
  //         const test = Object.entries(selectedRows)
  //           .flatMap((selectedRowStateFragment: any) => selectedRowStateFragment[1])
  //           .find((row) => row.ims_scan_id === hit.ims_scan_id)
  //         console.log({ test })
  //         rows.push(hit)
  //       }
  //     }
  //     return rows
  //   }, [] as any[])
  //   console.log({ nextSelectedRows })
  //   setSelectedRows({ ...selectedRows, [id as string]: nextSelectedRows })
  // }

  const setTableStateContext = useCallback((cb: any) => setTData(produce(tData, (draftState: any) => cb(draftState))), [
    setTData,
    tData,
  ]);

  // utilize react router to controll server driven table metadata -> e.g. paging, sort, etc
  const updateTableMetadataServerState = (tableMetadataParams: any) => {
    if (serverTableMetadata) {
      // dont rerender nonessential/unchanged stuff
      updateURL(tableMetadataParams);
    }
  };

  return tData.columns?.length > 0 ? (
    <TableComponent
      columns={tData.columns}
      data={tData.rows}
      setTableStateContext={setTableStateContext}
      setControlledTableMetadataLocalStorage={setControlledTableMetadataLocalStorage}
      callback={callback}
      theme={theme}
      style={{
        position: "relative",
        height: "100%",
        width: "100%",
        whiteSpace: "nowrap",
        overflowX: "auto",
      }}
      bodyStyle={{ overflowY: "scroll" }}
      checkbox={checkbox}
      defaultcheckall={defaultcheckall}
      id={id}
      updateSelectedRows={updateSelectedRows}
      selectedRows={selectedRows[id as string]}
      controlledTableMetadata={tData.controlledTableMetadata}
      updateTableMetadataServerState={updateTableMetadataServerState}
      uniqueIdColumnName={uniqueIdColumnName}
    />
  ) : null;
}
