import React, { useState } from "react";
import {
  MenuItem,
  H5,
  Classes,
  Colors,
  Button,
  Intent,
  ButtonGroup,
  MenuDivider,
  Popover,
  PopoverInteractionKind,
  IconName,
  Menu,
  Icon,
} from "@blueprintjs/core";
import { useSpring, animated, config } from "react-spring";

import {
  useMainState,
  TMainDispatchAction,
  MainActions,
  useMainDispatch,
  Theme,
  TLoginDispatchAction,
  LoginActions,
  useAuthDispatch,
  useAuthState,
  useSideNavData,
} from "../../../state";

import { Logo } from "../../../images/EICON_REACH";
import { AtomLogo } from "../../../images/EICON_ATOM";
import { useQueryClient } from "react-query";
import { useHistory, useLocation } from "react-router-dom";

type IMenuItem = {
  cell: string;
  displayName: string;
  icon: IconName;
  id: number;
  notebook: string;
  subMenu?: IMenuItem[];
};

function logOut(dispatch: any, cb: any) {
  const LOG_OUT: TLoginDispatchAction = { type: LoginActions.LOGOUT };
  dispatch(LOG_OUT);
  cb();
}

export function SideNav() {
  const { expandedNavMode, theme } = useMainState();
  const {
    user: { user_id },
  } = useAuthState();
  const { data: menuData } = useSideNavData();
  const queryClient = useQueryClient();
  const dumpServerState = () => queryClient.clear();

  const dispatch = useMainDispatch();
  const authDispatch = useAuthDispatch();
  const history = useHistory();
  const location = useLocation();
  const [hoverState, setHoverState] = useState(expandedNavMode);

  const envMode = location.pathname.split("/")[2]?.split(":")?.[0];
  console.log("location", envMode);
  const isDev = !(envMode === "Prod");

  const expandedNav = expandedNavMode || hoverState;
  const sideBarClass = Classes.DARK + ` eic-sidebar${expandedNav ? " expanded" : ""}`;
  const springProps = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    width: expandedNav ? "240px" : "80px",
    delay: 0,
    config: { ...config.default, duration: 40 },
  });

  const renderSubMenus = (menus: IMenuItem[]) => {
    const lst = menus.map((m, idx) => {
      return (
        <MenuItem
          key={idx}
          icon={
            <Icon
              icon={m.icon}
              iconSize={expandedNav ? undefined : 18}
              style={{ paddingLeft: expandedNav ? undefined : "16px" }}
            />
          }
          text={expandedNav ? m.displayName : ""}
          onClick={() => history.push(`${m.notebook}/${m.cell}`)}
        />
      );
    });
    return <React.Fragment>{lst}</React.Fragment>;
  };

  const renderMenu = (menus: IMenuItem[]) => {
    if (menus) {
      const lst = menus.map((m, idx) => {
        const subMenus = m.subMenu ? renderSubMenus(m.subMenu) : "";
        return (
          <React.Fragment key={idx}>
            {expandedNav ? <MenuDivider title={m.displayName} /> : ""}
            {subMenus}
          </React.Fragment>
        );
      });
      return (
        <Menu
          className={Classes.ELEVATION_1}
          style={{ backgroundColor: "black", minWidth: expandedNav ? "240px" : "80px" }}
        >
          {lst}
        </Menu>
      );
    }
    return <span />;
  };

  const toggleExpandedNav = () => {
    const TOGGLE_SIDE_MENU: TMainDispatchAction = { type: MainActions.toggleExpandedNav };
    setHoverState(!expandedNavMode);
    dispatch(TOGGLE_SIDE_MENU);
  };

  const logoutPopover = () => {
    return (
      <div key="text" style={{ padding: 10 }}>
        <H5>Confirm Logout</H5>
        <MenuDivider />
        <p>Do you wish to logout?</p>
        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 15 }}>
          <Button className={Classes.POPOVER_DISMISS} style={{ marginRight: 10 }}>
            Cancel
          </Button>
          <Button
            intent={Intent.DANGER}
            onClick={() => logOut(authDispatch, dumpServerState)}
            className={Classes.POPOVER_DISMISS}
          >
            Logout
          </Button>
        </div>
      </div>
    );
  };

  return (
    <animated.section
      className={sideBarClass}
      style={{
        ...springProps,
        backgroundColor: theme === Theme.DARK ? Colors.BLACK : undefined,
      }}
    >
      <div className="brand-logo-container">
        {expandedNav ? (
          <Logo style={{ paddingLeft: "12px" }} isDev={isDev} />
        ) : (
          <AtomLogo style={{ paddingLeft: "12px" }} />
        )}
      </div>
      <MenuDivider />
      {expandedNav ? (
        <ButtonGroup minimal={true} fill={true}>
          <Button icon="user">{user_id}</Button>
          <Popover content={logoutPopover()} usePortal interactionKind={PopoverInteractionKind.HOVER}>
            <Button intent={Intent.DANGER} style={{ paddingLeft: 10 }} minimal={false} fill={false} icon={"log-out"} />
          </Popover>
          <Button
            intent={Intent.PRIMARY}
            icon={expandedNav ? "menu-closed" : "menu-open"}
            style={{ paddingLeft: 10 }}
            fill={false}
            minimal={true}
            onClick={() => toggleExpandedNav()}
          />
        </ButtonGroup>
      ) : (
        <Button
          intent={Intent.PRIMARY}
          icon={expandedNav ? "menu-closed" : "menu-open"}
          style={{ paddingLeft: 10 }}
          fill={false}
          minimal={true}
          onClick={() => toggleExpandedNav()}
        />
      )}

      {renderMenu(menuData)}
    </animated.section>
  );
}
