import React from "react";
import { IDashboardCell, WidgetType, useMainState, useAuthState, Namespace, NamespaceProps } from "../state";
import * as W from "./";
import { useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router";
import { Spinner, Card, Divider, Icon, Intent } from "@blueprintjs/core";
import { ITokenInputContent, TokenInput } from "./TokenInput";
import { useRouterVariables } from "../routing";
import { SelectWidget, SelectWidgetContent } from "./select";

interface IWidgetProps {
  cell: IDashboardCell;
}

export function WidgetController({ cell }: IWidgetProps) {
  const { theme, expandedNavMode } = useMainState();
  const { type, tag, publisherName, content } = cell;
  const name = tag;
  let widget = <div />;
  switch (type) {
    case WidgetType.BUTTON:
      widget = <W.ButtonWidget id={name} content={{ ...(content as W.IButtonContent), field: publisherName! }} />;
      break;
    case WidgetType.FLOWCHART:
      widget = <W.Flowchart id={name} content={{ ...(content as W.IFlowchartContent) }} />;
      break;
    case WidgetType.DATE_PICKER:
      const datePickerProps = content as W.IDateRangeContent;
      widget = <W.DateRangeWidget id={name} content={datePickerProps} />;
      break;
    case WidgetType.E_CHART:
      widget = <W.EChartWidget id={name} content={content} appTheme={theme} />;
      break;
    case WidgetType.TOKEN_INPUT:
      const tokenInputProps = content as ITokenInputContent;
      widget = <TokenInput id={name} content={tokenInputProps} />;
      break;
    case WidgetType.MULTI_SELECT:
      const selectWidgetProps = content as W.IMultiselectContent;
      widget = <W.MultiSelectWidget id={name} content={selectWidgetProps} />;
      break;
    // re-implement select widget at HTML select/single select
    case WidgetType.SELECT:
      const selectWidgetContent = content as SelectWidgetContent;
      widget = <SelectWidget id={name} content={selectWidgetContent}/>
      break;
    case WidgetType.SWITCH:
      widget = <W.SwitchWidget id={name} content={content as W.SwitchContent} />;
      break;
    case WidgetType.TABLE:
      widget = <W.Table {...(content as W.TableWidgetProps)} theme={theme} />;
      break;
    case WidgetType.MARKDOWN:
      widget = <W.MarkdownWidget id={name} content={content as W.IMarkdownContent} />;
      break;
    case WidgetType.FORM:
      widget = <W.FormWidget id={name} content={content as W.IFormContent} />;
      break;
    case WidgetType.ROUTER_RULE_CONDITIONS:
      {
        const { value, values, id, ...options } = content;
        widget = <W.RuleWidget value={value} values={values} id={id} options={options} />;
      }
      break;
    case WidgetType.QUERY_BUILDER:
      {
        const { value, values, id, ...options } = content;
        widget = <W.QueryBuilder value={value} values={values} id={id} options={options} />;
      }
      break;
    case WidgetType.VALIDATION_BUILDER:
      {
        const { value, values, id, options } = content;
        widget = <W.ValidationBuilder value={value} values={values} id={id} options={options} />;
      }
      break;
    case WidgetType.NAVBAR:
      widget = <W.NavBar id={name} content={content} expandedNavMode={expandedNavMode} />;
      break;
    case WidgetType.SLIDER:
      widget = <W.SliderWidget id={name} content={content as W.ISliderContent} />;
      break;
    case WidgetType.TREE:
      widget = <W.TreeWidget id={name} content={content as W.ITreeContent} />;
      break;
    case WidgetType.DIALOG:
      if (Array.isArray(content)) {
        const widgets = renderWidgets(content);
        const prop: W.IDialogProps = {
          id: name,
          content: {
            title: cell["title"],
            widgets: widgets,
          },
        };
        widget = <W.DialogWidget {...prop} />;
      } else {
        widget = <h1>Dialog content is maligned !!!</h1>;
      }
      break;
    case WidgetType.HTML2:
      widget = <W.Html2Widget id={name} content={content as W.IHtml2Content} />;
      break;
    case WidgetType.DIVIDER:
      widget = <Divider style={{ backgroundColor: "white" }} />;
      break;
    case WidgetType.DRAWER:
      widget = <W.DrawerComponent id={name} content={content as W.DrawerComponentContent} />;
      break;
    case WidgetType.OVERLAY:
      if (Array.isArray(content)) {
        const widgets = renderWidgets(content);
        const prop: W.IOverlayProps = {
          id: name,
          content: {
            title: cell["title"],
            widgets: widgets,
          },
        };
        widget = <W.OverlayWidget {...prop} />;
      } else {
        widget = <h1>Overlay content is maligned !!!</h1>;
      }
      break;
    case WidgetType.CARD:
      if (Array.isArray(content)) {
        const widgets = renderWidgets(content);
        const prop: W.ICardProps = {
          id: name,
          content: {
            title: cell["title"],
            widgets: widgets,
            footer: renderWidgets(cell["footer"]),
            style: cell["style"],
          },
        };
        widget = <W.CardWidget {...prop} />;
      } else {
        widget = <h1>Card content is maligned !!!</h1>;
      }
      break;
    case WidgetType.CARD_LIST:
      widget = <W.CardListWidget id={name} content={content as W.CardListContent} />;
      break;
    case WidgetType.INFO_CARD:
      widget = <W.InfoCardWidget id={name} content={content as W.InfoCardContent} />;
      break;
    case WidgetType.STATISTIC:
      widget = <W.StatisticWidget id={name} content={content as W.StatisticWidgetContent} />;
      break;
    case WidgetType.ERROR_WIDGET:
      widget = <W.ErrorWidget id={name} content={content as W.ErrorWidgetContent} />;
      break;
    case WidgetType.NAMESPACE:
      widget = <Namespace {...(content as NamespaceProps)} />;
      break;
    case WidgetType.DEID_ACTION_BUILDER:
      {
        const { value, values, id, options } = content;
        widget = <W.DEIDActionBuilder value={value} values={values} id={id} options={options} />;
      }
      break;
    case WidgetType.DEID_FILTER_BUILDER:
      widget = <W.DEIDFilterBuilder {...content as W.DEIDFilterBuilderProps} />;
      break;
    default:
      // widget = <h1>Error: Unknown Widget - {type}</h1>;
      widget = (
        <W.ErrorWidget
          id={name}
          content={{
            icon: <Icon icon="disable" intent={Intent.DANGER} iconSize={Spinner.SIZE_LARGE} />,
            title: `Unknown Widget Type - "${type}"`,
          }}
        />
      );
  }
  return widget;
}

export function renderWidgets(lst: any, WidgetWrapper?: any) {
  if (typeof lst === "undefined") {
    return <div></div>;
  }
  if (typeof lst === "string") {
    return <span>{lst}</span>;
  }
  return lst.map((w: any, idx: number) => {
    if (typeof w === "string") {
      return w;
    }
    w["id"] = lst["id"];
    w["tag"] = lst["tag"];
    let rc = <WidgetController cell={w} key={idx} />;
    if (typeof WidgetWrapper === "function") {
      rc = WidgetWrapper({ children: [rc] });
    }
    return rc;
  });
}

export interface IWidgetLoaderProps {
  notebook: string;
  cell: string;
  params: {}; // incorect type
}

// export enum ReactQueryNBEndpoint {
//   WIDGET = "widget",
//   PAGE = "page"
// }

// export function formatReactQueryKeyNB(type: ReactQueryNBEndpoint, nb: string, cell: string) {
//   return `${type}:${nb}/${cell}`
// }

export function useGetNotebookViewContent(nb: string, cell: string, params: any) {
  // const queryKey = formatReactQueryKeyNB(type, nb, cell)
  // use page notebook potentially
  // page params cause infinite loop -> do we need them and how to use?
  const { client } = useAuthState();
  const queryClient = useQueryClient();
  const urlParams: URLSearchParams = useRouterVariables();
  const keys = urlParams.keys();
  const k = Array.from(keys);
  let urlVars: any = k.reduce((acc, kk) => Object.assign(acc, { [kk]: urlParams.get(kk) }), {});
  const config: any = {};
  try {
    if (typeof params !== "object") {
      params = JSON.parse(params);
    }
  } catch (e) {
    if (typeof params !== "object") {
      params = {};
    }
  }
  for (let key in urlVars) {
    try {
      const formattedQPVal = JSON.parse(urlVars[key]) 
      urlVars[key] = formattedQPVal
    } catch (err) {
      console.error(`Could not parse URL Query Param ${key} whose value is: ${urlVars[key]}; error is: ${err}`)
    }
  }
  params = { ...params, ...urlVars };
  config["keepPreviousData"] = true;
  if (typeof params["refetch"] !== "undefined" ) {
    console.log("refetch interval: ", params["refetch"]);
    config["refetchInterval"] = parseInt(params["refetch"]);
  }
  const staleData = queryClient.getQueryData([nb, cell, params])
  console.log("query data?: ", staleData)
  const queryParams = {
    queryKey: [nb, cell, params],
    queryFn: async () => await client.newExecNb(nb, cell, params),
    ...config,
  };
  const pageContent = useQuery(queryParams);
  return [pageContent, staleData as any];
}

export function WidgetLoader(props: IWidgetLoaderProps) {
  console.log("in widget loader: ", props.notebook, props.cell);
  const queryRC = useGetNotebookViewContent(props.notebook, props.cell, props.params);
  const [{ status, data }, staleData] = queryRC;
  
  if (status === "loading" && typeof data === "undefined" && typeof staleData === "undefined") {
    return (
      <Card style={{ textAlign: "center" }}>
        <W.ErrorWidget
          id={props.notebook + props.cell}
          content={{
            icon: <Spinner intent={Intent.PRIMARY} size={Spinner.SIZE_LARGE} />,
            title: "Loading...",
          }}
        />
      </Card>
    );
  }
  let widget_prop: any = data ?? staleData;
  if (typeof data === "string") {
    widget_prop = {
      type: "html2",
      content: {
        text: data,
      },
    };
  }
  widget_prop["id"] = props.notebook + ":" + props.cell;
  widget_prop["tag"] = props.notebook + ":" + props.cell;
  if (widget_prop["type"] === "html2") {
    console.log({ widget_prop });
    widget_prop["tag"] = props.cell;
    widget_prop["book"] = props.notebook
    // widget_prop = {
    //   type: WidgetType.ERROR_WIDGET,
    //   content: {
    //     title: "ur screwed",
    //   },
    // };
  } 
  return <WidgetController cell={widget_prop} />;
}

export function MarkdownLinkRenderer(props: any) {
  const history = useHistory();
  const href: string = props["href"];
  let params = {};
  const splitHref = href.split("?");
  if (splitHref[1]) {
    params = Object.fromEntries(new URLSearchParams(splitHref[1]));
  }

  const title = props["children"][0]["props"]["value"];
  // Render widget (not a link)
  if (title.trim().startsWith("!WIDGET!")) {
    let path = splitHref[0].split("/");
    const nbExecData = {
      notebook: path.slice(0, path.length - 1).join(":"),
      cell: path[path.length - 1],
    };

    if (path.length >= 2) {
      const prop: IWidgetLoaderProps = {
        notebook: nbExecData.notebook,
        cell: nbExecData.cell,
        params: params,
      };
      return (
        <div style={{ width: "100%" }}>
          <WidgetLoader {...prop} />
        </div>
      );
    }
  }
  // External navigation
  if (href.trim().startsWith("http")) {
    return <a href={href}>{title}</a>;
  }
  // In App Navigation
  return <em onClick={() => history.push(href)}>{title}</em>;
}
