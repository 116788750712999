import React from "react";
import { IconName, MaybeElement, NonIdealState } from "@blueprintjs/core";
import { WidgetController } from "./WidgetController";

export interface ErrorWidgetContent {
  title: string;
  icon: IconName | MaybeElement;
  description?: string;
  action?: any;
  children?: any;
}

export interface ErrorWidgetProps {
  id: string;
  content: ErrorWidgetContent;
}

export function ErrorWidget({ content: { icon, description, title, action } }: ErrorWidgetProps) {
  return (
    <NonIdealState
      icon={icon}
      description={description}
      title={title}
      action={action ? <WidgetController cell={action} /> : undefined}
    />
  );
}
