import React, { useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import * as echarts from "echarts";
import { Theme } from "../state";
import "./styling/echarts.css";
import { ResizeSensor } from "@blueprintjs/core";

require("../chart-themes/dark");
require("../chart-themes/eicon_dark");
require("../chart-themes/essos");
require("../chart-themes/roma");

export interface EChartWidgetProp {
  id: string;
  content: any;
  appTheme: Theme;
}

function renderEChart(content: Object, ref: any, appTheme: Theme, router: any) {
  let chart: echarts.ECharts;
  if (ref !== null) {
    const { callback, echart }: any = content;
    chart = echarts.init(ref.current, "eicon_dark", { renderer: "svg" });
    chart.setOption(echart);
    if (callback !== undefined) {
      if (typeof callback == "string") {
        chart.on("click", (ev: Event) => onChartClick(ev, callback, router, undefined));
      } else {
        callback.forEach((cb: any) => {
          chart.on(cb.eventType, cb.dataType, (ev: Event) => onChartClick(ev, cb.route, router, cb.keyModifier));
        });
      }
    }
  }
}

function disposeEChart(chart: any) {
  if (chart !== null && chart.current !== null) {
    const chartInstance = echarts.getInstanceByDom(chart.current);
    if (chartInstance) {
      chartInstance.resize();
    }
  }
  return chart;
}
function onChartClick(ev: any, callback: any, router: any, keyModifier: any) {
  function fireEvent() {
    const params =
      `seriesIndex=${ev.seriesIndex}` + `&dataIndex=${ev.dataIndex}` + `&name=${ev.name}` + `&value=${ev.value}`;
    router({ pathname: callback, search: params });
  }
  console.log("ev? ", ev.event.event);
  ev.event.event.preventDefault();
  if (keyModifier && keyModifier === "shift" && ev.event.event.shiftKey) {
    fireEvent();
  } else if (keyModifier === undefined) {
    fireEvent();
  }
}

function handleResize(chart: any) {
  if (chart !== null && chart.current !== null) {
    const chartInstance = echarts.getInstanceByDom(chart.current);
    if (chartInstance) {
      chartInstance.resize();
    }
  }
}
export function EChartWidget(props: EChartWidgetProp) {
  const chartEl = useRef<any>();
  const { content, appTheme, id: propsId } = props;
  const history = useHistory();
  useEffect(() => {
    renderEChart(content, chartEl, appTheme, history.push);
    return () => {
      disposeEChart(chartEl);
    };
  }, [chartEl, content, appTheme, history.push]);

  const id = propsId;

  return (
    <ResizeSensor observeParents={true} onResize={() => handleResize(chartEl)}>
      <div id={id} ref={chartEl} className="echarts-wrapper" />
    </ResizeSensor>
  );
}
