import React, { CSSProperties } from "react";
import { Card, Classes, Elevation, H3, H6, Tag } from "@blueprintjs/core";
import { renderWidgets } from "./WidgetController";

export interface InfoCardProps {
  id: string;
  content: InfoCardContent;
}

export interface InfoCardContent {
  title?: InfoCardTitle | string;
  body?: InfoCardBody;
  footer?: InfoCardFooter;
  options?: any;
  style?: Partial<CSSProperties>;
}

interface InfoCardTitle {
  leftElement?: GenericWidgetContentType;
  content: GenericWidgetContentType;
  rightElement?: GenericWidgetContentType;
}

interface InfoCardBody {
  content?: GenericWidgetContentType;
  rowPadding?: CSSProperties["padding"];
  itemPadding?: CSSProperties["padding"];
}

interface InfoCardFooter {
  actionButtonGroup: ActionButtonGroup;
}

interface ActionButtonGroup {
  left?: boolean;
  content: GenericWidgetContentType;
  style?: any;
}

// interface InfoCardOptions {

// }

export type GenericWidgetContentType = string | Object[] | Object;

const renderTitle = (title: InfoCardTitle | string | undefined) => {
  let rc = <span />;
  let leftElement = null;
  let rightElement = null;
  if (typeof title !== "undefined") {
    if (typeof title === "string") {
      rc = (
        <div>
          <H3 className={"eic-content-card title " + Classes.TEXT_OVERFLOW_ELLIPSIS}>{title}</H3>
        </div>
      );
    } else {
      if (typeof title.leftElement !== "undefined") {
        leftElement =
          typeof title.leftElement === "string" ? (
            <Tag>{title.leftElement}</Tag>
          ) : (
            renderWidgets(!Array.isArray(title.leftElement) ? [title.leftElement] : title.leftElement)
          );
      }
      if (typeof title.rightElement !== "undefined") {
        rightElement =
          typeof title.rightElement === "string" ? (
            <Tag>{title.rightElement}</Tag>
          ) : (
            <span style={{ right: "20px", paddingLeft: "18px" }}>
              {renderWidgets(!Array.isArray(title.rightElement) ? [title.rightElement] : title.rightElement)}
            </span>
          );
      }

      if (typeof title.content === "string") {
        rc = <H3 className={"eic-content-card title " + Classes.TEXT_OVERFLOW_ELLIPSIS}>{title.content}</H3>;
      } else {
        rc = renderWidgets(!Array.isArray(title.content) ? [title.content] : title.content);
      }
      if (leftElement !== null) {
        rc = <span style={{ paddingLeft: "16px", fontWeight: 600 }}>{rc}</span>;
      }
      rc = <header children={[leftElement, rc, rightElement]} />;
    }
  }
  return rc;
};

const renderBody = (body: InfoCardContent["body"] | undefined) => {
  let rc = <span />;
  if (typeof body !== "undefined" && typeof body.content !== undefined) {
    if (typeof body.content === "string") {
      rc = (
        <div>
          <p className={Classes.RUNNING_TEXT + " " + Classes.TEXT_OVERFLOW_ELLIPSIS}>{body.content}</p>
        </div>
      );
    } else {
      const widgetWrapper = (props: any) => <div {...props} style={{ padding: body.rowPadding }} />;
      rc = renderWidgets(!Array.isArray(body.content) ? [body.content] : body.content, widgetWrapper);
    }
  }
  return rc;
};

const renderFooter = (footer: InfoCardContent["footer"] | undefined) => {
  if (typeof footer !== "undefined") {
    return <div>{footer}</div>;
  }
};

export function InfoCardWidget(props: InfoCardProps) {
  const { id, content } = props;
  const {
    title,
    body,
    footer,
    options: { interactive },
    style,
  } = content;

  return (
    <Card
      interactive={interactive}
      elevation={Elevation.FOUR}
      style={style ? style : { height: "100%", overflow: "scroll" }}
      // className="eic-content-card"
      key={id}
    >
      <div>{renderTitle(title)}</div>
      <div>{renderBody(body)}</div>
      {/* {body}
      {renderFooter(footer)} */}
    </Card>
  );
}
