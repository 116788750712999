import React from "react";
import ReactMarkdown from "react-markdown/with-html";
import { MarkdownLinkRenderer } from ".";

export interface IMarkdownProps {
  content: IMarkdownContent;
  id: string;
}

export interface IMarkdownContent {
  text: string;
}

export function MarkdownWidget(props: IMarkdownProps) {
  return (
    <ReactMarkdown
      className="eic-markdown"
      sourcePos={true}
      source={props.content.text}
      escapeHtml={false}
      renderers={{ link: MarkdownLinkRenderer }}
    />
  );
}
