import React, { useEffect } from "react";
import { Intent } from "@blueprintjs/core/lib/esnext/common";
import { IconName } from "@blueprintjs/icons";
import {
  Button,
  Card,
  ControlGroup,
  HTMLSelect,
  Icon,
  IMenuItemProps,
  IMenuProps,
  InputGroup,
  IPopoverProps,
  MaybeElement,
  Menu,
  MenuItem,
  NumericInput,
  Popover,
  Position,
  Spinner,
  Tag,
  Tooltip,
} from "@blueprintjs/core";
import { ItemListRenderer, ItemRenderer, Select } from "@blueprintjs/select";
import { useQuery } from "react-query";
import produce from "immer";

import { ElementStateLookup, useAuthState, usePageNamespaceData } from "../state";
import {
  AutoCompleteTuple,
  StringTupleAutoComplete as ValidationBuilderAutoComplete,
  renderMenu,
  renderItem,
  filterItem,
  renderInputValue,
  areTagsEqual,
} from ".";

import "./styling/ValidationBuilder.css";
import { useImmer } from "use-immer";

interface DefaultOptions {
  allowCreate: boolean;
  closeOnSelect: boolean;
  createdFilterByItems: [];
  fill: boolean;
  minimal: boolean;
  outlined: boolean;
  inline: boolean;
  large: boolean;
  openOnKeyDown: boolean;
  resetOnClose: boolean;
  resetOnQuery: boolean;
  resetOnSelect: boolean;
}

export interface ValidationBuilderProps {
  value: ValidationBuilderDatum[];
  values: ValidationBuilderData;
  id: string;
  options: Partial<DefaultOptions>; //refactor
}

interface ValidationBuilderDatum {
  selectedField: FieldData;
  name: string;
  check: SelectOption;
  errorSeverity: SelectOption;
  validations: ValidationConditionValue[];
}

export interface SelectOption {
  type: CHECK_TYPE | ERROR_SEVERITY_TYPE | string;
  hasPredicate?: boolean;
  intent?: Intent;
  bgColor?: string;
  info?: string;
  label?: string;
}

enum CHECK_TYPE {
  CONTENT = "Content",
  ABSENT = "Absent",
  PRESENT = "Present",
  OPTIONAL = "Optional",
  EMPTY = "Empty",
}

enum ERROR_SEVERITY_TYPE {
  RETURN_ERROR = "Error",
  RETURN_WARNING = "Warning",
  IGNORE_EXCEPTIONS = "Ignore",
}

interface ValidationConditionValue {
  type: VALIDATION_CONDITION_TYPE | string;
  value: any;
}

enum VALIDATION_CONDITION_TYPE {
  EXACT_MATCH = "ExactMatch",
  REGEX = "Regex",
  MIN_LENGTH = "MinLength",
  MAX_LENGTH = "MaxLength",
  INT_RANGE = "IntRange",
  FLOAT_RANGE = "FloatRange",
  DATE = "Date",
  TIME = "Time",
  INSTANCE_NUMBER = "InstanceNumber",
  UNIQUE_PER_SCAN = "UniquePerScan",
  UNIQUE_PER_SLICE = "UniquePerSlice",
}

interface ValidationBuilderData {
  checkTypes: SelectOption[];
  errorSeverityTypes: SelectOption[];
  fields: FieldData[];
  validationTypes: ValidationConditionTypeDatum[];
}

interface ValidationConditionTypeDatum {
  type: VALIDATION_CONDITION_TYPE | string;
  label: string;
  component: VALIDATION_CONDITION_INPUT_COMPONENT;
  data: ValidationConditionComponentData;
}

export interface ValidationConditionComponentData
  extends Partial<ACInputComponentData>,
    Partial<ValidationConditionInputData> {}

enum VALIDATION_CONDITION_INPUT_COMPONENT {
  ICON = "Icon",
  SELECT = "Select",
  NUM_RANGE = "NumRange",
  TEXT_INPUT = "TextInput",
  NUM_INPUT = "NumInput",
  AUTO_COMPLETE_INPUT = "AutoCompleteInput",
}

interface ValidationConditionInputData {
  type?: INPUT_COMPONENT_TYPE | string;
  color?: string;
  bgColor?: string;
  intent?: Intent;
  defaultValue?: number | [number, number] | string | any; // any is a hack here
  values?: any[];
  min?: number;
  max?: number;
  numDecimals?: number;
  stepSize?: number;
  icon?: IconName | MaybeElement;
  tagIntent?: Intent;
}

enum INPUT_COMPONENT_TYPE {
  REGEX = "Regex",
  INT = "Int",
  FLOAT = "Float",
}

export interface ACInputComponentData {
  defaultValue: AutoCompleteTuple | any; // any is a hack here
  notebook: string;
  cell: string;
}

export interface FieldData {
  type: "Dicom Tag" | "EICON Vault Tags" | string;
  values: Array<AutoCompleteTuple>;
  value?: AutoCompleteTuple;
}

const defaultOptions: DefaultOptions = {
  allowCreate: false,
  closeOnSelect: true,
  createdFilterByItems: [],
  fill: false,
  minimal: true,
  outlined: false,
  inline: false,
  large: true,
  openOnKeyDown: false,
  resetOnClose: false,
  resetOnQuery: true,
  resetOnSelect: false,
};

export function ValidationBuilder({ id, value, values, options: propsOptions }: ValidationBuilderProps) {
  const options = { ...defaultOptions, ...propsOptions };
  const { large, outlined, minimal, fill } = options;

  const [validationsState, setValidationsState, destroyState] = usePageNamespaceData<ValidationBuilderDatum[]>(
    id,
    ElementStateLookup.VALIDATION_BUILDER,
    value,
  );
  useEffect(() => {
    setValidationsState(value);
    return () => destroyState();
  }, [value, setValidationsState, destroyState]);

  function handleRuleSelectedFieldACInputChange(v: any, validationRuleIdx: number) {
    if (validationsState[validationRuleIdx]) {
      setValidationsState(
        produce(validationsState, (draftState) => {
          draftState[validationRuleIdx].selectedField.value = v;
        }),
      );
    }
  }
  function handleRuleNameInputChange(e: any, validationRuleIdx: number) {
    if (validationsState[validationRuleIdx]) {
      setValidationsState(
        produce(validationsState, (draftState) => {
          draftState[validationRuleIdx].name = e.target.value;
        }),
      );
    }
  }
  function handleRuleCheckTypeChange(e: any, validationRuleIdx: number) {
    const newCheck = values.checkTypes.find((c) => c.type === e.target.value);
    if (validationsState[validationRuleIdx] && typeof newCheck !== "undefined") {
      setValidationsState(
        produce(validationsState, (draftState) => {
          draftState[validationRuleIdx].check = newCheck;
        }),
      );
    }
  }
  function handleRuleErrorSeverityChange(errorSeverity: SelectOption, validationRuleIdx: number) {
    if (validationsState[validationRuleIdx]) {
      setValidationsState(
        produce(validationsState, (draftState) => {
          draftState[validationRuleIdx].errorSeverity = errorSeverity;
        }),
      );
    }
  }
  function handleRuleCheckChange(check: SelectOption, validationRuleIdx: number) {
    if (validationsState[validationRuleIdx]) {
      setValidationsState(
        produce(validationsState, (draftState) => {
          draftState[validationRuleIdx].check = check;
        }),
      );
    }
  }
  function handleRuleConditionTypeChange(e: any, validationRuleIdx: number, validationRuleConditionIdx: number) {
    const t = e.target.value as ValidationConditionValue["type"];
    if (validationsState[validationRuleIdx]?.validations[validationRuleConditionIdx]) {
      setValidationsState(
        produce(validationsState, (draftState) => {
          draftState[validationRuleIdx].validations[validationRuleConditionIdx].type = t;
          draftState[validationRuleIdx].validations[validationRuleConditionIdx].value = values.validationTypes.find(
            (vt) => vt.type === t,
          )?.data?.defaultValue;
        }),
      );
    }
  }
  function handleRuleConditionValueChange(
    e: any,
    type: VALIDATION_CONDITION_INPUT_COMPONENT,
    validationRuleIdx: number,
    validationRuleConditionIdx: number,
  ) {
    let updatedValue = e;
    if (validationsState[validationRuleIdx]?.validations[validationRuleConditionIdx]) {
      setValidationsState(
        produce(validationsState, (draftState) => {
          if (
            [VALIDATION_CONDITION_INPUT_COMPONENT.SELECT, VALIDATION_CONDITION_INPUT_COMPONENT.TEXT_INPUT].includes(
              type,
            )
          ) {
            updatedValue = e.target.value;
          } else if (type === VALIDATION_CONDITION_INPUT_COMPONENT.NUM_INPUT) {
            updatedValue = e.type === INPUT_COMPONENT_TYPE.FLOAT ? e.strVal : e.numVal;
          } else if (type === VALIDATION_CONDITION_INPUT_COMPONENT.NUM_RANGE) {
            // shape of e: {min: boolean, strVal, numVal, type: Int | Float }
            const intermediate = draftState[validationRuleIdx].validations[validationRuleConditionIdx].value;
            intermediate[e.min ? 0 : 1] = e.type === INPUT_COMPONENT_TYPE.FLOAT ? e.strVal : e.numVal;
            updatedValue = intermediate;
          }
          draftState[validationRuleIdx].validations[validationRuleConditionIdx].value = updatedValue;
        }),
      );
    }
  }

  function addRule(duplicateRuleIdx?: number) {
    setValidationsState(
      produce(validationsState, (draftState) => {
        if (typeof duplicateRuleIdx === "undefined") {
          draftState.push(value[0]);
        } else if (typeof duplicateRuleIdx !== "undefined" && draftState[duplicateRuleIdx]) {
          draftState.push(draftState[duplicateRuleIdx]);
        }
      }),
    );
  }
  function addRuleCondition(duplicateRuleIdx: number, duplicateConditionIdx?: number) {
    setValidationsState(
      produce(validationsState, (draftState) => {
        if (typeof duplicateConditionIdx === "undefined") {
          draftState[duplicateRuleIdx].validations.push(
            draftState[duplicateRuleIdx].validations[0] ??
              value[duplicateRuleIdx].validations[0] ??
              value[0].validations[0],
          );
        } else if (duplicateRuleIdx && draftState[duplicateRuleIdx]) {
          draftState[duplicateRuleIdx].validations.push(
            draftState[duplicateRuleIdx].validations[duplicateConditionIdx],
          );
        }
      }),
    );
  }
  function deleteRule(ruleIdx: number) {
    setValidationsState(
      produce(validationsState, (draftState) => {
        draftState.splice(ruleIdx, 1);
      }),
    );
  }
  function deleteRuleCondition(ruleIdx: number, ruleConditionIdx: number) {
    setValidationsState(
      produce(validationsState, (draftState) => {
        draftState[ruleIdx].validations.splice(ruleConditionIdx, 1);
      }),
    );
  }

  return (
    <Card>
      {validationsState.map((v, idx) => (
        <ValidationRule
          {...v}
          values={values}
          key={"ValidationRuleComponent__" + idx}
          idx={idx}
          options={options}
          handleRuleSelectedFieldACInputChange={handleRuleSelectedFieldACInputChange}
          handleRuleNameInputChange={handleRuleNameInputChange}
          handleRuleCheckTypeChange={handleRuleCheckTypeChange}
          handleRuleErrorSeverityChange={handleRuleErrorSeverityChange}
          handleRuleCheckChange={handleRuleCheckChange}
          handleRuleConditionTypeChange={handleRuleConditionTypeChange}
          handleRuleConditionValueChange={handleRuleConditionValueChange}
          addRuleCondition={addRuleCondition}
          addRule={addRule}
          deleteRuleCondition={deleteRuleCondition}
          deleteRule={deleteRule}
        />
      ))}
      <Button
        text="Validation Rule"
        intent={Intent.PRIMARY}
        large={large}
        minimal={minimal}
        outlined={outlined}
        fill={fill}
        icon="add"
        className="validation-builder-add-rule-btn"
        onClick={() => addRule()}
      />
    </Card>
  );
}

interface CheckTagSelectOption extends SelectOption, Partial<DefaultOptions> {}
const renderTagSelectOption: ItemRenderer<CheckTagSelectOption> = (selectOption, itemProps) => {
  const { intent, type, bgColor, minimal, large, fill, info } = selectOption;
  const { handleClick, modifiers } = itemProps;
  return (
    <MenuItem
      active={modifiers.active}
      disabled={modifiers.disabled}
      // key={"CHECK_TYPE__" + type}
      onClick={handleClick}
      style={{ padding: "5px 12px" }}
      labelElement={
        typeof info !== "undefined" ? (
          <Tooltip content={info} intent={intent} placement="bottom" minimal={minimal} usePortal={true}>
            <Icon icon="info-sign" style={{ paddingTop: "4px", paddingLeft: "2px" }} />
          </Tooltip>
        ) : undefined
      }
      text={
        <Tag
          intent={intent}
          minimal={minimal}
          large={large}
          fill={fill}
          style={{ backgroundColor: bgColor, textAlign: "center" }}
        >
          {type}
        </Tag>
      }
    />
  );
};
const renderTagSelectMenu: ItemListRenderer<CheckTagSelectOption> = ({ items, itemsParentRef, renderItem }) => {
  const renderedItems = items.map(renderItem).filter((item) => item != null);
  const large = items[0].large;
  return (
    <Menu ulRef={itemsParentRef} large={large} style={{ minWidth: large ? "120px" : "96px", width: "max-content" }}>
      {renderedItems}
    </Menu>
  );
};

// contains rule name (desc), selected field (e.g. dicom tag), check, and validation rule conditions
// has button to add new conditions as part of array of validation conditionss for a single rule
// imlements predicate logic if a check type has / does not have a predicate for a check value (e.g. whether the specified check type requires validations)
const ValidationBuilderSelect = Select.ofType<SelectOption>();
interface ValidationRuleComponentProps extends ValidationBuilderDatum {
  key: string;
  idx: number;
  options: Partial<DefaultOptions>;
  values: ValidationBuilderData;
  handleRuleSelectedFieldACInputChange: (v: any, validationRuleIdx: number) => void;
  handleRuleNameInputChange: (e: any, validationRuleIdx: number) => void;
  handleRuleCheckTypeChange: (e: any, validationRuleIdx: number) => void;
  handleRuleErrorSeverityChange: (errorSeverity: SelectOption, validationRuleIdx: number) => void;
  handleRuleCheckChange: (c: SelectOption, validationRuleIdx: number) => void;
  handleRuleConditionTypeChange: (t: any, validationRuleIdx: number, validationRuleConditionIdx: number) => void;
  handleRuleConditionValueChange: (
    e: any,
    type: VALIDATION_CONDITION_INPUT_COMPONENT,
    validationRuleIdx: number,
    validationRuleConditionIdx: number,
  ) => void;
  addRule: (ruleIdx: number) => void;
  addRuleCondition: (ruleIdx: number, ruleConditionIdx?: number) => void;
  deleteRuleCondition: (ruleIdx: number, ruleConditionIdx: number) => void;
  deleteRule: (ruleIdx: number) => void;
}
function ValidationRule({
  options,
  idx,
  values,
  addRule,
  addRuleCondition,
  handleRuleConditionValueChange,
  handleRuleConditionTypeChange,
  handleRuleSelectedFieldACInputChange,
  handleRuleNameInputChange,
  handleRuleCheckTypeChange,
  handleRuleErrorSeverityChange,
  handleRuleCheckChange,
  deleteRule,
  deleteRuleCondition,
  ...value
}: ValidationRuleComponentProps) {
  const { large, inline, minimal, fill, outlined } = options;
  const largeClassName = large ? "bp3-input-group bp3-large bp3-input" : "";
  const { fields, checkTypes, validationTypes, errorSeverityTypes } = values;
  const { name, selectedField, check, errorSeverity, validations } = value;

  return (
    <Card>
      <Tag
        minimal={minimal}
        large={large}
        round={true}
        intent={check.intent ?? Intent.NONE}
        className="validation-rule-component-badge"
        style={{ marginTop: large ? ".5%" : undefined, marginLeft: large ? undefined : "-32px" }}
      >
        {idx + 1}
      </Tag>
      <ControlGroup fill={fill} vertical={inline} style={{ paddingTop: "2px", paddingBottom: "2px" }}>
        <div className={"validation-builder-inline-text-block " + largeClassName} style={{ width: "106px" }}>
          <span>Rule Name: </span>
        </div>
        <InputGroup
          value={name}
          placeholder="Please input a descriptive Validation Rule Name"
          large={large}
          fill={true}
          onChange={(e) => handleRuleNameInputChange(e, idx)}
        />
        <div className={"validation-builder-inline-text-block " + largeClassName} style={{ width: "106px" }}>
          <span>{fields[0].type}: </span>
        </div>
        <ValidationBuilderAutoComplete
          items={fields[0].values}
          {...options}
          itemRenderer={renderItem}
          itemListRenderer={renderMenu}
          itemPredicate={filterItem}
          inputValueRenderer={renderInputValue}
          itemsEqual={areTagsEqual}
          onItemSelect={(val) => handleRuleSelectedFieldACInputChange(val, idx)}
          selectedItem={selectedField.value}
          popoverProps={{ minimal, fill, placement: "bottom-start" }}
          inputProps={{ className: large ? "bp3-large" : undefined, leftIcon: "tag", fill, large }}
        />
        <div className={"validation-builder-inline-text-block " + largeClassName}>
          <span>Check: </span>
        </div>
        <ValidationBuilderSelect
          items={checkTypes.map((c) => ({ ...c, ...options }))}
          {...options}
          itemRenderer={renderTagSelectOption}
          itemListRenderer={renderTagSelectMenu}
          filterable={false}
          onItemSelect={(check) => handleRuleCheckChange(check, idx)}
          activeItem={check}
          popoverProps={{
            minimal,
            fill,
            placement: "bottom-start",
            position: Position.BOTTOM_LEFT,
            popoverClassName: "validation-rule-select-popover-container",
          }}
        >
          <Button
            rightIcon="caret-down"
            large={large}
            minimal={minimal}
            outlined={outlined}
            fill={fill}
            className="validation-rule-select-button"
          >
            <Tag
              fill={fill}
              large={large}
              minimal={minimal}
              intent={check.intent}
              style={{ backgroundColor: check.bgColor, textAlign: "center" }}
            >
              {check.type}
            </Tag>
          </Button>
        </ValidationBuilderSelect>
        <div className={"validation-builder-inline-text-block " + largeClassName} style={{ width: "96px" }}>
          <span>Severity: </span>
        </div>
        <ValidationBuilderSelect
          items={errorSeverityTypes.map((errorSeverityType) => ({ ...errorSeverityType, ...options }))}
          {...options}
          itemRenderer={renderTagSelectOption}
          itemListRenderer={renderTagSelectMenu}
          filterable={false}
          onItemSelect={(errorSeverityType) => handleRuleErrorSeverityChange(errorSeverityType, idx)}
          activeItem={errorSeverity}
          popoverProps={{
            minimal,
            fill,
            placement: "bottom-start",
            position: Position.BOTTOM_LEFT,
            popoverClassName: "validation-rule-select-popover-container",
          }}
        >
          <Button
            rightIcon="caret-down"
            large={large}
            minimal={minimal}
            outlined={outlined}
            fill={fill}
            className="validation-rule-select-button"
          >
            <Tag
              fill={fill}
              large={large}
              minimal={minimal}
              intent={errorSeverity.intent}
              style={{ backgroundColor: errorSeverity.bgColor, textAlign: "center" }}
            >
              {errorSeverity.type}
            </Tag>
          </Button>
        </ValidationBuilderSelect>
        <Popover
          content={
            <Menu {...(options as IMenuProps)}>
              <MenuItem
                icon="trash"
                onClick={() => deleteRule(idx)}
                shouldDismissPopover={true}
                {...(options as IMenuItemProps)}
                intent={Intent.DANGER}
                text="Remove"
              />
              <MenuItem
                icon="duplicate"
                onClick={() => addRule(idx)}
                shouldDismissPopover={true}
                {...(options as IMenuItemProps)}
                text="Duplicate"
              />
            </Menu>
          }
          placement="bottom"
          {...(options as IPopoverProps)}
        >
          <Button
            icon="more"
            large={large}
            minimal={minimal}
            outlined={outlined}
            className="validation-rule-select-button"
          />
        </Popover>
      </ControlGroup>
      {check.hasPredicate ? (
        <div style={{ paddingTop: "2px" }}>
          <Card className="eic-content-card" style={{ padding: "0px", margin: "0px" }}>
            <div style={{ display: "inline-flex", width: "100%" }}>
              <div
                className={"validation-builder-inline-text-block " + largeClassName}
                style={{
                  minWidth: "106px",
                  height: "auto",
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                  display: "flex",
                }}
              >
                <div style={{ alignSelf: "center" }}>Validations: </div>
              </div>
              <div style={{ display: "block", width: "100%", height: "auto", paddingTop: "2px", paddingBottom: "2px" }}>
                {validations.map((v, vIdx) => (
                  <ValidationRuleCondition
                    key={"ValidationRuleComponent__" + idx + "__RuleConditionComponent__" + vIdx}
                    conditionIdx={vIdx}
                    parentRuleIdx={idx}
                    options={options}
                    validationTypes={validationTypes}
                    largeClassName={largeClassName}
                    selectedField={selectedField}
                    handleRuleConditionTypeChange={handleRuleConditionTypeChange}
                    handleRuleConditionValueChange={handleRuleConditionValueChange}
                    deleteRuleCondition={deleteRuleCondition}
                    addRuleCondition={addRuleCondition}
                    {...v}
                  />
                ))}
              </div>
              <div style={{ alignSelf: "center" }}>
                <Button
                  large={large}
                  icon="plus"
                  intent={Intent.PRIMARY}
                  minimal={minimal}
                  outlined={outlined}
                  onClick={() => addRuleCondition(idx)}
                  fill={fill}
                  text="Condition"
                />
              </div>
              {/* ^ shouldnt take up full width; should have plus button; each rule/condition should have delete or duplicate option */}
            </div>
          </Card>
        </div>
      ) : (
        <ValidationRuleNoPredicate />
      )}
    </Card>
  );
}

// individual validation rule condition where predicate calls for a collection of validation rule conditions
interface ValidationRuleConditionComponentProps extends ValidationConditionValue {
  options: Partial<DefaultOptions>;
  conditionIdx: number;
  parentRuleIdx: number;
  key?: string;
  validationTypes: ValidationConditionTypeDatum[];
  largeClassName?: string;
  selectedField: FieldData;
  deleteRuleCondition: (ruleIdx: number, conditionIdx: number) => void;
  addRuleCondition: (ruleIdx: number, conditionIdx: number) => void;
  handleRuleConditionTypeChange: (t: any, validationRuleIdx: number, validationRuleConditionIdx: number) => void;
  handleRuleConditionValueChange: (
    e: any,
    type: VALIDATION_CONDITION_INPUT_COMPONENT,
    validationRuleIdx: number,
    validationRuleConditionIdx: number,
  ) => void;
}

function ValidationRuleCondition({
  options,
  largeClassName,
  conditionIdx,
  parentRuleIdx,
  selectedField,
  type,
  value,
  validationTypes,
  deleteRuleCondition,
  addRuleCondition,
  handleRuleConditionTypeChange,
  handleRuleConditionValueChange,
}: ValidationRuleConditionComponentProps) {
  const { outlined, minimal, large } = options;
  return (
    <ControlGroup
      fill={true}
      vertical={false}
      style={{ paddingTop: "2px", paddingBottom: "2px", paddingLeft: "4px", paddingRight: "4px" }}
    >
      <div
        className={"validation-builder-inline-text-block " + largeClassName}
        style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
      >
        <span>Type: </span>
      </div>
      <div style={{ width: "280px" }}>
        <HTMLSelect
          options={validationTypes.map((o) => o.type)}
          value={type}
          onChange={(e: any) => handleRuleConditionTypeChange(e, parentRuleIdx, conditionIdx)}
          minimal={minimal}
          large={large}
          className="validation-vanilla-select validation-rule-select-button"
          fill
        />
      </div>
      <ValidationRuleConditionWidgetRenderer
        options={options}
        conditionIdx={conditionIdx}
        parentRuleIdx={parentRuleIdx}
        value={value}
        selectedField={selectedField}
        type={type}
        validationTypes={validationTypes}
        largeClassName={largeClassName}
        handleRuleConditionValueChange={handleRuleConditionValueChange}
      />
      <Popover
        content={
          <Menu {...(options as IMenuProps)}>
            <MenuItem
              icon="trash"
              onClick={() => deleteRuleCondition(parentRuleIdx, conditionIdx)}
              shouldDismissPopover={true}
              {...(options as IMenuItemProps)}
              intent={Intent.DANGER}
              text="Remove Condition"
            />
            <MenuItem
              icon="duplicate"
              onClick={() => addRuleCondition(parentRuleIdx, conditionIdx)}
              shouldDismissPopover={true}
              {...(options as IMenuItemProps)}
              text="Duplicate Condition"
              />
          </Menu>
        }
        placement="bottom"
        {...(options as IPopoverProps)}
      >
        <Button
          icon="more"
          large={large}
          minimal={minimal}
          outlined={outlined}
          className="validation-rule-select-button"
        />
      </Popover>
    </ControlGroup>
  );
}

function ValidationRuleConditionWidgetRenderer({
  type: validationConditionType,
  value,
  parentRuleIdx,
  conditionIdx,
  handleRuleConditionValueChange,
  options: {
    minimal,
    fill,
    large,
    // outlined,
    // inline
  },
  validationTypes,
  largeClassName,
  selectedField,
}: Omit<
  ValidationRuleConditionComponentProps,
  "handleRuleConditionTypeChange" | "addRuleCondition" | "deleteRuleCondition"
>) {
  const widgetTypeData = validationTypes.find((t) => t.type === validationConditionType);
  let widget = <div />;
  const err = <pre>Malformed Validation Condition Data</pre>;
  if (widgetTypeData) {
    const { type: widgetType, label, component, data } = widgetTypeData;
    const {
      type,
      icon,
      tagIntent,
      color,
      bgColor: backgroundColor,
      intent,
      defaultValue,
      values,
      min,
      max,
      numDecimals,
      stepSize,
    } = data;
    const widgetId = `${validationConditionType}__${widgetType}__${type ?? "N/A"}:${parentRuleIdx}-${conditionIdx}`;
    const leftElement =
      typeof type !== "undefined" ? (
        <span className="bp3-input-left-container">
          <Tag minimal={minimal} large={large} intent={tagIntent ?? intent}>
            {type.toUpperCase()}
          </Tag>
        </span>
      ) : undefined;
    const leftIcon = typeof leftElement !== "undefined" ? undefined : icon;
    // const inputClassName = minimal ? "" : ""
    // const numInput
    switch (component) {
      case VALIDATION_CONDITION_INPUT_COMPONENT.AUTO_COMPLETE_INPUT:
        widget = (
          <FieldAutoComplete
            id={widgetId}
            minimal={minimal}
            value={value}
            large={large}
            endpointData={data}
            selectedField={selectedField}
            parentRuleIdx={parentRuleIdx}
            conditionIdx={conditionIdx}
            handleRuleConditionValueChange={handleRuleConditionValueChange}
          />
        );
        break;
      case VALIDATION_CONDITION_INPUT_COMPONENT.TEXT_INPUT:
        widget = (
          <InputGroup
            id={widgetId}
            value={value}
            fill={true}
            large={large}
            leftElement={leftElement}
            leftIcon={leftIcon}
            intent={intent}
            style={{
              color,
              backgroundColor,
            }}
            // onchange
            defaultValue={defaultValue}
            placeholder={
              "Enter a " +
              (type === "Regex" ? "pattern" : "value") +
              (selectedField?.value?.[1] ? " for Tag: " + selectedField?.value?.[1] : "")
            }
            onChange={(e) =>
              handleRuleConditionValueChange(
                e,
                VALIDATION_CONDITION_INPUT_COMPONENT.TEXT_INPUT,
                parentRuleIdx,
                conditionIdx,
              )
            }
          />
        );
        break;
      case VALIDATION_CONDITION_INPUT_COMPONENT.NUM_INPUT:
        widget = (
          <NumericInput
            id={widgetId}
            value={value}
            min={min}
            max={max}
            stepSize={stepSize}
            minorStepSize={numDecimals}
            large={large}
            intent={intent}
            style={{ color, backgroundColor, paddingLeft: "60px" }}
            defaultValue={defaultValue?.[0]}
            leftIcon={leftElement ?? leftIcon}
            className={
              leftElement
                ? "validation-num-input " + (type === INPUT_COMPONENT_TYPE.FLOAT ? "long" : "short")
                : undefined
            }
            placeholder={"Enter a " + type?.toLowerCase}
            fill
            onValueChange={(numVal, strVal) =>
              handleRuleConditionValueChange(
                { numVal, strVal, type },
                VALIDATION_CONDITION_INPUT_COMPONENT.NUM_INPUT,
                parentRuleIdx,
                conditionIdx,
              )
            }
          />
        );
        break;
      case VALIDATION_CONDITION_INPUT_COMPONENT.NUM_RANGE:
        widget = (
          <ControlGroup id={widgetId} fill={true} vertical={false}>
            <div
              className={"validation-builder-inline-text-block " + largeClassName}
              style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
            >
              <span>Min: </span>
            </div>
            <NumericInput
              value={value[0]}
              min={min}
              max={parseFloat(value[1]) > max! ? max : value[1]}
              leftIcon={leftElement ?? leftIcon}
              stepSize={stepSize}
              minorStepSize={numDecimals}
              large={large}
              intent={intent}
              style={{ color, backgroundColor }}
              defaultValue={defaultValue?.[0]}
              placeholder="Enter Min"
              className={
                leftElement
                  ? "validation-num-input " + (type === INPUT_COMPONENT_TYPE.FLOAT ? "long" : "short")
                  : undefined
              }
              fill
              onValueChange={(numVal, strVal) =>
                handleRuleConditionValueChange(
                  { min: true, numVal, strVal, type },
                  VALIDATION_CONDITION_INPUT_COMPONENT.NUM_RANGE,
                  parentRuleIdx,
                  conditionIdx,
                )
              }
            />
            <div
              className={"validation-builder-inline-text-block " + largeClassName}
              style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
            >
              <span>Max: </span>
            </div>
            <NumericInput
              value={value[1]}
              min={parseFloat(value[0]) < min! ? min : value[0]}
              max={max}
              stepSize={stepSize}
              minorStepSize={numDecimals}
              large={large}
              intent={intent}
              style={{ color, backgroundColor }}
              defaultValue={defaultValue?.[1]}
              placeholder="Enter Max"
              fill
              onValueChange={(numVal, strVal) =>
                handleRuleConditionValueChange(
                  { min: false, numVal, strVal, type },
                  VALIDATION_CONDITION_INPUT_COMPONENT.NUM_RANGE,
                  parentRuleIdx,
                  conditionIdx,
                )
              }
            />
          </ControlGroup>
        );
        break;
      case VALIDATION_CONDITION_INPUT_COMPONENT.SELECT:
        // implement label as tool tip
        widget = (
          <ControlGroup id={widgetId} fill={true} vertical={false}>
            <div
              className={"validation-builder-inline-text-block " + largeClassName}
              style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
            >
              <span>{widgetType}: </span>
            </div>
            <HTMLSelect
              options={values?.map((o) => o.type)}
              value={value}
              minimal={minimal}
              large={large}
              className="validation-vanilla-select validation-rule-select-button"
              fill
              onChange={(e) =>
                handleRuleConditionValueChange(
                  e,
                  VALIDATION_CONDITION_INPUT_COMPONENT.SELECT,
                  parentRuleIdx,
                  conditionIdx,
                )
              }
            />
          </ControlGroup>
        );
        break;
      case VALIDATION_CONDITION_INPUT_COMPONENT.ICON:
        widget = (
          <InputGroup
            id={widgetId}
            disabled={true}
            leftElement={leftElement}
            leftIcon={leftIcon}
            large={large}
            intent={intent}
            fill
            style={{ color, backgroundColor, cursor: "default" }}
          />
        );
        break;
      default:
        widget = err;
    }
  } else {
    widget = err;
  }
  return widget;
}

export function useValidationAutoCompleteData(selectedField: FieldData, endpointData: Partial<ACInputComponentData>, id?: string) {
  const { client } = useAuthState();

  const autoCompleteContent = useQuery([id ?? "validationAutoComplete", selectedField.value], async () => {
    if (endpointData.cell && endpointData.notebook) {
      const { notebook, cell } = endpointData;
      const { value } = selectedField;
      const lookup = value?.[1] ?? value;
      return await client.newExecNb(notebook, cell, { lookup });
    }
    return null;
  });
  return autoCompleteContent as any;
}

interface FieldAutoCompleteProps extends Partial<DefaultOptions> {
  endpointData: Partial<ACInputComponentData>;
  selectedField: FieldData;
  id: string;
  value: any;
  parentRuleIdx: number;
  conditionIdx: number;
  handleRuleConditionValueChange: (
    e: any,
    type: VALIDATION_CONDITION_INPUT_COMPONENT,
    validationRuleIdx: number,
    validationRuleConditionIdx: number,
  ) => void;
  // onchange
}
function FieldAutoComplete({
  id,
  parentRuleIdx,
  conditionIdx,
  endpointData,
  selectedField,
  handleRuleConditionValueChange,
  large,
  fill,
  minimal,
  value,
  // onchange
  ...options
}: FieldAutoCompleteProps) {
  const [displayStandardInput, setDisplayStandardInput] = useImmer<boolean>(false);
  const { data, isLoading } = useValidationAutoCompleteData(selectedField, endpointData);
  const items = data?.content ?? [];
  return data === null || items.length === 0 || displayStandardInput ? (
    <InputGroup
      placeholder="Enter a match value"
      value={value[1]}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        handleRuleConditionValueChange(
          ["Unstructured Search Term", e.target.value],
          VALIDATION_CONDITION_INPUT_COMPONENT.AUTO_COMPLETE_INPUT,
          parentRuleIdx,
          conditionIdx,
        )
      }
      fill
      large={large}
      rightElement={
        <Button
          large={large}
          minimal={minimal}
          outlined={true}
          icon="search"
          text="Autocomplete"
          disabled={data === null || items.length === 0}
          onClick={() => {
            setDisplayStandardInput((d) => false);
          }}
        />
      }
    />
  ) : (
    <ValidationBuilderAutoComplete
      items={items}
      {...options}
      itemRenderer={renderItem}
      itemListRenderer={renderMenu}
      itemPredicate={filterItem}
      inputValueRenderer={renderInputValue}
      itemsEqual={areTagsEqual}
      onItemSelect={(val) =>
        handleRuleConditionValueChange(
          val,
          VALIDATION_CONDITION_INPUT_COMPONENT.AUTO_COMPLETE_INPUT,
          parentRuleIdx,
          conditionIdx,
        )
      }
      selectedItem={value}
      popoverProps={{ minimal, fill, placement: "bottom-start" }}
      inputProps={{
        className: large ? "bp3-large" : undefined,
        leftIcon: isLoading ? <Spinner intent={Intent.NONE} size={Spinner.SIZE_SMALL} tagName="span" className="eic-ac-loading"/> : "search",
        rightElement: (
          <Button
            large={large}
            minimal={minimal}
            outlined={true}
            icon="add"
            text="Create New"
            onClick={() => {
              setDisplayStandardInput((d) => true);
            }}
          />
        ),
        fill: true,
        large,
      }}
      disabled={isLoading}
      fill
    />
  );
}

// display component for no predicate check type
export function ValidationRuleNoPredicate() {
  return null;
}
