import React from "react";

export function AtomLogo(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="70" height="69" fill="none" viewBox="0 0 68 67" style={props.style}>
      <g filter="url(#filter0_d)">
        <path
          fill="#E6F7FF"
          d="M29.705 44.027a.935.935 0 01-.622-.238 96.883 96.883 0 01-4.821-4.619c-3.317-3.227-6.157-6.51-8.42-9.741a1.096 1.096 0 01-.228-.234c-1.97-2.893-3.596-5.81-4.83-8.672-2.816-6.573-2.792-11.373.073-14.268 2.786-2.911 7.52-3.006 14.06-.27 1.28.53 2.632 1.123 4.017 1.76.041.019.082.041.121.066.234.152.432.276.595.37a1.518 1.518 0 01.356.216l.44.381a.96.96 0 01.1 1.344.936.936 0 01-1.328.103l-.353-.306-.055-.029c-.19-.107-.43-.252-.717-.438a115.944 115.944 0 00-3.895-1.705c-5.758-2.408-9.794-2.46-11.999-.156-2.274 2.3-2.165 6.39.312 12.172 1.166 2.706 2.703 5.471 4.565 8.22.124.1.254.233.347.408 2.189 3.113 4.934 6.284 8.162 9.424a93.934 93.934 0 004.744 4.544c.39.349.428.95.084 1.345a.938.938 0 01-.708.323z"
        ></path>
      </g>
      <g filter="url(#filter1_d)">
        <path
          fill="#E6F7FF"
          d="M18.949 26.774a.934.934 0 01-.542-.173.962.962 0 01-.229-1.33l.818-1.177.044-.057a55.804 55.804 0 014.639-5.014c3.205-3.155 6.412-5.834 9.525-7.954a.862.862 0 01.15-.083c3.065-2.151 6.157-3.836 9.192-5.009 6.504-2.73 11.225-2.634 14.004.295 2.875 2.883 2.901 7.69.059 14.27a35.42 35.42 0 01-2.15 4.569.915.915 0 01-.09.133l-.408.51a.935.935 0 01-1.328.137.96.96 0 01-.136-1.34l.356-.446a33.46 33.46 0 002.01-4.288c2.516-5.822 2.625-9.917.342-12.207-2.203-2.32-6.224-2.267-11.962.141-2.959 1.144-5.959 2.786-8.94 4.892a.977.977 0 01-.174.097c-2.98 2.042-6.054 4.618-9.143 7.658a54.259 54.259 0 00-4.467 4.824l-.798 1.147a.937.937 0 01-.772.405z"
        ></path>
      </g>
      <g filter="url(#filter2_d)">
        <path
          fill="#E6F7FF"
          d="M51.286 54.033c-2.307 0-5.029-.64-8.15-1.921a40.671 40.671 0 01-5.257-2.514l-.693-.427a.945.945 0 01-.309-1.303.948.948 0 011.305-.306l.665.409a38.717 38.717 0 004.993 2.384c5.752 2.36 9.826 2.385 12.128.086 2.193-2.265 2.076-6.334-.361-12.085-1.347-3.038-3.193-6.152-5.48-9.24-2.13-2.87-4.724-5.827-7.709-8.788a258.564 258.564 0 00-5.46-5.013.945.945 0 01-.078-1.336.948.948 0 011.339-.077 256.22 256.22 0 015.517 5.066c3.07 3.046 5.726 6.075 7.913 9.021 2.37 3.201 4.287 6.435 5.697 9.613 2.768 6.532 2.755 11.293-.027 14.166-1.513 1.51-3.529 2.265-6.033 2.265z"
        ></path>
      </g>
      <g filter="url(#filter3_d)">
        <path
          fill="#E6F7FF"
          d="M16.123 54.033c-2.501 0-4.506-.755-5.994-2.267-2.837-2.885-2.838-7.664-.005-14.203a47.858 47.858 0 012.356-4.748c.148-.358.362-.672.638-.935l.37-.455a.949.949 0 011.473 1.196l-.412.508c-.032.039-.066.074-.103.107a.83.83 0 00-.222.33 1.006 1.006 0 01-.061.13 45.923 45.923 0 00-2.295 4.616c-2.499 5.766-2.627 9.843-.386 12.122 2.266 2.303 6.336 2.272 12.095-.087 3.216-1.257 6.479-3.062 9.711-5.367.067-.044.14-.09.218-.129 2.805-1.992 5.661-4.427 8.491-7.236a88.044 88.044 0 005.142-5.388l.89-1.257a.95.95 0 011.549 1.097l-.917 1.297a.937.937 0 01-.06.075 90.386 90.386 0 01-5.276 5.532c-2.926 2.904-5.893 5.428-8.813 7.494a.916.916 0 01-.175.098c-3.33 2.372-6.718 4.243-10.056 5.548-3.13 1.28-5.854 1.922-8.158 1.922z"
        ></path>
      </g>
      <g filter="url(#filter4_ddd)">
        <path
          fill="#1890FF"
          fillRule="evenodd"
          d="M38.44 21.598v2.275h-6.825v3.958h6.535v2.275h-6.535v4.28h6.977v2.276h-9.889V21.598h9.738z"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <filter
          id="filter0_d"
          width="38.084"
          height="56.027"
          x="0.69"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"></feColorMatrix>
          <feOffset dy="4"></feOffset>
          <feGaussianBlur stdDeviation="4"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.901961 0 0 0 0 0.968627 0 0 0 0 1 0 0 0 0.18 0"></feColorMatrix>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"></feBlend>
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"></feBlend>
        </filter>
        <filter
          id="filter1_d"
          width="56.717"
          height="38.774"
          x="10.007"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"></feColorMatrix>
          <feOffset dy="4"></feOffset>
          <feGaussianBlur stdDeviation="4"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.901961 0 0 0 0 0.968627 0 0 0 0 1 0 0 0 0.18 0"></feColorMatrix>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"></feBlend>
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"></feBlend>
        </filter>
        <filter
          id="filter2_d"
          width="38.774"
          height="56.372"
          x="28.64"
          y="9.662"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"></feColorMatrix>
          <feOffset dy="4"></feOffset>
          <feGaussianBlur stdDeviation="4"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.901961 0 0 0 0 0.968627 0 0 0 0 1 0 0 0 0.18 0"></feColorMatrix>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"></feBlend>
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"></feBlend>
        </filter>
        <filter
          id="filter3_d"
          width="57.752"
          height="39.464"
          x="0"
          y="26.57"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"></feColorMatrix>
          <feOffset dy="4"></feOffset>
          <feGaussianBlur stdDeviation="4"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.901961 0 0 0 0 0.968627 0 0 0 0 1 0 0 0 0.18 0"></feColorMatrix>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"></feBlend>
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"></feBlend>
        </filter>
        <filter
          id="filter4_ddd"
          width="25.888"
          height="31.064"
          x="20.703"
          y="17.598"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"></feColorMatrix>
          <feOffset></feOffset>
          <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0"></feColorMatrix>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"></feBlend>
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"></feColorMatrix>
          <feOffset></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.0941176 0 0 0 0 0.564706 0 0 0 0 1 0 0 0 0.12 0"></feColorMatrix>
          <feBlend in2="effect1_dropShadow" result="effect2_dropShadow"></feBlend>
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"></feColorMatrix>
          <feOffset dy="4"></feOffset>
          <feGaussianBlur stdDeviation="4"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.0941176 0 0 0 0 0.564706 0 0 0 0 1 0 0 0 0.18 0"></feColorMatrix>
          <feBlend in2="effect2_dropShadow" result="effect3_dropShadow"></feBlend>
          <feBlend in="SourceGraphic" in2="effect3_dropShadow" result="shape"></feBlend>
        </filter>
      </defs>
    </svg>
  );
}
