import React, { useEffect, useRef } from "react";
import parse, { domToReact, attributesToProps } from "html-react-parser";
import produce from "immer";
import {
  Icon,
  ControlGroup,
  InputGroup,
  Label,
  NumericInput,
  Checkbox,
  ProgressBar,
  Switch,
  Intent,
  HTMLSelect as BPHTMLSelect,
  IOptionProps,
  Classes,
  Dialog,
} from "@blueprintjs/core";

import * as W from ".";
import { HtmlMenu } from "./html2/Menu";
import { HtmlTab } from "./html2/Tab";

import { Theme, ElementStateLookup, usePageNamespaceData, Namespace, NamespaceProps, useAuthState } from "../state";
import { checkObjNotEmpty } from "../Utils";

import "./html.css";
import { LinkWrapper } from "./LinkWrapper";
import { IDateRangeShortcut } from "@blueprintjs/datetime";
import { SelectOption, SelectWidget } from "./select";

export interface IHtml2Props {
  content: IHtml2Content;
  id: string;
}

export interface IHtml2Content {
  text: string;
}

interface GenericHTMLWidgetProps {
  key: string;
  attrs: HtmlAttrs;
}

let idx = 0;

function generateKey(pre: string | number) {
  return `${pre}_${new Date().getTime()}`;
}

export interface InputErrorState {
  error: boolean;
  message: string;
  icon: {
    placement: "leftIcon" | "rightIcon";
    type: string;
    intent: Intent;
  };
}

export interface InputValue {
  type: HTMLInputElement["type"];
  value: boolean | string;
}

export interface InputControlState {
  errorState: InputErrorState;
  required?: boolean;
  data: InputValue;
}

export const defaultInputControlState: Partial<InputControlState> = {
  errorState: {
    error: false,
    message: "Please enter a value",
    icon: {
      placement: "leftIcon",
      type: "warning-sign",
      intent: Intent.WARNING,
    },
  },
};
function InputControl({ key, attrs }: GenericHTMLWidgetProps) {
  const initialState = {
    ...defaultInputControlState,
    required: !!attrs?.required,
    data: {
      type: attrs?.type,
      value: attrs?.type === "switch" ? !!attrs.checked : attrs.value!,
    },
  } as InputControlState;
  const [inputState, setInputState, destroyState] = usePageNamespaceData<InputControlState>(
    attrs?.id,
    ElementStateLookup.INPUT,
    initialState,
  );
  const {
    data: { value, type },
    errorState: {
      error,
      message: errorMessage,
      icon: { placement: errorIconPlacement, type: errorIconType, intent: errorIntent },
    },
  } = inputState;

  const setValue = (v: InputValue["value"]) =>
    setInputState(
      produce(inputState, (draftState) => {
        if (
          draftState.required === true &&
          draftState.errorState.error === true &&
          draftState.data.type !== "switch" &&
          v !== ""
        ) {
          draftState.errorState.error = false;
        }
        draftState.data.value = v;
        return draftState;
      }),
    );

  // TODO: conditionally infer types string | boolean https://www.notion.so/Typescript-f024ab7d00bb493eb5d7b01f1f7007f0#527f4adc81ff4304b6124c6d3ee56b89
  useEffect(() => {
    // add flag to conditionally destroy state -> prop/notebook driven
    return () => destroyState();
  }, [destroyState]);

  console.log({ attrs });
  if (attrs?.type === "file") {
    return (
      <W.FileInputWidget
        id={attrs.id}
        disabled={!!attrs.disabled}
        text={attrs.text}
        accept={attrs.accept}
        key={key}
        type={attrs.type}
        required={!!attrs.required}
        label={attrs.label}
        fileDataDelimiter={attrs.fileDataDelimiter}
        allowTablePrune={(attrs.allowTablePrune as unknown) as boolean}
      />
    );
  }
  console.log("input attrs: ", attrs)
  return (
    <>
      <Label htmlFor={attrs.id} className={Classes.INLINE} key={key}>
        <span className="eic-label">{attrs.label}</span>
        {!attrs.disabled && attrs.required && "*"}
      </Label>
      {attrs?.type === "switch" ? (
        <Switch
          id={attrs.id}
          // defaultChecked={!!attrs.checked}
          checked={value as boolean}
          onChange={() => setValue(!value)}
          large={attrs.large !== undefined}
        />
      ) : (
        <InputGroup
          id={attrs.id}
          placeholder={error ? errorMessage : attrs.placeholder ?? attrs.label}
          required={!!attrs.required}
          value={error ? undefined : (value as string)}
          {...{ [errorIconPlacement || 'leftIcon']: error ? errorIconType : attrs.icon }}
          onChange={({ target: { value: newState } }) => setValue(newState)}
          intent={error ? errorIntent : (attrs.intent as Intent)}
          disabled={attrs.disabled ? true : false}
          type={type ? type : "text"}
        />
      )}
    </>
  );
}

function NumberInput({ key, attrs }: GenericHTMLWidgetProps) {
  const [value, setValue, destroyState] = usePageNamespaceData<string>(
    attrs?.field,
    ElementStateLookup.NUMERIC_INPUT,
    attrs?.value,
  );

  useEffect(() => {
    // add flag to conditionally destroy state -> prop/notebook driven
    return () => destroyState();
  }, [destroyState]);

  return (
    <ControlGroup fill={true} vertical={false} key={key}>
      <Label>
        {attrs.label}
        <NumericInput
          id={attrs.field}
          placeholder={attrs.label}
          defaultValue={value}
          onValueChange={(numVal, strVal) => setValue(strVal)}
          min={attrs.min ? parseFloat(attrs.min) : 0}
          max={attrs.max ? parseFloat(attrs.max) : 9999}
          clampValueOnBlur={true}
        />
      </Label>
    </ControlGroup>
  );
}

function LabelCheckbox({ key, attrs }: GenericHTMLWidgetProps) {
  const [value, setValue, destroyState] = usePageNamespaceData<boolean>(
    attrs?.id,
    ElementStateLookup.CHECKBOX,
    !!attrs?.checked,
  );
  useEffect(() => {
    // add flag to conditionally destroy state -> prop/notebook driven
    return () => destroyState();
  }, [destroyState]);

  return (
    <ControlGroup fill={true} vertical={false} key={key}>
      <Label className=".bp3-inline">
        {attrs.label}
        <Checkbox id={attrs.id} checked={value} onChange={() => setValue(!value)} />
      </Label>
    </ControlGroup>
  );
}

type HtmlAttrs = { [s: string]: string };

// function hasJsonStructure(str: string) {
//   if (typeof str !== 'string') return false;
//   try {
//       const result = JSON.parse(str);
//       const type = Object.prototype.toString.call(result);
//       return type === '[object Object]' 
//           || type === '[object Array]';
//   } catch (err) {
//       return false;
//   }
// }
function Widget({ key, attrs }: GenericHTMLWidgetProps) {
  // const pageParams = usePageParams();
  // const pageParams = {}
  // const pageNotebook = usePageNotebook();
  let params = {};
  try {
    console.log("params from attrs: ", attrs.params)
    params = attrs.params ? JSON.parse(attrs.params.replace(/'/g, '"')) : {};
  } catch (ex) {}
  // if (pageParams) {
  //   params = { ...params, ...pageParams };
  // }
  const widget = <W.WidgetLoader notebook={attrs["notebook"]} cell={attrs.cell} params={params} />
  return widget;
}

type Node =
  | {
    type: string;
    name: string;
    children: Node[];
  }
  | undefined;
const table = (key: string, attrs: HtmlAttrs, children: Node[], props: IHtml2Props, transform: any) => {
  if (attrs["id"] === undefined) {
    return;
  }
  const thead: Node = children.find((value: any): undefined | any => {
    if (value["type"] === "tag" && value["name"] === "thead") {
      return value;
    }
    return undefined;
  });
  if (thead === undefined) {
    return;
  }
  const cols = thead.children.filter((value: any) => {
    if (value["type"] === "tag" && value["name"] === "tr") {
      return value;
    }
    return undefined;
  });
  if (cols === undefined) {
    return;
  }

  let headers = [] // TODO: type this properly
  if (cols[0]) {
    headers = cols[0].children.reduce((prevValue: any, value: any) => {
        if (value["type"] === "tag" && value["name"] === "th") {
          prevValue.push({ Header: value.children[0]["data"], accessor: value.attribs["id"] });
        }
        return prevValue;
      }, []);
  } 
  // headers = [{Header: "Test", accessor: "test", columns: headers}]
  // if (cols.length > 1) {
  //   cols.slice(1).forEach((c, idx) => {
  //     if (c) {
  //       const trueIdx = idx + 1;
  //       const mappedColumns = c.children.reduce((prevValue: any, value: any) => {
  //         if (value["type"] === "tag" && value["name"] === "th") {
  //           prevValue.push({ Header: value.children[0]["data"], accessor: value.attribs["id"] });
  //         }
  //         return prevValue;
  //       }, []);

  //     }
  //   })

  // }

  const tbody: any = children.find((value: any) => {
    if (value["type"] === "tag" && value["name"] === "tbody") {
      return value;
    }
    return undefined;
  });
  const rows = tbody.children.reduce((prevValue: any, value: any) => {
    if (value["type"] === "tag" && value["name"] === "tr") {
      const rowData = value.children.reduce((p: any, v: any) => {
        if (v["type"] === "tag" && v["name"] === "td") {
          if (v.attribs["action"]) {
            p["action"] = v.attribs["action"];
          }
          if (v.children.length > 0) {
            if (v.children[0]["type"] === "tag") {
              p[v.attribs["id"]] = decodeHtmlNodes(v.children[0], props, transform);
            } else {
              p[v.attribs["id"]] = v.children[0]["data"];
            }
            if (p[v.attribs["id"]] === undefined) {
              p[v.attribs["id"]] = domToReact(v.children, { replace: transform });
            }
          }
        }
        return p;
      }, {});
      if (value.attribs["onclick"]) {
        rowData["_callback"] = value.attribs["onclick"];
      }
      prevValue.push(rowData);
    }
    return prevValue;
  }, []);

  console.log("paging data in html: ", attrs.pagingData, typeof attrs.pagingData);
  let pagingData = undefined;
  if (attrs.pagingData) {
    pagingData = JSON.parse(attrs.pagingData);
  }
  return (
    <W.Table
      key={key}
      name={attrs.name}
      id={attrs.id}
      checkbox={attrs.checkbox ? true : false}
      defaultcheckall={attrs.defaultcheckall ? true : false}
      uniqueIdColumnName={attrs.uniqueIdColumnName}
      pagingData={pagingData}
      cols={headers}
      rows={rows}
      theme={Theme.DARK}
    />
  );
};

interface SelectOptionProps {
  disabled?: boolean;
  className?: string;
  label?: string;
  value: number | string;
}

interface SelectAttrs {
  id: string;
  minimal?: boolean;
  large?: boolean;
  disabled?: boolean;
  fill?: boolean;
  required?: boolean;
  label: string;
}

interface SelectProps {
  key: string;
  attrs: SelectAttrs;
  initialValue: SelectOptionProps;
  options: SelectOptionProps[];
}

function HTMLSelect({
  key,
  attrs: { id, minimal, fill, large, disabled, required, label },
  initialValue,
  options,
}: SelectProps) {
  const [selectState, setSelectState, destroyState] = usePageNamespaceData<SelectOptionProps>(
    id,
    ElementStateLookup.SELECT,
    initialValue,
  );
  useEffect(() => {
    return () => destroyState();
  }, [destroyState]);

  const HTMLSelect = (props: {}) => (
    <BPHTMLSelect
      id={id}
      key={"select" + key}
      value={selectState.value}
      options={options}
      onChange={({ target: { value } }) => {
        console.log("select event", value, options);
        setSelectState(options.filter((o) => o.value === value)[0]);
      }}
      minimal={!!minimal}
      fill={!!fill}
      large={!!large}
      disabled={!!disabled}
    />
  );
  return (
    <>
      {label && 
      <Label htmlFor={id} className={Classes.INLINE} key={key}>
        <span className="eic-label">{label}</span>
        {!disabled && required && "*"}
      </Label>}
      <HTMLSelect />
    </>
  )
}

function formatNodeAttribsAsHTMLSelectProps(node: any): [SelectOptionProps, SelectOptionProps[]] {
  let initialSelectValue = {} as SelectOptionProps;
  const selectOptions = node.children.reduce(
    (children: Array<string | number | IOptionProps>, child: any, idx: number) => {
      if (child.name === "option") {
        const {
          attribs: { value, disabled, className },
        } = child;
        let { label } = child;
        if (label === undefined && child.children[0]?.data) {
          label = child.children[0].data;
        }
        const formattedOptionData = { value, disabled: !!disabled, className, label };
        if (!checkObjNotEmpty(initialSelectValue) || child.attribs?.selected !== undefined) {
          initialSelectValue = formattedOptionData;
        }
        children.push(formattedOptionData);
      }
      return children;
    },
    [],
  );
  return [initialSelectValue, selectOptions];
}

function rawHtmlAttribToCamelCase(name: string) {
  return name.replace(/-([a-z])/g, function (g) {
    return g[1].toUpperCase();
  });
}

function formatNodeAttribsAsSelectWidgetProps(node: any): [SelectOption, SelectOption[]] {
  let selectedValue = {} as SelectOption;
  const selectOptions = node.children.reduce(
    (children: any, child: any, idx: number) => {
      if (child.name === "option") {
        let {
          attribs: { value, disabled, minimal, fill, intent, large, outlined, bgColor, style, info, icon, round },
        } = child;
        let { label } = child;
        if (label === undefined && child.children[0]?.data) {
          label = child.children[0].data;
        }
        minimal = convertHtmlBoolAttrib(minimal); 
        disabled = convertHtmlBoolAttrib(disabled);
        fill = convertHtmlBoolAttrib(fill);
        large = convertHtmlBoolAttrib(large)
        outlined = convertHtmlBoolAttrib(outlined)
        round = convertHtmlBoolAttrib(round)
        const formattedOptionData: SelectOption = { 
          value, 
          disabled: !!disabled, 
          text: label, 
          minimal,
          style,
          icon,
          bgColor,
          info,
          round,
          fill,
          large,
          outlined,
          intent  
        };
        if (!checkObjNotEmpty(selectedValue) || child.attribs?.selected !== undefined) {
          selectedValue = formattedOptionData;
        }
        if (value !== "None") {
          children.push(formattedOptionData);
        }
      }
      return children;
    },
    [] as SelectOption[],
  );
  return [selectedValue, selectOptions];
}

export function convertStringifiedBoolToBool(v: any) {
  if (typeof v === "string") {
    if (v === "True" || v === "true") {
      return true;
    }
    if (v === "False" || v === "false") {
      return false;
    }
  }
  return v;
}

function convertHtmlBoolAttrib(attrib: string | undefined) {
  return typeof attrib === "undefined" ? false : convertStringifiedBoolToBool(attrib)
}

function reformatHtmlAttribObj(attribs: any) {
  return Object.fromEntries(
    Object.entries(attribs).map(([name, val]) => [rawHtmlAttribToCamelCase(name), convertStringifiedBoolToBool(val)]),
  ) as Record<string, string>;
}

interface IFrameWidgetProps {
  src: string;
  title: string;
  style?: any; // type
  frameBorder?: any; // type
}

function IFrameWidget(props: any) {
  const {user} = useAuthState()
  let {attrs: iFrameAttrs} = props;
  console.log("iframe: ", iFrameAttrs)
  iFrameAttrs = attributesToProps(iFrameAttrs)
  let {src, title, frameBorder, style: iFrameStyle } = iFrameAttrs as unknown as IFrameWidgetProps;
  let srcURL: URL | string = new URL(src)
  const targetHost = srcURL.origin
  const targetPathname = srcURL.pathname
  srcURL.searchParams.set("parentApplicationHost", window.location.origin)
  srcURL = srcURL.toString()
  console.log("constructing iframe target host: ", targetHost)
  const iFrameRef = useRef<HTMLIFrameElement>(null)

  useEffect(() => {
    if (iFrameRef.current) {
      const iFrameWindow = iFrameRef.current.contentWindow 
      if (iFrameWindow) {
        console.log("posting message", targetHost)
        setTimeout(() => {
          iFrameWindow.postMessage({user: user, targetPathname}, targetHost)
        }, 500)
      }
    }
  }, [targetHost, targetPathname, user])


  return <iframe ref={iFrameRef} title={title} src={srcURL} style={iFrameStyle} frameBorder={frameBorder} key={props.key}/>
}

export interface ModalWidgetProps {
  id: string;
  isOpen: boolean;
  className?: string;
  children: any;
}

function ModalWidget(props: ModalWidgetProps) {
  console.log("modal id in modalWidget: ", props.id, props.children)
  const [modalIsOpen, setModalIsOpen] = usePageNamespaceData<boolean>(
    props.id,
    ElementStateLookup.MODAL,
    props.isOpen,
  );


  return <Dialog isOpen={modalIsOpen} className={props.className} onClose={() => setModalIsOpen(!modalIsOpen)}>
    {props.children}
  </Dialog>

}

// add dispatch types
// type node and attributees
// type transform
const decodeHtmlNodes = (node: any, props: IHtml2Props, transform: any) => {
  if (node.type === "tag") {
    node.attribs = reformatHtmlAttribObj(node.attribs);
    const attrs: HtmlAttrs = node.attribs;
    attrs["reachParentId"] = props.id;
    idx = idx + 1;
    const key = generateKey(idx);
    const nodeProps = { key, attrs, reachParentId: props.id };
    switch (node.name) {
      case "button":
        return <W.ButtonWidget key={key} id="1" content={node.attribs as W.IButtonContent} />;
      case "select":
        const [initialSelectValue, selectOptions] = formatNodeAttribsAsHTMLSelectProps(node);
        return <HTMLSelect key={key} initialValue={initialSelectValue} options={selectOptions} attrs={node.attribs} />;
      case "selectwidget":
        const rc = formatNodeAttribsAsSelectWidgetProps(node);
        let {id, ...options} = node.attribs
        let { value, disabled, minimal, fill, intent, large, outlined, bgColor, style, info, icon, round } = options
        minimal = convertHtmlBoolAttrib(minimal); 
        disabled = convertHtmlBoolAttrib(disabled);
        fill = convertHtmlBoolAttrib(fill);
        large = convertHtmlBoolAttrib(large)
        outlined = convertHtmlBoolAttrib(outlined)
        round = convertHtmlBoolAttrib(round)
        options = { value, disabled, minimal, fill, intent, large, outlined, bgColor, style, info, icon, round }
        const content = {
          options,
          data: rc[1],
          selectedValue: rc[0]
        }
        console.log("selectwidget attrs", rc, content)
        // return <span>"hi"</span>;
        return <SelectWidget id={id} content={content}/>;
      case "input":
        return <InputControl {...(nodeProps as GenericHTMLWidgetProps)} />;
      case "modal":
        let modalProps = nodeProps as any
        const children = domToReact(node.children, {replace: transform})
        console.log("in modal: ", nodeProps, node.children, node, children)
        return <ModalWidget id={modalProps.attrs.id} isOpen={modalProps.attrs.isOpen} className={modalProps.attrs.className}>{children}</ModalWidget>
      case "iframe":
        return <IFrameWidget {...nodeProps}/>
      case "daterange":
        const range = [attrs.startDate, attrs.endDate] as any
        let shortcuts: boolean | IDateRangeShortcut[] = true;
        if (attrs.shortcuts) {
          if (typeof attrs.shortcuts === "string") {
            try {
              shortcuts = JSON.parse(attrs.shortcuts)
              if (Array.isArray(shortcuts)) {
                shortcuts = shortcuts.map((s) => {
                  s["dateRange"] = [new Date(s["dateRange"][0]!), new Date(s["dateRange"][1]!)]
                  return s
                })
              }
            } catch (err: any) {
              console.error("unable to parse daterange shortcuts: ", err)
            }
          } else {
            shortcuts = attrs.shortcuts
          }
        }
        return <W.DateRangeWidget key={key} id={attrs.id} content={{range, options: {shortcuts}}}/>;
      case "number":
        return <NumberInput {...(nodeProps as GenericHTMLWidgetProps)} />;
      case "checkbox":
        return <LabelCheckbox {...(nodeProps as GenericHTMLWidgetProps)} />;
      case "menu":
        return HtmlMenu(key, node.attribs, node.children);
      case "ul":
        if (node.attribs.role === "tablist") {
          return HtmlTab(key, node.attribs, node.children);
        }
        return;
      case "table":
        return table(key, node.attribs, node.children, props, transform);
      case "a":
        return <LinkWrapper attribs={node.attribs} text={node.children[0]?.["data"]} />;
      case "icon":
        if (node.attribs["size"]) {
          node.attribs["iconSize"] = node.attribs["size"];
        }
        return <Icon {...node.attribs} />;
      case "widget":
        return <Widget key={key} attrs={attrs} />;
      case "pre":
        return <pre>{node.children.map((line: any) => line["data"])}</pre>;
      case "namespace":
        return <Namespace {...(node.attribs as NamespaceProps)} />;
      case "span":
        if (node.attribs.type !== undefined) {
          switch (node.attribs.type) {
            case "progressbar":
              return <ProgressBar key={key} {...node.attribs} />;
          }
        }
    }
  }
};

export function Html2Widget(props: IHtml2Props) {
  const html = props.content.text;
  const transform = (node: any) => {
    return decodeHtmlNodes(node, props, transform);
  };
  return <React.Fragment key={"html2widget" + props.id}>{parse(html, { replace: transform })}</React.Fragment>;
}
