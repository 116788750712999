import React from "react";
import { Switch } from "react-router-dom";
import { enableMapSet } from "immer";

import { Toaster, Position } from "@blueprintjs/core";
import { QueryClient, QueryClientProvider } from "react-query";

import { SignOn, Main } from "./views";
import { CommandPalette } from "./CmdPalette";
import UnprotectedRoute from "./routing/UnprotectedRoute";
import ProtectedRoute from "./routing/ProtectedRoute";
import { AuthContextProvider } from "./state";

import "./fonts/din-pro/font.css";
import "./fonts/fira-code/font.css";
import "./App.css";
import "./branding.css";

enableMapSet();

export const AppToaster = Toaster.create({
  className: "recipe-toaster",
  position: Position.TOP,
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      refetchOnMount: true,
      // staleTime: Infinity
    },
  },
});

export function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthContextProvider>
        <Switch>
          <UnprotectedRoute path="/login" exact={true}>
            <SignOn />
          </UnprotectedRoute>
          <ProtectedRoute path="/">
            <Main />
          </ProtectedRoute>
        </Switch>
        <CommandPalette />
      </AuthContextProvider>
    </QueryClientProvider>
  );
}
