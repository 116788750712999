import React from "react";
import { Popover, Icon, H3 } from "@blueprintjs/core";
import * as packageJSON from "../../package.json";
import { Theme } from "../state";

export * from "./SignOn";
export * from "./Main";
export * from "./view-components";

export const versionNum: string = packageJSON["version"];

export const buildName: string = "{" + packageJSON["build_name"] + "}";

export const buildNotes: any = (theme: any) => (
  <Popover
    content={
      <>
        <H3>Build {buildName} Notes</H3>
        <ul>
          {packageJSON["build_notes"].map((n: string, idx: number) => (
            <li id={"BN" + idx} key={"BN-key-" + idx}>
              {n}
            </li>
          ))}
        </ul>
      </>
    }
    popoverClassName={"bp3-popover" + (theme === Theme.DARK ? " bp3-dark" : " ") + " bp3-popover-content-sizing"}
  >
    <Icon icon="info-sign" />
  </Popover>
);
