import React from "react";
import {
  Button,
  Alignment,
  IconName,
  ButtonGroup,
  Collapse,
  Divider,
  MaybeElement,
  Intent,
  Icon,
} from "@blueprintjs/core";
import { Link, useLocation } from "react-router-dom";
import { VIEWS } from "../../../routing";
import { NewSubMenu } from ".";
import * as CustomIcons from "../CustomIcons";

export type subMenuDatum = {
  displayName: string;
  icon: IconName | MaybeElement;
  notebookName: string;
};

export interface IMenuRecord {
  displayName: string;
  icon?: IconName | MaybeElement;
  rightIcon?: IconName | MaybeElement;
  iconSize?: number;
  intent?: Intent;
  notebook: string;
  cell: string;
  fontSize?: string;
  height?: string;
  params: any; // object
}

export interface IMenuGroup extends IMenuRecord {
  subMenu: IMenuRecord[];
  topDivider?: boolean;
  bottomDivider?: boolean;
}

export interface IViewData {
  activeView: VIEWS;
  subMenuData?: string[] | subMenuDatum[];
  basePathname: string;
  nestedPathname: string;
}

interface IButtonMenuState {
  alignText: Alignment;
  fill: boolean;
  large: boolean;
  minimal: boolean;
  vertical: boolean;
}

interface IButtonMenuProps {
  themeClass: string;
  expandedNav: boolean;
  style?: any;
  handleNavHover: any;
  viewData: IViewData;
  menuData?: (IMenuGroup | IMenuRecord)[];
}

const initialMenuState: IButtonMenuState = {
  alignText: Alignment.LEFT,
  fill: false,
  large: false,
  minimal: true,
  vertical: true,
};

export function formatRouteParams(params: any) {
  if (!params || JSON.stringify(params) === "{}") {
    return "";
  }
  const routeParams = "?" + new URLSearchParams(params).toString();
  return routeParams;
}

function renderNavMenuItem(
  navBtnData: IMenuRecord | IMenuGroup,
  activeView: string,
  expandedNavMode: boolean,
  location: string,
  idx: number,
) {
  const { notebook, cell, params, icon: i, rightIcon, displayName, intent, fontSize, iconSize, height } = navBtnData;
  const { subMenu, topDivider, bottomDivider } = navBtnData as IMenuGroup;
  const displaySubMenu = subMenu && subMenu.length > 0;
  const routeParams = formatRouteParams(params);
  const icon = (i as string) === "eican" ? CustomIcons.hubSpokeIcon(iconSize) : i;
  console.log("icon in menu is: ", icon);
  activeView = "/Page/" + activeView;

  return (
    <React.Fragment key={"navmenu-" + idx}>
      {topDivider && (
        <Divider
          style={{
            borderColor: "#E6F7FF",
            marginLeft: "12px",
            marginRight: "12px",
            opacity: ".5",
          }}
        />
      )}
      <Link to={notebook + "/" + cell + routeParams} className="nav-menu-link">
        <Button
          active={
            activeView === notebook ||
            (displaySubMenu ? subMenu.filter((navItem) => navItem.notebook === activeView).length > 0 : false)
          }
          icon={icon && <Icon icon={icon} iconSize={iconSize} />}
          rightIcon={
            expandedNavMode &&
            (displaySubMenu || rightIcon) && (
              <Icon icon={displaySubMenu ? "caret-down" : rightIcon} iconSize={iconSize} />
            )
          }
          fill={true}
          intent={intent}
          text={expandedNavMode ? <span className="bp3-text-overflow-ellipsis DashH3">{displayName}</span> : undefined}
          style={{
            fontSize,
            height,
            lineHeight: height,
          }}
        />
      </Link>
      {displaySubMenu && (
        <Collapse
          isOpen={
            (activeView === notebook ||
              (displaySubMenu ? subMenu.filter((navItem) => navItem.notebook === activeView).length > 0 : false)) &&
            expandedNavMode
          }
        >
          <NewSubMenu nodes={subMenu} location={location} expandedNavMode={expandedNavMode} />
        </Collapse>
      )}
      {bottomDivider && (
        <Divider
          style={{
            borderColor: "#E6F7FF",
            marginLeft: "12px",
            marginRight: "12px",
            opacity: ".5",
          }}
        />
      )}
    </React.Fragment>
  );
}

export function ButtonMenu({ themeClass, expandedNav, style, handleNavHover, menuData }: IButtonMenuProps) {
  const { pathname, search } = useLocation();
  const basepathPageRoute = pathname.split("/")[2];
  const location = pathname + search;
  console.log("menuData is: ", menuData);

  return (
    <>
      <ButtonGroup
        {...initialMenuState}
        className={themeClass}
        onMouseEnter={() => handleNavHover("onHover")}
        onMouseLeave={() => handleNavHover("leaveHover")}
        style={{ minWidth: expandedNav ? "200px" : 80, ...style }}
      >
        {menuData &&
          menuData.map((navItem: IMenuGroup | IMenuRecord, idx: number) =>
            renderNavMenuItem(navItem, basepathPageRoute, expandedNav, location, idx),
          )}
      </ButtonGroup>
    </>
  );
}
