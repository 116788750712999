import { Icon, Intent } from "@blueprintjs/core";
import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import { useImmer } from "use-immer";
import { App, AppToaster } from "../../App";
import { EChartWidget, EChartWidgetProp } from "../EChartWidget";
import * as H from "history";
import { Theme } from "../../state";
import * as echarts from "echarts";

export interface IWsProps {
  url: string;
  id: string;
}

export type TCmd = {
  type: "app" | "widget" | "log" | "test" | "hide" | "show" | "toast" | "route" | "echart" | "update";
  id: string;
  content: any;
  history: H.History<H.LocationState>;
};

function processCmd(cmd: TCmd) {
  switch (cmd.type) {
    case "app":
      {
        const em = document.createElement("div");
        em.id = cmd.id;
        const root = document.getElementById("root");
        document.body.insertBefore(em, root);
        ReactDOM.render(
          <Router>
            <App />
          </Router>,
          document.getElementById(cmd.id),
        );
      }
      break;
    case "hide": {
      const em = document.getElementById(cmd.id);
      if (em) {
        em.hidden = true;
      }
      break;
    }
    case "show": {
      const em = document.getElementById(cmd.id);
      if (em) {
        em.hidden = false;
      }
      break;
    }
    case "toast": {
      AppToaster.show(cmd.content);
      break;
    }
    case "route": {
      cmd.history.push(cmd.content);
      break;
    }
    case "echart": {
      const prop: EChartWidgetProp = {
        id: cmd.id,
        content: cmd.content,
        appTheme: Theme.DARK,
      };
      const em = document.createElement("div");
      em.id = "ws_" + cmd.id;
      const root = document.getElementById("graph");
      root?.appendChild(em);
      ReactDOM.render(
        <Router>
          <div style={{ height: 300 }}>
            <EChartWidget {...prop} />
          </div>
        </Router>,
        em,
      );
      break;
    }
    case "update":
      {
        const em = document.getElementById(cmd.id) as HTMLDivElement;
        if (em) {
          const ec = echarts.getInstanceByDom(em);
          if (ec) {
            ec.setOption(cmd.content);
            window.M = ec;
          }
        }
      }
      break;
    default:
      console.error("Unknown cmd: ", cmd);
  }
}

export function WsWidget(props: IWsProps) {
  const ws = useRef<WebSocket>();
  const [status, setStatus] = useImmer<Intent>("danger");
  const history = useHistory();

  useEffect(() => {
    ws.current = new WebSocket(props.url);
    ws.current.onopen = (ev: Event) => {
      console.log("Open: ", ev);
      setStatus((draft) => "success");
    };
    ws.current.onclose = (ev: Event) => {
      console.log("Close: ", ev);
      setStatus((draft) => "danger");
    };
    ws.current.onmessage = (ev) => {
      console.log("Msg: ", ev);
      const payload: TCmd = JSON.parse(ev.data);
      payload.history = history;
      processCmd(payload);
      setStatus((draft) => {
        draft = "primary";
        setTimeout(() => {
          setStatus((draft) => "success");
        }, 1000);
      });
    };

    return () => ws.current?.close();
  }, [props.url, setStatus, history]);
  return (
    <Icon
      icon="exchange"
      intent={status}
      iconSize={30}
      style={{ zIndex: 100, position: "absolute", top: 0, right: 0, marginRight: 10 }}
    />
  );
}
