import React, { ReactNode, useState } from "react";
import { Button, ButtonGroup, Checkbox, ControlGroup, HTMLSelect, Icon, Intent, Navbar, NumericInput } from "@blueprintjs/core";
import { Cell } from "react-table";
import { IButtonContent, ButtonWidget, WidgetController } from "..";

// Cell Rendering Utilities and Component Renderers
interface ICellPattern {
  RemoveRow: () => ReactNode;
  Delete: () => ReactNode;
  Date: (dt: string) => JSX.Element;
  Bool: (d: boolean) => JSX.Element;
  Array: (a: object[]) => ReactNode;
  Object: (o: object) => JSX.Element;
  Widget: (w: JSX.Element) => JSX.Element;
  Other: (c: Cell) => ReactNode;
}

function cellPattern(p: ICellPattern): (cell: Cell) => JSX.Element | ReactNode {
  return (cell: Cell): JSX.Element | ReactNode => {
    if (cell.column.Header === "!Del") {
      return p.Delete();
    }
    if (typeof cell.column.Header === "string" && cell.column.Header.toLowerCase().includes("!removerow")) {
      console.log("cell is: ", cell);
      return p.RemoveRow();
    }
    if (cell.column.Header === "created") {
      return p.Date(cell.value);
    }
    if (["True", "False"].indexOf(cell.value) > -1) {
      return p.Bool(cell.value === "True" ? true : false);
    }
    if (Array.isArray(cell.value)) {
      return p.Array(cell.value);
    }
    if (React.isValidElement(cell.value)) {
      return p.Widget(cell.value);
    }
    if (typeof cell.value === "object") {
      return p.Object(cell.value);
    }
    return p.Other(cell);
  };
}

export const renderCell = (cell: Cell, uniqueIdColumnName: string | undefined, setTableStateContext: any) => {
  const fn = cellPattern({
    RemoveRow: () => {
      if (uniqueIdColumnName) {
        const removeRowFromContext = (curriedState: any) => {
          console.log(
            "removing row: ",
            uniqueIdColumnName,
            cell.value,
            JSON.parse(JSON.stringify(curriedState.rows)),
            curriedState.rows.findIndex((row: any) => row.origRowIdx === parseInt(cell.value)),
          );
          curriedState.rows.splice(
            curriedState.rows.findIndex((row: any) => {
              const match = row[uniqueIdColumnName] === cell.value;
              console.log(row[uniqueIdColumnName], { match });
              return match;
            }),
            1,
          );
          return curriedState;
        };
        const btn = (
          <Button
            intent={Intent.DANGER}
            icon="remove"
            minimal
            onClick={() => setTableStateContext(removeRowFromContext)}
          />
        );
        return btn;
      }
      return <Icon icon="error" intent={Intent.DANGER} />;
    },
    Delete: () => {
      const props: IButtonContent = {
        icon: "trash",
        intent: Intent.DANGER,
        field: "",
        href: `${cell.value}?id=${cell.row.values["id"]}`,
        action: "form://delete",
      };

      const btn = <ButtonWidget id="btn" content={props} />;
      return btn;
    },
    Date: (dt: string) => {
      try {
        return <em>{new Date(Date.parse(dt)).toUTCString()}</em>;
      } catch {
        return <span>{dt}</span>;
      }
    },
    Bool: (b: boolean) => {
      if (b) {
        return <Icon icon="tick" style={{ color: "limegreen" }} />;
      }
      return <Icon icon="cross" style={{ color: "pink" }} />;
    },
    Array: (a: object[]) => {
      return <div>{a}</div>;
    },
    Widget: (w: JSX.Element) => w,
    Object: (o: any) => {
      // console.log("##", { o })
      return WidgetController({ cell: o });
      // return <></>
    },
    Other: (c: Cell) => c.render("Cell"),
  });
  const rc = fn(cell);
  return rc;
};
// End Cell Renderer

// Pagination Toolbar
export const PaginationToolbar = (
  tableOpts: any,
  setTableStateContext: any,
  updateControlledTableMetadataLocalStorage: any,
  updateTableMetadataServerState: any,
  id: string,
) => {
  const [currentPageNumber, setCurrentPageNumber] = useState(tableOpts.state.pageIndex + 1)
  const changePaginationData = (newPaginationDatum: number, type: "pageIndex" | "pageSize") => async (
    curriedState: any,
  ) => {

    console.log("updating table metadata: ", JSON.parse(JSON.stringify(curriedState.controlledTableMetadata)));
    curriedState.controlledTableMetadata[type] = newPaginationDatum;
    if (tableOpts.manualPagination && type === "pageSize") {
      curriedState.controlledTableMetadata["pageCount"] = Math.ceil(curriedState.controlledTableMetadata["totalItems"] / newPaginationDatum)
    }
    // const { sortBy, ...paginationData } = curriedState.controlledTableMetadata
    // updateControlledTableMetadataLocalStorage(paginationData)
    // updateTableMetadataServerState({ pagination: paginationData })
    updateControlledTableMetadataLocalStorage(curriedState.controlledTableMetadata);
    updateTableMetadataServerState({ controlledTableMetadata: curriedState.controlledTableMetadata });

    return curriedState;
  };

  const handlePageNumberChangeFromInput = () => {
    const page = currentPageNumber - 1;
    console.log("running click is: ", currentPageNumber)
    tableOpts.gotoPage(page);
    if (tableOpts.manualPagination) {
      setTableStateContext(changePaginationData(page, "pageIndex"));
    }
  } 
  
  let pageSizeOptions = [10, 20, 30, 40, 50];
  if (!pageSizeOptions.includes(tableOpts.state.pageSize)) {
    pageSizeOptions.push(tableOpts.state.pageSize);
    pageSizeOptions = pageSizeOptions.sort();
  }
  return (
    <Navbar>
      <Navbar.Group>
        <ButtonGroup minimal>
          <Button
            intent="success"
            icon="double-chevron-left"
            minimal
            onClick={() => {
              tableOpts.gotoPage(0);
              if (tableOpts.manualPagination) {
                setTableStateContext(changePaginationData(0, "pageIndex"));
              }
            }}
            disabled={!tableOpts.canPreviousPage}
          />
          <Button
            icon="arrow-left"
            intent="primary"
            minimal
            onClick={() => {
              tableOpts.previousPage();
              if (tableOpts.manualPagination) {
                setTableStateContext(changePaginationData(tableOpts.state.pageIndex - 1, "pageIndex"));
              }
            }}
            disabled={!tableOpts.canPreviousPage}
          />
          <Button
            intent="primary"
            icon="arrow-right"
            minimal
            onClick={() => {
              tableOpts.nextPage();
              if (tableOpts.manualPagination) {
                setTableStateContext(changePaginationData(tableOpts.state.pageIndex + 1, "pageIndex"));
              }
            }}
            disabled={!tableOpts.canNextPage}
          />
          <Button
            intent="success"
            icon="double-chevron-right"
            minimal
            onClick={() => {
              tableOpts.gotoPage(tableOpts.pageCount - 1);
              if (tableOpts.manualPagination) {
                setTableStateContext(changePaginationData(tableOpts.pageCount - 1, "pageIndex"));
              }
            }}
            disabled={!tableOpts.canNextPage}
          />
        </ButtonGroup>
      </Navbar.Group>
      <Navbar.Group align="right">
        <b>{`Page ${tableOpts.state.pageIndex + 1} of ${tableOpts.pageOptions.length}`}</b>
        <Navbar.Divider />
        <ControlGroup vertical={false}>
          <NumericInput
            value={currentPageNumber}
            fill={false}
            min={1}
            max={tableOpts.pageCount}
            asyncControl
            onValueChange={(n) => {
              if (n <=tableOpts.pageCount) {
                setCurrentPageNumber(n)
              } else {
                setCurrentPageNumber(tableOpts.pageCount)
              }
            }}
            className="bp3-minimal"
          />
          <Button icon="key-enter" onClick={handlePageNumberChangeFromInput} />
        </ControlGroup>
        <Navbar.Divider />
        <HTMLSelect
          value={tableOpts.state.pageSize}
          minimal
          onChange={({ target: { value } }) => {
            tableOpts.setPageSize(Number(value));
            if (tableOpts.manualPagination) {
              setTableStateContext(changePaginationData(Number(value), "pageSize"));
            }
          }}
          options={pageSizeOptions.map((pageSize) => ({ label: "Show " + pageSize, value: pageSize }))}
        />
      </Navbar.Group>
    </Navbar>
  );
};
// End Pagination Toolbar

// Checkbox Component
const areEqual = (prevProps: any, nextProps: any) =>
  prevProps.checked === nextProps.checked && prevProps.indeterminate === nextProps.indeterminate;

export const MemodCheckbox = React.memo(Checkbox, areEqual);
// End Checkbox Component

// ---------------> Old checkbox code <--------------------
// interface CheckboxProps {
//   indeterminate?: boolean;
//   name: string;
//   onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
// }
// const useCombinedRefs = (...refs: any[]): React.MutableRefObject<any> => {
//   const targetRef = React.useRef();

//   React.useEffect(() => {
//     refs.forEach(ref => {
//       if (!ref) return;

//       if (typeof ref === 'function') {
//         ref(targetRef.current);
//       } else {
//         ref.current = targetRef.current;
//       }
//     });
//   }, [refs]);

//   return targetRef;
// };

// const IndeterminateCheckbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
//   ({ indeterminate, ...rest }, ref: React.Ref<HTMLInputElement>) => {
//     const defaultRef = React.useRef(null)
//     const combinedRef = useCombinedRefs(ref, defaultRef);

//     useEffect(() => {
//       if (combinedRef?.current) {
//         combinedRef.current.indeterminate = indeterminate ?? false;
//       }
//     }, [combinedRef, indeterminate]);

//     return (
//       <>
//         <Checkbox ref={combinedRef} {...rest} />
//       </>
//     )
//   }
// )
// -----------------------------------------------------------
