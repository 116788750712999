import React from "react";
import produce from "immer";
import {
  Button,
  Intent,
  Hotkey,
  Hotkeys,
  AnchorButton,
  IButtonProps,
  IAnchorButtonProps,
  Tag,
} from "@blueprintjs/core";
import { HotkeysTarget } from "@blueprintjs/core/lib/esnext/components/hotkeys/hotkeysTarget.js";
import { useHistory } from "react-router";
import { useQueryClient } from "react-query";

import {
  usePage,
  useAuthState,
  useGetNamespacesByType,
  useCheckNamespacesStateValidForSubmit,
  usePageNamespaceDataCB,
  ElementStateLookup,
} from "../state";
import { AppToaster } from "../App";

import { checkObjNotEmpty } from "../Utils";
import { useTableContextData } from "./Table";
import { InputControlState } from ".";

export interface IKeyedButtonProps {
  onClick: (e: any) => void;
  actionLabel: string;
  keySequence: string;
  text: string;
  intent?: Intent;
  type?: "submit" | "reset" | "button";
  large?: boolean;
  small?: boolean;
  fill?: boolean;
  className?: string;
  minimal?: boolean;
  allowInInput?: boolean;
  isAnchor?: boolean;
  outlined?: boolean;
  icon?: IButtonProps["icon"];
  rightIcon?: IButtonProps["icon"];
  global?: boolean;
}

// TODO: should accept children (react node) and text should be string | react.node | undefined
@HotkeysTarget
export class KeyedButton extends React.PureComponent<IKeyedButtonProps, {}> {
  constructor(props: IKeyedButtonProps) {
    super(props);
    this.renderHotkeys = this.renderHotkeys.bind(this);
  }

  private btnRef: HTMLElement | null = null;

  public render() {
    const {
      onClick,
      isAnchor,
      text,
      intent,
      className,
      type,
      large,
      small,
      fill,
      minimal,
      outlined,
      icon,
      rightIcon,
    } = this.props;
    const btnProps = {
      intent: intent || "none",
      fill,
      large,
      small,
      text,
      type,
      className,
      onClick: (e: any) => onClick(e),
      minimal,
      outlined,
      icon,
      rightIcon,
    };
    return (
      <div ref={(ref) => (this.btnRef = ref)}>
        {isAnchor ? <AnchorButton {...(btnProps as IAnchorButtonProps)} /> : <Button {...(btnProps as IButtonProps)} />}
      </div>
    );
  }

  public renderHotkeys() {
    const { onClick, actionLabel, keySequence, allowInInput, global } = this.props;
    return (
      <Hotkeys>
        <Hotkey
          allowInInput={allowInInput}
          global={global !== undefined ? global : true}
          combo={keySequence}
          label={actionLabel}
          onKeyDown={(e: any) => onClick(e)}
        />
      </Hotkeys>
    );
  }
}

export interface IButtonWidgetProps {
  content: IButtonContent;
  id: string;
}

// TODO: implement active/inactive button icons driven from props
// TODO: implement active/inactive button text driven from props
// TODO: link to pieces of state (potentially) for data display -> icons, text, intent/styling, etc
export interface IButtonContent {
  id?: string;
  field: string;
  label?: string;
  description?: string;
  action: string;
  filename?: string;
  href?: string;
  notebook?: string;
  cell?: string;
  params?: any;
  icon?: any;
  rightIcon?: any;
  small?: boolean;
  large?: boolean;
  fill?: boolean;
  minimal?: boolean;
  outlined?: boolean;
  intent?: Intent;
  inputId?: string;
  modalId?: string;
  hidealert?: string | boolean;
  statesubscriberid?: string;
  shoppingcartid?: string;
  stateNamespaces?: string;
  dataProviderName?: string;
  dataProviderType?: ElementStateLookup;
  submitMessages?: string;
  hidebadge?: boolean;
  disabled?: boolean;
}

function useNBIdentifiers() {
  const { notebook, cell, params } = usePage();
  return [notebook, cell, params];
}

export function ButtonWidget(props: IButtonWidgetProps) {
  const {
    content: {
      id,
      disabled,
      hidebadge,
      outlined,
      label,
      minimal,
      icon,
      intent,
      fill,
      small,
      large,
      action,
      href,
      notebook,
      cell,
      params: propsNBParams,
      filename,
      inputId,
      modalId,
      statesubscriberid,
      shoppingcartid,
      stateNamespaces,
      dataProviderName,
      dataProviderType,
      submitMessages,
      rightIcon,
    },
  } = props;
  let {
    content: { hidealert },
  } = props;
  const queryClient = useQueryClient();
  const { client: axiosClient } = useAuthState();
  const [tableSubscriberData, setTableSubscriberData] = useTableContextData(statesubscriberid, "");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [shoppingCartTableData, setShoppingCartTableData] = useTableContextData(shoppingcartid, "");


  const namespaceDatumGetter = usePageNamespaceDataCB();
  const namespaceDataGetter = useGetNamespacesByType();
  const preSubmitValidator = useCheckNamespacesStateValidForSubmit();
  const [pageNb, pageCell] = useNBIdentifiers();
  console.log({ submitMessages, stateNamespaces });

  if (hidealert === undefined && action?.includes("save")) {
    hidealert = false;
  }

  const history = useHistory();
  const { client } = useAuthState();
  const onClick = (e: any) => {
    console.log("firing click", action, inputId, e);
    e.preventDefault()
    e.stopPropagation()
    let routeTo, params, PASSES_VALIDATION;
    let payload: any = {};
    const nameSpaceData = namespaceDataGetter(stateNamespaces);

    switch (action) {
      case "history://back":
        history.goBack();
        break;
      case "history://refresh":
        history.replace(`../${pageNb}/${pageCell}`);
        break;
      case "file://download":
        if (typeof notebook !== "undefined" && typeof cell !== "undefined" && typeof filename !== "undefined") {
          axiosClient.downloadNbOutput(notebook, cell, propsNBParams ?? {}, filename);
        }
        break;
      case "drawer://toggle_visibility":
        const [drawerState, setDrawerState] = namespaceDatumGetter<InputControlState>(
          statesubscriberid!,
          ElementStateLookup.DRAWER_STATE,
        );
        setDrawerState(!drawerState);
        break;
      case "input://toggle_hide":
        if (inputId !== undefined) {
          try {
            const [inputState, setInputState] = namespaceDatumGetter<InputControlState>(
              inputId,
              ElementStateLookup.INPUT,
            );
            setInputState(
              produce(inputState, (draftState) => {
                const {
                  data: { type },
                } = draftState;

                if (type === "password") {
                  draftState.data.type = "text";
                } else if (type === "text") {
                  draftState.data.type = "password";
                }
              }),
            );
          } catch (err) {
            console.error(err);
            AppToaster.show({
              message: "Corresponding Input data does not exist! (check 'input-id' attribute on button)",
              intent: Intent.DANGER,
            });
          }
        }
        break;
      case "input://copy":
        if (inputId !== undefined) {
          try {
            const [inputState] = namespaceDatumGetter<InputControlState>(inputId, ElementStateLookup.INPUT);
            const {
              data: { value },
            } = inputState;
            if (value) {
              navigator.clipboard.writeText("" + value);
              AppToaster.show({ message: "Copied Text!", intent: Intent.SUCCESS });
            }
          } catch (err) {
            console.error(err);
            AppToaster.show({
              message: "Corresponding Input data does not exist (check 'input-id' attribute on button)",
              intent: Intent.DANGER,
            });
          }
        }
        break;
      case "dashboard://publish":
        nameSpaceData.forEach((namespace) => {
          namespace.forEach((d) => {
            if (d.id !== "") {
              payload[d.id] = d.value;
            }
          });
        });
        console.log({ nameSpaceData });
        routeTo = "/Page/" + notebook + "/" + cell;
        params = {};
        if (propsNBParams) {
          if (typeof propsNBParams === "object") {
            payload = { ...propsNBParams, ...payload };
          } else {
            try {
              const parsedNBParams = JSON.parse(propsNBParams);
              payload = { ...parsedNBParams, ...payload };
            } catch (err) {
              console.error(err);
            }
          }
        }
        if (checkObjNotEmpty(payload)) {
          console.log("payload is: ", payload);
          params =
            "?" +
            Object.keys(payload)
              .map((key) => {
                return encodeURIComponent(key) + "=" + encodeURIComponent(JSON.stringify(payload[key]));
              })
              .join("&");
        }
        // early implementation of user alerts - needs to have a better implementation of null check and handle success cases
        PASSES_VALIDATION = preSubmitValidator(stateNamespaces, nameSpaceData, params, submitMessages);
        if (!PASSES_VALIDATION) {
          try {
            let errorMessage = JSON.parse(submitMessages!)["error"];
            AppToaster.show({ message: errorMessage, intent: "warning" });
          } catch (err) {
            console.log("error parsing/looking up dashboard-publish error message", err);
            AppToaster.show({
              message: "Please ensure you have selected valid data before proceeding.",
              intent: "warning",
            });
          }
        } else {
          history.push(routeTo + params);
        }
        break;

      case "dashboard://publish_selected_table_rows":
        payload["table_data"] = [];
        try {
          for (let i in tableSubscriberData) {
            payload["table_data"] = [
              ...payload["table_data"],
              ...tableSubscriberData[i].map((el: Record<string, any>) => {
                const { origRowIdx, ...rc } = el;
                return rc;
              }),
            ];
          }
        } catch (error) {
          console.log("error retrieving table state: ", error);
        }
        if (payload["table_data"].length === 0) {
          try {
            let errorMessage = JSON.parse(submitMessages!)["error"];
            AppToaster.show({ message: errorMessage, intent: "warning" });
          } catch (err) {
            console.log("error parsing/looking up dashboard-publish error message", err);
            AppToaster.show({ message: "Please ensure you have selected data before proceeding.", intent: "warning" });
          }
          break;
        }
        routeTo = "/Page/" + notebook + "/" + cell;
        params = {};
        if (checkObjNotEmpty(payload)) {
          console.log("payload is: ", payload);
          params =
            "?" +
            Object.keys(payload)
              .map((key) => {
                return encodeURIComponent(key) + "=" + encodeURIComponent(JSON.stringify(payload[key]));
              })
              .join("&");
        }
        // dont push in qs - push as values
        history.push(routeTo + params);
        break;

      case "form://clear_shopping_cart":
        setTableSubscriberData({});
        setShoppingCartTableData({});
        history.goBack();
        break;
      case "form://delete":
        const rc = window.confirm("Do you really wish to delete?");
        if (rc && href) {
          client.execViews(href, null).then((rc) => {
            AppToaster.show(rc);
            history.replace(`../${pageNb}/${pageCell}`);
          });
        }
        break;
      case "form://save":
        let error = false;
        console.log({ nameSpaceData });
        nameSpaceData.forEach((namespace) => {
          namespace.forEach((d) => {
            console.log({ d });
            if (d.id !== "" && typeof d.value !== "undefined") {
              payload[d.id] = d.value;
            }
          });
        });
        if (statesubscriberid) {
          payload["table_data"] = [];
          try {
            for (let i in tableSubscriberData) {
              payload["table_data"] = [
                ...payload["table_data"],
                ...tableSubscriberData[i].map((el: Record<string, any>) => {
                  const { origRowIdx, ...rc } = el;
                  return rc;
                }),
              ];
            }
          } catch (error) {
            console.log("error retrieving table state: ", error);
          }
          if (payload["table_data"].length === 0) {
            try {
              let errorMessage = JSON.parse(submitMessages!)["error"];
              AppToaster.show({ message: errorMessage, intent: "warning" });
            } catch (err) {
              console.log("error parsing/looking up dashboard-publish error message", err);
              AppToaster.show({
                message: "Please ensure you have selected valid data before proceeding.",
                intent: "warning",
              });
            }
            break;
          }
        }
        if (propsNBParams) {
          console.log("btnparams? ", propsNBParams)
          if (typeof propsNBParams === "object") {
            payload = { ...propsNBParams, ...payload };
          } else {
            try {
              const parsedNBParams = JSON.parse(propsNBParams);
              payload = { ...parsedNBParams, ...payload };
            } catch (err) {
              console.error(err);
            }
          }
        }
        if (stateNamespaces !== '[]') {
          PASSES_VALIDATION = preSubmitValidator(stateNamespaces, nameSpaceData, undefined, submitMessages);
          if (!PASSES_VALIDATION) {
            break;
          }
        }

        if (notebook && cell && !error) {
          client.newExecNb(notebook, cell, payload).then((rc) => {
            if (typeof rc === "string") {
              if (rc === "history://back") {
                history.goBack();
              } else if (rc === "history://refresh") {
                // history.go(0)
              } else {
                if (hidealert === false) {
                  AppToaster.show({ message: rc });
                }
              }
            } else {
              if (hidealert === false) {
                const { toast, callback, message } = rc;
                if (typeof message === "string") {
                  AppToaster.show({ message });
                } else if (typeof toast === "object") {
                  AppToaster.show(toast);
                }
                if (typeof callback === "string") {
                  if (callback === "history://back") {
                    history.goBack();
                  } else if (callback === "history://refresh") {
                    // history.go(0)
                  } else {
                    history.push(rc["callback"]);
                  }
                }
              }
            }
            if (hidealert === false) {
              queryClient.invalidateQueries({ refetchInactive: true, refetchActive: true });
              if (statesubscriberid) {
                setTableSubscriberData({});
              }
              if (typeof dataProviderName !== "undefined" && typeof dataProviderType !== "undefined") {
                const [namespaceData, setNamespaceData] = namespaceDatumGetter<{ data: any[] }>(
                  dataProviderName,
                  dataProviderType,
                );
                setNamespaceData(
                  produce(namespaceData, (draftState: any) => {
                    const { data } = namespaceData;
                    if (Array.isArray(data)) {
                      draftState.data = [];
                    }
                  }),
                );
              }
            }
          });
        }
        if (href && !error) {
          client.execViews(href, payload).then((rc) => {
            if (rc === "history://back") {
              history.goBack();
            } else if (rc === "history://refresh") {
              history.go(0);
            } else {
              if (hidealert === false) {
                AppToaster.show({ message: rc });
              }
            }
            if (hidealert === false) {
              queryClient.invalidateQueries({ refetchInactive: true, refetchActive: true });
            }
          });
        }
        console.log("in formsave", error);
        break;
      case "modal://toggle":
        console.log("button id is: ", id, "modalId: ", modalId);
        const [modalIsOpen, setModalIsOpen] = namespaceDatumGetter<InputControlState>(
          modalId!,
          ElementStateLookup.MODAL,
        );
        setModalIsOpen(!modalIsOpen);
        break;
      case "cohort://save":
        let err = false;
        console.log({ nameSpaceData });
        nameSpaceData.forEach((namespace) => {
          namespace.forEach((d) => {
            console.log({ d });
            if (d.id !== "" && typeof d.value !== "undefined") {
              payload[d.id] = d.value;
            }
          });
        });
        if (propsNBParams) {
          console.log("btnparams? ", propsNBParams)
          if (typeof propsNBParams === "object") {
            payload = { ...propsNBParams, ...payload };
          } else {
            try {
              const parsedNBParams = JSON.parse(propsNBParams);
              payload = { ...parsedNBParams, ...payload };
            } catch (err) {
              console.error(err);
            }
          }
        }
        if ("clauses" in payload) {
          payload["urlParams"] = encodeURIComponent("clauses") + "=" + encodeURIComponent(JSON.stringify(payload["clauses"]))
        }

        if (stateNamespaces !== '[]') {
          PASSES_VALIDATION = preSubmitValidator(stateNamespaces, nameSpaceData, undefined, submitMessages);
          if (!PASSES_VALIDATION) {
            break;
          }
        }

        if (notebook && cell && !err) {
          client.newExecNb(notebook, cell, payload).then((rc) => {
            if (typeof rc === "string") {
              if (rc === "history://back") {
                history.goBack();
              } else if (rc === "history://refresh") {
                // history.go(0)
              } else {
                if (hidealert === false) {
                  AppToaster.show({ message: rc });
                }
              }
            } else {
              if (hidealert === false) {
                const { toast, callback, message } = rc;
                if (typeof message === "string") {
                  AppToaster.show({ message });
                } else if (typeof toast === "object") {
                  AppToaster.show(toast);
                }
                if (typeof callback === "string") {
                  if (callback === "history://back") {
                    history.goBack();
                  } else if (callback === "history://refresh") {
                    // history.go(0)
                  } else {
                    history.push(rc["callback"]);
                  }
                }
              }
            }
            if (hidealert === false) {
              queryClient.invalidateQueries({ refetchInactive: true, refetchActive: true });
              if (statesubscriberid) {
                setTableSubscriberData({});
              }
              if (typeof dataProviderName !== "undefined" && typeof dataProviderType !== "undefined") {
                const [namespaceData, setNamespaceData] = namespaceDatumGetter<{ data: any[] }>(
                  dataProviderName,
                  dataProviderType,
                );
                setNamespaceData(
                  produce(namespaceData, (draftState: any) => {
                    const { data } = namespaceData;
                    if (Array.isArray(data)) {
                      draftState.data = [];
                    }
                  }),
                );
              }
            }
          });
        }
        if (href && !err) {
          client.execViews(href, payload).then((rc) => {
            if (rc === "history://back") {
              history.goBack();
            } else if (rc === "history://refresh") {
              history.go(0);
            } else {
              if (hidealert === false) {
                AppToaster.show({ message: rc });
              }
            }
            if (hidealert === false) {
              queryClient.invalidateQueries({ refetchInactive: true, refetchActive: true });
            }
          });
        }
        console.log("in formsave", err);

        const [modalIsOpen2, setModalIsOpen2] = namespaceDatumGetter<InputControlState>(
          modalId!,
          ElementStateLookup.MODAL,
        );
        setModalIsOpen2(!modalIsOpen2);

        // implement store
        break;
      case "data_provider://store_id_in_array":
        console.log("button id is: ", id, dataProviderName, dataProviderType);
        // if (notebook && cell && typeof id !== "undefined") {
        //   payload["query"] = id
        //   client.newExecNb(notebook, cell, payload).then((rc) => {
        //   })
        // }
        if (typeof dataProviderName !== "undefined" && typeof dataProviderType !== "undefined") {
          const [namespaceData, setNamespaceData] = namespaceDatumGetter<{ data: any[] }>(
            dataProviderName,
            dataProviderType,
          );
          setNamespaceData(
            produce(namespaceData, (draftState: any) => {
              const { data } = namespaceData;
              if (Array.isArray(data)) {
                if (data.indexOf(id) === -1) {
                  draftState["data"].push(id);
                  if (submitMessages) {
                    AppToaster.show({ message: submitMessages, intent: Intent.PRIMARY });
                  }
                } else {
                  AppToaster.show({ message: "Duplicate", intent: Intent.WARNING });
                }
              }
            }),
          );
        }
        break;
      case "data_provider://remove_id_from_array":
        if (typeof dataProviderName !== "undefined" && typeof dataProviderType !== "undefined") {
          const [namespaceData, setNamespaceData] = namespaceDatumGetter<{ data: any[] }>(
            dataProviderName,
            dataProviderType,
          );
          setNamespaceData(
            produce(namespaceData, (draftState: any) => {
              const { data } = namespaceData;
              if (Array.isArray(data)) {
                const idIndex = data.indexOf(id);
                if (idIndex > -1) {
                  draftState["data"].splice(idIndex, 1);
                  if (submitMessages) {
                    AppToaster.show({ message: submitMessages, intent: Intent.DANGER });
                  }
                } else {
                  AppToaster.show({ message: "Unable to remove item.", intent: Intent.WARNING });
                }
              }
            }),
          );
        }
        break;
      case "data_provider://clear_array":
        if (typeof dataProviderName !== "undefined" && typeof dataProviderType !== "undefined") {
          const [namespaceData, setNamespaceData] = namespaceDatumGetter<{ data: any[] }>(
            dataProviderName,
            dataProviderType,
          );
          setNamespaceData(
            produce(namespaceData, (draftState: any) => {
              const { data } = namespaceData;
              if (Array.isArray(data)) {
                draftState.data = [];
              }
            }),
          );
          if (href === "back") {
            history.goBack();
          }
        }
        break;
      case "nav://href":
        if (href) {
          history.push(href);
        }
        break;
      default:
        console.log("Button: ", action, notebook, cell);
        if (notebook && cell) {
          history.push(`/Page/${notebook}/${cell}`);
        }
        if (action) {
          history.push(action);
        }
    }
  };

  const resolveDisabled = () => {
    if (action === "data_provider://remove_id_from_array") {
      if (typeof dataProviderName !== "undefined" && typeof dataProviderType !== "undefined") {
        const [namespaceData] = namespaceDatumGetter(dataProviderName, dataProviderType);
        if (Array.isArray(namespaceData)) {
          return !namespaceData.includes(id);
        }
      }
    }
    return disabled ?? false;
  };

  const Btn = ({ style }: any) => (
    <Button
      icon={icon}
      rightIcon={rightIcon}
      text={label}
      small={small}
      large={large}
      fill={fill}
      intent={intent}
      minimal={minimal}
      outlined={outlined}
      disabled={resolveDisabled()}
      onClick={(e: any) => onClick(e)}
      style={style}
    />
  );

  console.log(tableSubscriberData);
  const badgeCount = tableSubscriberData
    ? Object.values<Array<any[]>>(tableSubscriberData).reduce((count: number, el: any[]) => {
        return count + el.length;
      }, 0)
    : 0;

  const BadgeWrapper = () => (
    <Tag
      intent={Intent.DANGER}
      minimal={minimal}
      round
      style={{
        position: "absolute",
        top: -6,
        right: -6,
        width: "max-content",
      }}
    >
      {badgeCount > 99 ? "+99" : badgeCount}
    </Tag>
  );

  return !hidebadge && badgeCount > 0 ? (
    <div
      style={{
        position: "relative",
        display: "inline-block",
        // width: "105%"
      }}
    >
      <BadgeWrapper />
      <Btn />
    </div>
  ) : (
    <Btn />
  );
}
