import React, { useEffect } from "react";
import produce from "immer";
import { useImmer } from "use-immer";
import { FixedSizeList as List } from "react-window";
import { Button, ControlGroup, HTMLSelect, InputGroup, Intent, MenuItem, Menu, ButtonGroup } from "@blueprintjs/core";
import { Suggest } from "@blueprintjs/select";
import { ElementStateLookup, usePageNamespaceData } from "../state";

export interface IPatientOptions {
  allowCreate: boolean;
  closeOnSelect: boolean;
  createdItems: [];
  fill: boolean;
  minimal: boolean;
  openOnKeyDown: boolean;
  resetOnClose: boolean;
  resetOnQuery: boolean;
  resetOnSelect: boolean;
}

const defaultOptions: IPatientOptions = {
  allowCreate: false,
  closeOnSelect: true,
  createdItems: [],
  fill: false,
  minimal: true,
  openOnKeyDown: false,
  resetOnClose: false,
  resetOnQuery: true,
  resetOnSelect: false,
};

export function RuleWidget({ value, values, id, options }: any) {
  const [ruleState] = useImmer({ value, values });

  console.log("rulewidget props are: ", options);
  return <Rule data={ruleState.values} initialValue={ruleState.value} id={id} options={options} />;
}

export function Rule(props: any) {
  const { initialValue, data, controlRuleState, id, options } = props;
  const { globalOperators, filterBy, booleanOperators } = data;
  const [ruleState, setRuleState, destroyState] = usePageNamespaceData<any>(
    id,
    ElementStateLookup.RULE_BUILDER,
    initialValue,
  );
  useEffect(() => {
    // add flag to conditionally destroy state -> prop/notebook driven
    return () => destroyState();
  }, [destroyState]);

  function addCondition() {
    setRuleState(
      produce(ruleState, (draftState: any) => {
        draftState.conditions.push(initialValue.conditions[0]);
        if (controlRuleState) {
          controlRuleState(JSON.parse(JSON.stringify(draftState)));
        }
      }),
    );
  }

  function deleteCondition(ruleIndex: number) {
    setRuleState(
      produce(ruleState, (draftState: any) => {
        draftState.conditions.splice(ruleIndex, 1);
        if (controlRuleState) {
          controlRuleState(JSON.parse(JSON.stringify(draftState)));
        }
      }),
    );
  }

  // TODO: refactor to be different functions
  function handleInput(e: any, ruleIndex: number, lookupKey: string, nestedLookup?: string) {
    const {
      target: { value },
    } = e;
    setRuleState(
      produce(ruleState, (draftState: any) => {
        if (value !== draftState.conditions[ruleIndex][lookupKey]) {
          if (nestedLookup !== undefined) {
            draftState.conditions[ruleIndex][lookupKey][nestedLookup] = value;
            if (lookupKey === "filterBy" && nestedLookup === "type") {
              draftState.conditions[ruleIndex][lookupKey].value = filterBy.filter(
                (o: any) => o.type === value,
              )[0].values[0];
            }
          } else {
            draftState.conditions[ruleIndex][lookupKey] = value;
          }
        } else {
          draftState.conditions[ruleIndex][lookupKey] = "";
        }
        if (controlRuleState) {
          controlRuleState(JSON.parse(JSON.stringify(draftState)));
        }
      }),
    );
  }

  function setCreatedFilterByRuleOption(ruleIndex: number, newItemTuple: [string, string]) {
    setRuleState(
      produce(ruleState, (draftState: any) => {
        draftState.conditions[ruleIndex]["filterBy"]["value"] = newItemTuple;
      }),
    );
  }

  function handleChangeGlobalOperator({ target: { value } }: any, id: string) {
    console.log("radio: ", id);
    setRuleState(
      produce(ruleState, (draftState: any) => {
        draftState.globalOperator = id;
        if (controlRuleState) {
          controlRuleState(JSON.parse(JSON.stringify(draftState)));
        }
      }),
    );
  }

  return (
    <div>
      {/* <HTMLSelect
                id="global_operator"
                options={globalOperators}
                value={ruleState.globalOperator}
                onChange={(e: any) => handleChangeGlobalOperator(e)}
                minimal={options.minimal}
                fill={false}
            /> */}
      <ButtonGroup minimal={options.minimal} large={options.large}>
        {globalOperators.map((gOp: string, idx: number) => (
          <Button
            active={ruleState.globalOperator === gOp}
            intent={ruleState.globalOperator === gOp ? Intent.DANGER : Intent.NONE}
            text={gOp}
            key={gOp + idx}
            id={gOp}
            onClick={(e: any) => handleChangeGlobalOperator(e, gOp)}
          />
        ))}
      </ButtonGroup>
      <div style={{ width: "max-content" }}>
        {ruleState.conditions.map((c: any, idx: number) => (
          <Condition
            value={c}
            optionData={{ filterBy, booleanOperators }}
            key={"condition" + idx}
            idx={idx}
            deleteRule={deleteCondition}
            handleInput={handleInput}
            setCreatedFilterByRuleOption={setCreatedFilterByRuleOption}
            styleOptions={options}
          />
        ))}
        {/* plus button which uses the add rule function */}
        <Button
          text="Condition"
          icon="plus"
          intent={Intent.PRIMARY}
          minimal={options.minimal}
          fill={true}
          onClick={() => addCondition()}
        />
      </div>
    </div>
  );
}

export function Condition(props: any) {
  const {
    value,
    styleOptions,
    optionData,
    idx: conditionIdx,
    handleInput,
    deleteRule,
    setCreatedFilterByRuleOption,
  } = props;
  const [createdItems, setCreatedItems] = useImmer(
    optionData.filterBy.map(({ type }: { type: string; values: any[] }) => ({ type, values: [] as any })),
  );

  const renderMenu: any = ({ filteredItems, itemsParentRef, query, renderItem }: any) => {
    return filteredItems.length === 0 ? (
      renderAddItem(query)
    ) : (
      <List
        height={filteredItems.length > 5 ? 150 : 30 * filteredItems.length}
        itemCount={filteredItems.length}
        itemSize={30}
        width={300}
        outerElementType="ul"
        className="bp3-menu"
      >
        {({ index, style }) => renderItem({ itemTuple: filteredItems[index], style })}
      </List>
    );
  };

  function renderAddItem(query: string) {
    const newPrivateTagID = `Private Tag ${
      createdItems.filter((o: any) => o.type === value.filterBy.type)[0].values.length + 1
    }`;
    return (
      <Menu>
        <MenuItem disabled={true} text="No results found." />
        <MenuItem
          text={
            <span>
              Add <strong>{newPrivateTagID}</strong>: <em>{query}</em>
            </span>
          }
          icon="plus"
          onClick={() => {
            const newItemTuple = [newPrivateTagID, query];
            setCreatedItems((draftState) => {
              const mutateIdx = value.filterBy.type === "Dicom Tag" ? 0 : 1;
              draftState[mutateIdx].values.push(newItemTuple);
            });
            setCreatedFilterByRuleOption(conditionIdx, newItemTuple);
          }}
        />
      </Menu>
    );
  }

  function renderItem(item: any, predicate: any) {
    const { itemTuple, style } = item;
    const { modifiers, handleClick } = predicate;

    return (
      <MenuItem
        active={modifiers.active}
        icon={JSON.stringify(value.filterBy.value) === JSON.stringify(itemTuple) ? "tick" : "blank"}
        label={itemTuple[0]}
        onClick={handleClick}
        text={itemTuple[1]}
        shouldDismissPopover={false}
        style={style}
      />
    );
  }

  function renderInputValue(itemTuple: [string, string]) {
    return itemTuple[1];
  }

  return (
    <ControlGroup fill={false} vertical={false} key={"condition-" + conditionIdx}>
      <HTMLSelect
        options={optionData.filterBy.map((o: any) => o.type)}
        value={value.filterBy.type}
        // redesign to take
        onChange={(e: any) => handleInput(e, conditionIdx, "filterBy", "type")}
        minimal={styleOptions.minimal}
      />
      <Suggest
        items={[
          ...createdItems.filter((o: any) => o.type === value.filterBy.type)[0].values,
          ...optionData.filterBy.filter((o: any) => o.type === value.filterBy.type)[0].values,
        ]}
        {...defaultOptions}
        itemRenderer={renderItem}
        itemListRenderer={renderMenu}
        inputValueRenderer={renderInputValue}
        itemPredicate={(query: string, itemTuple: any) =>
          itemTuple[1].toLowerCase().includes(query.toLowerCase()) ||
          itemTuple[0].toLowerCase().includes(query.toLowerCase())
        }
        selectedItem={value.filterBy.value}
        onItemSelect={({ itemTuple }: any) =>
          handleInput({ target: { value: itemTuple } }, conditionIdx, "filterBy", "value")
        }
        popoverProps={{ minimal: true, position: "bottom-left" }}
      />
      <HTMLSelect
        options={optionData.booleanOperators}
        value={value.booleanOperator}
        onChange={(e: any) => handleInput(e, conditionIdx, "booleanOperator")}
        minimal={styleOptions.minimal}
      />
      <InputGroup
        placeholder=""
        value={value.booleanMatchValue}
        onChange={(e: any) => handleInput(e, conditionIdx, "booleanMatchValue")}
      />
      <Button
        icon="delete"
        intent={Intent.DANGER}
        onClick={() => deleteRule(conditionIdx)}
        minimal={styleOptions.minimal}
        outlined={styleOptions.outlined !== undefined ? styleOptions.outlined : styleOptions.minimal === true}
      />
    </ControlGroup>
  );
}
