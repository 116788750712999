import React, { useState } from "react";
import { Dialog } from "@blueprintjs/core";

export interface IDialogProps {
  id: string;
  content: IDialogContent;
}

export interface IDialogContent {
  title?: string;
  widgets: Object[];
}

export interface IDialogState {
  autoFocus: boolean;
  canEscapeKeyClose: boolean;
  canOutsideClickClose: boolean;
  enforceFocus: boolean;
  isOpen: boolean;
  usePortal: boolean;
}

export function DialogWidget(props: IDialogProps) {
  const [state] = useState({
    autoFocus: true,
    canEscapeKeyClose: true,
    canOutsideClickClose: true,
    enforceFocus: true,
    isOpen: true,
    usePortal: true,
  });

  return <Dialog {...state}>{props.content.widgets}</Dialog>;
}
