import React, { useContext, useEffect } from "react";
import { useImmerReducer } from "use-immer";

import * as Cache from "./cache";
import { User, Api, ImmerableApi, getServerURLOriginString } from "../Api";
import { useHistory } from "react-router-dom";

export enum LoginActions {
  LOGIN,
  ERROR,
  LOGOUT,
  INIT_CLIENT,
  CHECK_SESSION,
  COMPLETE_INIT,
}

export type TLoginDispatchAction = { type: LoginActions; payload?: any };

type TDispatch = (action: TLoginDispatchAction) => void;

type AuthProviderProps = { children: React.ReactNode };

interface IGlobalStore {
  authenticated: boolean;
  error: string;
  client: ImmerableApi;
  user: User;
  initializing: boolean;
}

const initialState: IGlobalStore = {
  authenticated: false,
  error: "",
  client: new Api(""),
  user: new User(),
  initializing: true,
};

// check session is valid when retrieving from cache
// register error handling interceptors for client
// redirect user to page initiallly specified after login

function loginReducer(draft: IGlobalStore, action: TLoginDispatchAction) {
  switch (action.type) {
    case LoginActions.LOGIN: {
      // setting user, token and cache should be its own function
      const { payload } = action;
      draft.user = new User(payload["user_id"], payload["role_id"], payload["group_id"], payload["token"]);
      draft.client.setToken(draft.user.token);
      Cache.setItem("user", draft.user);
      draft.error = "";
      draft.authenticated = true;
      return;
    }
    case LoginActions.ERROR: {
      const { payload } = action;
      draft.error = payload;
      draft.authenticated = false;
      return;
    }
    case LoginActions.LOGOUT: {
      // write a "reset" function
      draft.error = "";
      draft.authenticated = false;
      draft.user = new User();
      draft.client.clearToken();
      Cache.clear();
      return;
    }
    case LoginActions.INIT_CLIENT: {
      // factor this into its own function
      let serverUrlOriginString = getServerURLOriginString();
      const interceptorCB = action.payload;

      draft.client = new Api(serverUrlOriginString);
      draft.client.addEICInterceptor(interceptorCB);
      return;
    }
    case LoginActions.CHECK_SESSION: {
      const EiconSession: any = window["EiconSession" as any];
      let user: User | null = null;
      if (!!Cache.getItem("user")) {
        user = Cache.getItem("user") as User;
      } else if (EiconSession && EiconSession.token !== "") {
        user = new User(EiconSession.user_id, EiconSession.role_id, EiconSession.group_id, EiconSession.token);
      }
      if (user) {
        draft.user = user;
        draft.client.setToken(draft.user.token);
        Cache.setItem("user", draft.user);
        draft.authenticated = true;
      }
      return;
    }
    case LoginActions.COMPLETE_INIT: {
      draft.initializing = false;
      return;
    }
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}

const AuthContext = React.createContext<IGlobalStore | undefined>(undefined);
const AuthDispatchContext = React.createContext<TDispatch | undefined>(undefined);

export function AuthContextProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useImmerReducer(loginReducer, initialState);
  const contextInitialized = state.initializing === false;
  const history = useHistory();

  useEffect(() => {
    const LOGOUT: TLoginDispatchAction = { type: LoginActions.LOGOUT };
    const logout = () => dispatch(LOGOUT);
    const notFound = () => history.push("/404");
    const INITIALIZE_CLIENT: TLoginDispatchAction = {
      type: LoginActions.INIT_CLIENT,
      payload: { logout, notFound },
    };
    const CHECK_CACHED_USER: TLoginDispatchAction = {
      type: LoginActions.CHECK_SESSION,
    };
    const COMPLETE_CONTEXT_INIT: TLoginDispatchAction = {
      type: LoginActions.COMPLETE_INIT,
    };
    dispatch(INITIALIZE_CLIENT);
    dispatch(CHECK_CACHED_USER);
    // if (state.authenticated) {
    //   state.client.checkSession
    // }
    dispatch(COMPLETE_CONTEXT_INIT);
  }, [dispatch, history]);

  return (
    <AuthContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>
        {contextInitialized && <main className={state.authenticated ? "App" : "SignOn"}>{children}</main>}
      </AuthDispatchContext.Provider>
    </AuthContext.Provider>
  );
}

export function useAuthState() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuthState must be used within a AuthContextProvider");
  }
  return context;
}

export function useAuthDispatch() {
  const context = useContext(AuthDispatchContext);
  if (context === undefined) {
    throw new Error("useAuthDispatch must be used within a AuthContextProvider");
  }
  return context;
}

export function useGetPermissionLevel() {
  const { user } = useAuthState();
  return user.role_id;
}
