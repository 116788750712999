import React, { useState } from "react";
import { Icon, Switch, IconName, MaybeElement, Classes } from "@blueprintjs/core";

export interface SwitchWidgetProps {
  content: SwitchContent | undefined;
  id: string;
  title?: string;
}

export interface SwitchContent {
  field: string;
  items: {
    isChecked: boolean | string;
    unChecked: { icon: string; value: string };
    checked: { icon: string; value: string };
  };
  label?: string;
  description?: string;
}

const onChange = (checked: boolean, props: SwitchWidgetProps, setState: any) => {
  setState({ checked });
  // const val = {
  //   name: props.content!.field,
  //   value: checked ? props.content!.items.checked.value : props.content!.items.unChecked.value,
  //   id: props.id
  // };
};

export function SwitchWidget(props: SwitchWidgetProps) {
  const propsChecked = props.content && !!props.content.items.isChecked;
  const [state, setState] = useState({ checked: propsChecked });
  // create a wrapper around state to implement the publish when state changes

  return (
    <div
      style={{
        margin: "auto",
        width: "max-content",
      }}
    >
      {props.content ? (
        <>
          {`${props.content.label}: `}
          <Switch
            labelElement={
              <Icon
                icon={
                  state.checked
                    ? (props.content.items.checked.icon as IconName | MaybeElement)
                    : (props.content.items.unChecked.icon as IconName | MaybeElement)
                }
              />
            }
            onChange={() => onChange(!state.checked, props, setState)}
            checked={state.checked}
            large
          />
        </>
      ) : (
        <Switch className={Classes.SKELETON} />
      )}
    </div>
  );
}
