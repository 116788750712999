/* eslint-disable no-undef */
(function (root, factory) {
  if (typeof define === "function" && define.amd) {
    // AMD. Register as an anonymous module.
    define(["exports", "echarts"], factory);
  } else if (typeof exports === "object" && typeof exports.nodeName !== "string") {
    // CommonJS
    factory(exports, require("echarts"));
  } else {
    // Browser globals
    factory({}, root.echarts);
  }
})(this, function (exports, echarts) {
  var log = function (msg) {
    if (typeof console !== "undefined") {
      console && console.error && console.error(msg);
    }
  };
  if (!echarts) {
    log("ECharts is not Loaded");
    return;
  }
  echarts.registerTheme("essos", {
    color: ["#893448", "#d95850", "#eb8146", "#ffb248", "#f2d643", "#ebdba4"],
    backgroundColor: "rgba(242,234,191,0.15)",
    textStyle: {},
    title: {
      textStyle: {
        color: "#893448",
      },
      subtextStyle: {
        color: "#d95850",
      },
    },
    line: {
      itemStyle: {
        normal: {
          borderWidth: "2",
        },
      },
      lineStyle: {
        normal: {
          width: "2",
        },
      },
      symbolSize: "6",
      symbol: "emptyCircle",
      smooth: true,
    },
    radar: {
      itemStyle: {
        normal: {
          borderWidth: "2",
        },
      },
      lineStyle: {
        normal: {
          width: "2",
        },
      },
      symbolSize: "6",
      symbol: "emptyCircle",
      smooth: true,
    },
    bar: {
      itemStyle: {
        normal: {
          barBorderWidth: 0,
          barBorderColor: "#ccc",
        },
        emphasis: {
          barBorderWidth: 0,
          barBorderColor: "#ccc",
        },
      },
    },
    pie: {
      itemStyle: {
        normal: {
          borderWidth: 0,
          borderColor: "#ccc",
        },
        emphasis: {
          borderWidth: 0,
          borderColor: "#ccc",
        },
      },
    },
    scatter: {
      itemStyle: {
        normal: {
          borderWidth: 0,
          borderColor: "#ccc",
        },
        emphasis: {
          borderWidth: 0,
          borderColor: "#ccc",
        },
      },
    },
    boxplot: {
      itemStyle: {
        normal: {
          borderWidth: 0,
          borderColor: "#ccc",
        },
        emphasis: {
          borderWidth: 0,
          borderColor: "#ccc",
        },
      },
    },
    parallel: {
      itemStyle: {
        normal: {
          borderWidth: 0,
          borderColor: "#ccc",
        },
        emphasis: {
          borderWidth: 0,
          borderColor: "#ccc",
        },
      },
    },
    sankey: {
      itemStyle: {
        normal: {
          borderWidth: 0,
          borderColor: "#ccc",
        },
        emphasis: {
          borderWidth: 0,
          borderColor: "#ccc",
        },
      },
    },
    funnel: {
      itemStyle: {
        normal: {
          borderWidth: 0,
          borderColor: "#ccc",
        },
        emphasis: {
          borderWidth: 0,
          borderColor: "#ccc",
        },
      },
    },
    gauge: {
      itemStyle: {
        normal: {
          borderWidth: 0,
          borderColor: "#ccc",
        },
        emphasis: {
          borderWidth: 0,
          borderColor: "#ccc",
        },
      },
    },
    candlestick: {
      itemStyle: {
        normal: {
          color: "#eb8146",
          color0: "transparent",
          borderColor: "#d95850",
          borderColor0: "#58c470",
          borderWidth: "2",
        },
      },
    },
    graph: {
      itemStyle: {
        normal: {
          borderWidth: 0,
          borderColor: "#ccc",
        },
      },
      lineStyle: {
        normal: {
          width: 1,
          color: "#aaaaaa",
        },
      },
      symbolSize: "6",
      symbol: "emptyCircle",
      smooth: true,
      color: ["#893448", "#d95850", "#eb8146", "#ffb248", "#f2d643", "#ebdba4"],
      label: {
        normal: {
          textStyle: {
            color: "#ffffff",
          },
        },
      },
    },
    map: {
      itemStyle: {
        normal: {
          areaColor: "#f3f3f3",
          borderColor: "#999999",
          borderWidth: 0.5,
        },
        emphasis: {
          areaColor: "#ffb248",
          borderColor: "#eb8146",
          borderWidth: 1,
        },
      },
      label: {
        normal: {
          textStyle: {
            color: "#893448",
          },
        },
        emphasis: {
          textStyle: {
            color: "#893448",
          },
        },
      },
    },
    geo: {
      itemStyle: {
        normal: {
          areaColor: "#f3f3f3",
          borderColor: "#999999",
          borderWidth: 0.5,
        },
        emphasis: {
          areaColor: "#ffb248",
          borderColor: "#eb8146",
          borderWidth: 1,
        },
      },
      label: {
        normal: {
          textStyle: {
            color: "#893448",
          },
        },
        emphasis: {
          textStyle: {
            color: "#893448",
          },
        },
      },
    },
    categoryAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: "#aaaaaa",
        },
      },
      axisTick: {
        show: false,
        lineStyle: {
          color: "#333",
        },
      },
      axisLabel: {
        show: true,
        textStyle: {
          color: "#999999",
        },
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ["#e6e6e6"],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: ["rgba(250,250,250,0.05)", "rgba(200,200,200,0.02)"],
        },
      },
    },
    valueAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: "#aaaaaa",
        },
      },
      axisTick: {
        show: false,
        lineStyle: {
          color: "#333",
        },
      },
      axisLabel: {
        show: true,
        textStyle: {
          color: "#999999",
        },
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ["#e6e6e6"],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: ["rgba(250,250,250,0.05)", "rgba(200,200,200,0.02)"],
        },
      },
    },
    logAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: "#aaaaaa",
        },
      },
      axisTick: {
        show: false,
        lineStyle: {
          color: "#333",
        },
      },
      axisLabel: {
        show: true,
        textStyle: {
          color: "#999999",
        },
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ["#e6e6e6"],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: ["rgba(250,250,250,0.05)", "rgba(200,200,200,0.02)"],
        },
      },
    },
    timeAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: "#aaaaaa",
        },
      },
      axisTick: {
        show: false,
        lineStyle: {
          color: "#333",
        },
      },
      axisLabel: {
        show: true,
        textStyle: {
          color: "#999999",
        },
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ["#e6e6e6"],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: ["rgba(250,250,250,0.05)", "rgba(200,200,200,0.02)"],
        },
      },
    },
    toolbox: {
      iconStyle: {
        normal: {
          borderColor: "#999999",
        },
        emphasis: {
          borderColor: "#666666",
        },
      },
    },
    legend: {
      textStyle: {
        color: "#999999",
      },
    },
    tooltip: {
      axisPointer: {
        lineStyle: {
          color: "#cccccc",
          width: 1,
        },
        crossStyle: {
          color: "#cccccc",
          width: 1,
        },
      },
    },
    timeline: {
      lineStyle: {
        color: "#893448",
        width: 1,
      },
      itemStyle: {
        normal: {
          color: "#893448",
          borderWidth: 1,
        },
        emphasis: {
          color: "#ffb248",
        },
      },
      controlStyle: {
        normal: {
          color: "#893448",
          borderColor: "#893448",
          borderWidth: 0.5,
        },
        emphasis: {
          color: "#893448",
          borderColor: "#893448",
          borderWidth: 0.5,
        },
      },
      checkpointStyle: {
        color: "#eb8146",
        borderColor: "rgba(255,178,72,0.41)",
      },
      label: {
        normal: {
          textStyle: {
            color: "#893448",
          },
        },
        emphasis: {
          textStyle: {
            color: "#893448",
          },
        },
      },
    },
    visualMap: {
      color: ["#893448", "#d95850", "#eb8146", "#ffb248", "#f2d643", "rgb(247,238,173)"],
    },
    dataZoom: {
      backgroundColor: "rgba(255,255,255,0)",
      dataBackgroundColor: "rgba(255,178,72,0.5)",
      fillerColor: "rgba(255,178,72,0.15)",
      handleColor: "#ffb248",
      handleSize: "100%",
      textStyle: {
        color: "#333333",
      },
    },
    markPoint: {
      label: {
        normal: {
          textStyle: {
            color: "#ffffff",
          },
        },
        emphasis: {
          textStyle: {
            color: "#ffffff",
          },
        },
      },
    },
  });
});
