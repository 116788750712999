import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";

import { App } from "./App";

declare global {
  interface Window {
    msg: Event;
    em: HTMLElement;
    echart: Object;
    store: Object;
    H: Object;
    M: Object;
  }
}

ReactDOM.render(
  <>
    <Router>
      <App />
    </Router>
    {/* <ReactQueryDevtools initialIsOpen={false} /> */}
  </>,
  document.getElementById("root") as HTMLElement,
);

// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
