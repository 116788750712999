import React, { useCallback, useEffect } from "react";
import produce from "immer";
import { useImmer } from "use-immer";
import {
  Button,
  ControlGroup,
  HTMLSelect,
  InputGroup,
  Intent,
  MenuItem,
  Menu,
  Popover,
  IPopoverProps,
  Card,
  Classes,
  H5,
  Alignment,
  Icon,
  Spinner,
} from "@blueprintjs/core";
import { ElementStateLookup, useAuthState, useGetPageNamespaceDataRO, usePageNamespaceData } from "../state";
import { useQuery } from "react-query";
import { ConditionalWrapper } from "../Utils";
import {
  StringTupleAutoComplete as QueryBuilderAutoComplete,
  arrayContainsItem,
  renderMenu,
  renderItem,
  filterItem,
  renderInputValue,
  areTagsEqual,
  renderCreateNewItem,
  AutoCompleteTuple,
  IDateRangeContent,
  defaultOptions as defaultDateRangeOptions,
} from ".";

import "./styling/ValidationBuilder.css"
import { DateRange, DateRangeInput } from "@blueprintjs/datetime";

interface DefaultQueryOptions {
  allowCreate: boolean;
  closeOnSelect: boolean;
  createdFilterByItems: [];
  fill: boolean;
  minimal: boolean;
  openOnKeyDown: boolean;
  resetOnClose: boolean;
  resetOnQuery: boolean;
  resetOnSelect: boolean;
}

export interface QueryBuilderProps {
  value: QueryBuilderInitData;
  values: QueryBuilderData;
  id: string;
  options: any; //refactor
  depth?: number;
  nestedClausePointer?: number[];
}

interface QueryBuilderInitData {
  globalOperator: GlobalOperator;
  clauses: Array<QueryBuilderDatum | QueryBuilderInitData>;
}

interface QueryBuilderSimpleData {
  globalOperator: GlobalOperator;
  clauses: Array<QueryBuilderDatum>;
}

interface QueryBuilderDatum {
  filterBy: FilterData;
  booleanOperator: BooleanOperator;
  booleanMatchValue: string | ACBooleanMatchData;
}

interface QueryBuilderData {
  globalOperators: Array<GlobalOperator>;
  filterBy: Array<FilterData>;
  booleanOperators: Array<BooleanOperator>;
}

interface ACBooleanMatchData {
  value: AutoCompleteTuple;
  notebook: string;
  cell: string;
}

interface FilterData {
  type: "Dicom Tag" | "EICON Vault Tags" | string;
  values: Array<AutoCompleteTuple>;
  value?: AutoCompleteTuple;
}

type GlobalOperator = "And" | "Or" | "And not";

type BooleanOperator = "Equals" | "NotEquals" | "StartsWith" | "EndsWith" | "Contains" | "NotContains" | "Regex";

const defaultOptions: DefaultQueryOptions = {
  allowCreate: false,
  closeOnSelect: true,
  createdFilterByItems: [],
  fill: false,
  minimal: true,
  openOnKeyDown: false,
  resetOnClose: false,
  resetOnQuery: true,
  resetOnSelect: false,
};

export function useQueryBuilderState(
  id: string,
  value: QueryBuilderInitData,
  root: boolean,
): [QueryBuilderInitData, any] {
  const [queryState, setQueryState, destroyState] = usePageNamespaceData<QueryBuilderInitData>(
    id,
    ElementStateLookup.QUERY_BUILDER,
    value,
  );
  useEffect(() => {
    // add flag to conditionally destroy state -> prop/notebook driven

    return () => {
      if (root) {
        destroyState();
      }
    };
  }, [destroyState, root]);

  return [queryState, setQueryState];
}

export function useGetQueryBuilderStateRO(id: string) {
  const queryState = useGetPageNamespaceDataRO(id, ElementStateLookup.QUERY_BUILDER)
  
  return queryState
}

function extractNestedState(queryState: QueryBuilderInitData, nestedClausePointer: number[], depth: number) {
  const nestedState =
    depth === 0
      ? queryState
      : nestedClausePointer.reduce(
          (reducedState, pointer, index) => {
            return reducedState.clauses[pointer] as QueryBuilderInitData;
          },
          { ...queryState },
        );
  return nestedState;
}

function truncateQueryState(queryState: QueryBuilderInitData, nestedClausePointer: number[], depth: number, clauseIdx: number) {
  const truncatedState = produce(queryState, draft => {
    if (depth === 0) {
      draft.clauses = draft.clauses.slice(0, clauseIdx)
    } else {
      draft = nestedClausePointer.slice(0, depth).reduce(
        (reducedState, pointer, index) => {
          reducedState.clauses.splice(0, pointer);
          return reducedState
        },
        { ...draft })
    }
  })
  return truncatedState;
}

export function QueryBuilder(props: QueryBuilderProps) {
  const { value: initialValue, values: optionData, id, options } = props;
  const { filterBy } = optionData;
  const depth = props.depth ?? 0;
  const nestedClausePointer = props.nestedClausePointer ?? [];
  const root = depth === 0 && nestedClausePointer.length === 0;

  const [queryState, setQueryState] = useQueryBuilderState(id, initialValue, root);

  useEffect(() => {
    if (root) {
      setQueryState(initialValue);
    }
  }, [initialValue, root, setQueryState]);
  // console.log({ initialValue, queryState, id, depth, root, nestedClausePointer })

  function addClause() {
    setQueryState(
      produce(queryState, (d) => {
        const draftState = extractNestedState(d, nestedClausePointer, depth);
        let newClause = draftState.clauses[draftState.clauses.length - 1];
        if (!newClause || (newClause as QueryBuilderInitData).globalOperator) {
          newClause = initialValue.clauses[0];
        }
        draftState.clauses.push(newClause);
      }),
    );
  }

  function addNestedClauseGroup() {
    setQueryState(
      produce(queryState, (d) => {
        const draftState = extractNestedState(d, nestedClausePointer, depth);
        let newClause = draftState.clauses[draftState.clauses.length - 1];
        if (!newClause || (newClause as QueryBuilderInitData).globalOperator) {
          newClause = initialValue.clauses[0];
        }
        const newNestedClauseGroup = { ...initialValue };
        newNestedClauseGroup.clauses = [newClause];
        draftState.clauses.push(newNestedClauseGroup);
      }),
    );
  }

  function duplicateClause(clauseIndex: number) {
    setQueryState(
      produce(queryState, (d) => {
        const draftState = extractNestedState(d, nestedClausePointer, depth);
        draftState.clauses = [...draftState.clauses];
        draftState.clauses.splice(clauseIndex, 0, draftState.clauses[clauseIndex]);
      }),
    );
  }

  function convertClause(clauseIndex: number, conversionFlag: "group" | "filter") {
    setQueryState(
      produce(queryState, (d) => {
        const draftState = extractNestedState(d, nestedClausePointer, depth);
        let convertedClause = draftState.clauses[clauseIndex];
        if (conversionFlag === "filter") {
          console.log("filter: ", JSON.parse(JSON.stringify(draftState)));
          convertedClause = (convertedClause as QueryBuilderInitData).clauses[0];
          draftState.clauses.splice(clauseIndex, 1, convertedClause);
        } else {
          const convertedClauseGroup = { ...initialValue };
          convertedClauseGroup.clauses = [convertedClause];
          draftState.clauses.splice(clauseIndex, 1, convertedClauseGroup);
        }
      }),
    );
  }

  function deleteClause(clauseIndex: number) {
    setQueryState(
      produce(queryState, (d) => {
        const draftState = extractNestedState(d, nestedClausePointer, depth);
        draftState.clauses = [...draftState.clauses];
        draftState.clauses.splice(clauseIndex, 1);
      }),
    );
  }

  function resetQueryState(clear: boolean) {
    setQueryState(
      produce(initialValue, (draftState) => {
        if (clear) {
          let base = draftState.clauses[0];
          if ((base as QueryBuilderInitData).clauses && (base as QueryBuilderInitData).clauses.length > 0) {
            base = (base as QueryBuilderInitData).clauses[0];
            if ((base as QueryBuilderInitData).clauses && (base as QueryBuilderInitData).clauses.length > 0) {
              base = (base as QueryBuilderInitData).clauses[0];
            }
          }
          draftState.clauses = [base];
        }
        return draftState;
      }),
    );
    // setQueryState(baseQueryState)
  }

  // TODO: refactor to be different functions
  function handleInput(
    e: any,
    clauseIndex: number,
    lookupKey: keyof QueryBuilderDatum,
    nestedLookup?: keyof FilterData,
  ) {
    const {
      target: { value },
    } = e;
    setQueryState(
      produce(queryState as QueryBuilderSimpleData, (d) => {
        const draftState = extractNestedState(d, nestedClausePointer, depth) as QueryBuilderSimpleData;
        if (value !== draftState.clauses[clauseIndex][lookupKey]) {
          if (lookupKey === "filterBy" && nestedLookup !== undefined) {
            draftState.clauses[clauseIndex][lookupKey][nestedLookup] = value;
            if (typeof draftState.clauses[clauseIndex]["booleanMatchValue"] !== "string") {
              if (!draftState.clauses[clauseIndex].booleanOperator.includes("date range")) {
                (draftState.clauses[clauseIndex]["booleanMatchValue"] as ACBooleanMatchData).value = ["", ""];
              }
            }
            // check nb api for autocomplete options and rest accordingly when filterby changes
            if (nestedLookup === "type") {
              draftState.clauses[clauseIndex][lookupKey].value = filterBy.filter((o) => o.type === value)[0].values[0];
            }
          } else if (
            lookupKey === "booleanMatchValue" &&
            typeof draftState.clauses[clauseIndex][lookupKey] !== "string"
          ) {
            (draftState.clauses[clauseIndex][lookupKey] as any).value = value;
          } else { // handle boolOperator change
            if (value.includes("date range") && !(draftState.clauses[clauseIndex][lookupKey] as string).includes("date range")) { 
              draftState.clauses[clauseIndex].booleanMatchValue = {value: [new Date() as any, new Date() as any], notebook: "", cell: ""}
            } else if ((draftState.clauses[clauseIndex][lookupKey] as string).includes("date range") && !value.includes("date range")) {
              draftState.clauses[clauseIndex].booleanMatchValue = {
                value: ["", ""], 
                notebook: "Staging:ElasticSearch_Exclude", 
                cell: "FieldLookup"
              }
            }
            draftState.clauses[clauseIndex][lookupKey] = value;
          }
        } else {
          // fix
          (draftState.clauses[clauseIndex][lookupKey] as any) = "";
        }
      }),
    );
  }

  function setCreatedFilterByClauseOption(clauseIndex: number, newItemTuple: [string, string]) {
    setQueryState(
      produce(queryState as QueryBuilderSimpleData, (d) => {
        const draftState = extractNestedState(d, nestedClausePointer, depth) as QueryBuilderSimpleData;
        draftState.clauses[clauseIndex].filterBy.value = newItemTuple;
      }),
    );
  }

  function setCreatedBooleanMatchValueClauseOption(clauseIndex: number, newItemTuple: [string, string]) {
    setQueryState(
      produce(queryState as QueryBuilderSimpleData, (d) => {
        const draftState = extractNestedState(d, nestedClausePointer, depth) as QueryBuilderSimpleData;
        const rc = draftState.clauses[clauseIndex].booleanMatchValue;
        if (typeof rc !== "string") {
          rc.value = newItemTuple;
        }
        // TODO: refine this, shouldnt ever bee a case where value is string type
      }),
    );
  }

  function handleChangeGlobalOperator({ target: { value } }: React.ChangeEvent<{ value: GlobalOperator }>) {
    setQueryState(
      produce(queryState, (d) => {
        const draftState = extractNestedState(d, nestedClausePointer, depth) as QueryBuilderSimpleData;
        draftState.globalOperator = value;
      }),
    );
  }

  const nestedState = extractNestedState(queryState, nestedClausePointer, depth);

  // maxWidth: `calc(100% - (114px * ${depth} ))`
  return (
    <ConditionalWrapper
      condition={depth !== 0}
      wrapper={(children: any) => (
        <Card className="eic-content-card" style={{ padding: "8px" }}>
          {children}
        </Card>
      )}
    >
      <div style={{ width: "max-content" }}>
        {nestedState?.clauses?.map((c, idx) => (
          <Clause
            value={c as QueryBuilderDatum}
            globalOperator={nestedState.globalOperator}
            optionData={optionData}
            key={"clause" + idx + "depth" + depth}
            idx={idx}
            depth={depth}
            querybuilderID={id}
            nestedClausePointer={produce(nestedClausePointer, (draftPointer) => {
              if (typeof draftPointer[depth] === "undefined") {
                console.log("draftPointer", JSON.stringify(draftPointer), typeof draftPointer[depth]);
                draftPointer.push(idx);
              } else {
                draftPointer[depth] = idx;
              }
              return draftPointer;
            })}
            isDeleteDisabled={nestedState.clauses.length <= 1}
            deleteClause={deleteClause}
            duplicateClause={duplicateClause}
            convertClause={convertClause}
            handleInput={handleInput}
            setCreatedFilterByClauseOption={setCreatedFilterByClauseOption}
            setCreatedBooleanMatchValueClauseOption={setCreatedBooleanMatchValueClauseOption}
            styleOptions={options}
            handleChangeGlobalOperator={handleChangeGlobalOperator}
          />
        ))}
        {/* plus button which uses the add clause function */}
        <ControlGroup fill={true} vertical={false}>
          <div>
            {depth < 2 ? (
              <>
                <Button
                  alignText={Alignment.LEFT}
                  text="Add a filter"
                  icon="plus"
                  intent={Intent.PRIMARY}
                  minimal={options.minimal}
                  onClick={() => addClause()}
                />
                <Button
                  alignText={Alignment.LEFT}
                  text="Add a filter group"
                  icon={
                    <span className="bp3-icon" style={{ width: "16px" }}>
                      <svg
                        viewBox="0 0 16 16"
                        className="addFilterGroup"
                        style={{
                          width: "100%",
                          height: "100%",
                          display: "block",
                          fill: "currentColor",
                          flexShrink: 0,
                          backfaceVisibility: "hidden",
                        }}
                      >
                        <path d="M2.374 12.42h1.293v1.157c0 1.557.787 2.344 2.375 2.344h7.591c1.573 0 2.367-.787 2.367-2.344V5.924c0-1.557-.794-2.344-2.367-2.344h-1.3V2.423C12.333.866 11.539.08 9.966.08H2.374C.786.08 0 .866 0 2.423v7.653c0 1.557.786 2.344 2.374 2.344zm.015-1.218c-.756 0-1.172-.408-1.172-1.194V2.49c0-.786.416-1.194 1.172-1.194h7.554c.749 0 1.172.408 1.172 1.194v1.09H6.042c-1.588 0-2.375.778-2.375 2.343v5.278H2.39zm3.668 3.501c-.749 0-1.172-.408-1.172-1.194V5.991c0-.786.423-1.194 1.172-1.194h7.554c.748 0 1.172.408 1.172 1.194v7.516c0 .787-.424 1.195-1.172 1.195H6.057zm3.773-1.565c.4 0 .643-.272.643-.71v-2.042h2.17c.423 0 .718-.227.718-.628 0-.408-.272-.643-.718-.643h-2.17V6.938c0-.447-.242-.719-.643-.719-.4 0-.628.288-.628.719v2.177h-2.17c-.438 0-.718.235-.718.643 0 .4.295.628.718.628h2.17v2.041c0 .424.227.711.628.711z" />
                      </svg>
                    </span>
                  }
                  intent={Intent.PRIMARY}
                  minimal={options.minimal}
                  onClick={() => addNestedClauseGroup()}
                />
              </>
            ) : (
              <Button
                alignText={Alignment.LEFT}
                text="Add a filter"
                icon="plus"
                intent={Intent.PRIMARY}
                minimal={options.minimal}
                fill={true}
                onClick={() => addClause()}
              />
            )}
          </div>
          {depth === 0 ? (
            <div style={{ maxWidth: "30px" }}>
              <Popover
                popoverClassName={Classes.POPOVER_CONTENT_SIZING}
                content={
                  <div key="text">
                    <H5>Clear Query Builder</H5>
                    <p>Are you sure you want to clear all filters?</p>
                    <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 15 }}>
                      <Button className={Classes.POPOVER_DISMISS} minimal={options.minimal} style={{ marginRight: 10 }}>
                        Cancel
                      </Button>
                      <Button
                        icon="refresh"
                        intent={Intent.DANGER}
                        minimal={options.minimal}
                        className={Classes.POPOVER_DISMISS}
                        onClick={() => {
                          const clearFlag = true;
                          resetQueryState(clearFlag);
                        }}
                      >
                        Clear All
                      </Button>
                    </div>
                  </div>
                }
                {...(options as IPopoverProps)}
              >
                <Button icon="refresh" intent={Intent.DANGER} minimal={options.minimal} />
              </Popover>
            </div>
          ) : null}
        </ControlGroup>
      </div>
    </ConditionalWrapper>
  );
}

// first implement suggest vs regular input for booleanMatchValue
// implement loader during autocomplete fetch
// clear out value when new filterby condition selected

interface ClauseProps {
  value: QueryBuilderDatum;
  globalOperator: GlobalOperator;
  optionData: QueryBuilderData;
  key: string;
  idx: number;
  depth: number;
  querybuilderID: string;
  nestedClausePointer: number[];
  isDeleteDisabled: boolean;
  deleteClause: (clauseIdx: number) => void;
  duplicateClause: (clauseIdx: number) => void;
  convertClause: (clauseIdx: number, conversionFlag: "group" | "filter") => void;
  handleInput: (
    e: any,
    clauseIndex: number,
    lookupKey: keyof QueryBuilderDatum,
    nestedLookup?: "value" | "values" | "type" | undefined,
  ) => void;
  setCreatedFilterByClauseOption: (clauseIndex: number, newItemTuple: [string, string]) => void;
  setCreatedBooleanMatchValueClauseOption: (clauseIndex: number, newItemTuple: [string, string]) => void;
  handleChangeGlobalOperator: any;
  styleOptions: any;
}

function useQueryBuilderAutoCompleteData(selectedData: QueryBuilderDatum, querybuilderID: string, depth: number, nestedClausePointer: number[], clauseIdx: number) {
  const { client } = useAuthState();
  const queryState = useGetQueryBuilderStateRO(querybuilderID)
  let truncatedQueryState: QueryBuilderInitData | null = null
  if (queryState !== null) {
    truncatedQueryState = truncateQueryState(queryState, nestedClausePointer, depth, clauseIdx)
    // console.log("extracted clause in AC: ", truncatedQueryState, queryState, {depth, nestedClausePointer, clauseIdx})

  }

  const autoCompleteContent = useQuery(["queryBuilderAutoComplete", selectedData.filterBy?.value, nestedClausePointer[depth], truncatedQueryState], async () => {
    if (typeof selectedData.booleanMatchValue !== "string" && typeof selectedData.booleanMatchValue !== undefined) {
      const { notebook, cell } = selectedData.booleanMatchValue as ACBooleanMatchData;
      const { value } = selectedData.filterBy;
      const lookup = value; // for ES autocomplete

      const payload: any = { lookup }
      if (queryState !== null) {

        payload["clauses"] = truncatedQueryState
      }
      if (notebook !== "" && cell !== "") {
        return await client.newExecNb(notebook, cell, payload);
      }
      return null
    }
    return null;
  });
  return autoCompleteContent as any;
}

export function Clause({
  value,
  globalOperator,
  styleOptions,
  optionData,
  idx: clauseIdx,
  isDeleteDisabled,
  handleInput,
  deleteClause,
  duplicateClause,
  convertClause,
  setCreatedFilterByClauseOption,
  setCreatedBooleanMatchValueClauseOption,
  handleChangeGlobalOperator,
  querybuilderID,
  depth,
  nestedClausePointer,
}: ClauseProps) {

  console.log("nested c pointer: ", nestedClausePointer[depth])

  const [createdFilterByItems, setCreatedFilterByItems] = useImmer<FilterData[]>(
    optionData.filterBy.map(({ type }) => ({ type, values: [] })),
  );
  const [createdBoolMatchValues, setCreatedBoolMatchValues] = useImmer<Array<AutoCompleteTuple>>([]);
  const { data, isLoading } = useQueryBuilderAutoCompleteData(value, querybuilderID, depth, nestedClausePointer, clauseIdx);
  const filterByItems = value.filterBy
    ? [
        ...createdFilterByItems.filter((o: any) => o.type === value.filterBy.type)[0].values,
        ...optionData.filterBy.filter((o: any) => o.type === value.filterBy.type)[0].values,
      ]
    : [];
  const boolMatchValueItems = [...createdBoolMatchValues, ...(data && typeof data === "object" ? data.content : [])];
  const RENDER_FILTER_GROUP = ((value as unknown) as QueryBuilderInitData).globalOperator !== undefined && depth < 2;

  function createTag(type: "filterBy" | "booleanMatchValue") {
    const newItemID =
      type === "filterBy"
        ? `Private Tag ${createdFilterByItems.filter((o: any) => o.type === value.filterBy.type)[0].values.length + 1}`
        : `Unstructured Search Term`; // TODO: improve this
    return (queryAsCreatedTagName: string): AutoCompleteTuple => [newItemID, queryAsCreatedTagName];
  }

  function handleValueChange(inputType: "filterBy" | "booleanMatchValue") {
    return (tagTuple: AutoCompleteTuple) => {
      const items = inputType === "filterBy" ? filterByItems : boolMatchValueItems;
      const isNewCreatedItem = !arrayContainsItem(items, tagTuple);

      if (isNewCreatedItem) {
        if (inputType === "filterBy") {
          setCreatedFilterByItems((draftState) => {
            const mutateIdx = value.filterBy.type === "Dicom Tag" ? 0 : 1;
            draftState[mutateIdx].values.push(tagTuple);
          });
          setCreatedFilterByClauseOption(clauseIdx, tagTuple);
        } else {
          setCreatedBoolMatchValues((draftState) => {
            draftState.push(tagTuple);
          });
          setCreatedBooleanMatchValueClauseOption(clauseIdx, tagTuple);
        }
      } else {
        handleInput({ target: { value: tagTuple } }, clauseIdx, inputType, "value");
      }
    };
  }

  return (
    <ControlGroup
      fill={false}
      vertical={false}
      key={"clause-" + clauseIdx}
      style={{ paddingTop: "2px", paddingBottom: "2px" }}
    >
      <div style={{ width: "84px" }}>
        {clauseIdx === 0 ? <span style={{ paddingLeft: ".6rem", lineHeight: "2" }}>Where: </span> : null}
        {clauseIdx === 1 ? (
          <HTMLSelect
            options={optionData.globalOperators}
            value={globalOperator}
            // redesign to take
            onChange={(e: any) => handleChangeGlobalOperator(e)}
            minimal={styleOptions.minimal}
          />
        ) : null}
        {![0, 1].includes(clauseIdx) ? (
          <span style={{ paddingLeft: ".6rem", lineHeight: "2" }}>{globalOperator}: </span>
        ) : null}
      </div>
      {RENDER_FILTER_GROUP ? (
        <QueryBuilder
          value={(value as unknown) as QueryBuilderInitData}
          values={optionData}
          depth={depth + 1}
          id={querybuilderID}
          options={styleOptions}
          nestedClausePointer={nestedClausePointer}
        />
      ) : (
        <>
          {optionData.filterBy.map((o: any) => o.type).length === 1 ? (
            <div style={{ minWidth: "84px" }}>
              <span style={{ paddingLeft: ".6rem", paddingRight: "1.2rem", lineHeight: "2" }}>
                {value.filterBy.type}:{" "}
              </span>
            </div>
          ) : (
            <HTMLSelect
              options={optionData.filterBy.map((o: any) => o.type)}
              value={value.filterBy.type}
              onChange={(e: any) => handleInput(e, clauseIdx, "filterBy", "type")}
              minimal={styleOptions.minimal}
            />
          )}
          <QueryBuilderAutoComplete
            items={filterByItems}
            {...defaultOptions}
            itemListRenderer={renderMenu}
            itemRenderer={renderItem}
            itemPredicate={filterItem}
            inputValueRenderer={renderInputValue}
            itemsEqual={areTagsEqual}
            createNewItemFromQuery={createTag("filterBy")}
            createNewItemRenderer={renderCreateNewItem}
            onItemSelect={handleValueChange("filterBy")}
            selectedItem={value.filterBy.value}
            popoverProps={{ minimal: true, position: "bottom-left" }}
            inputProps={{ className: Classes.TEXT_OVERFLOW_ELLIPSIS }}
            fill
          />
          <HTMLSelect
            options={optionData.booleanOperators}
            value={value.booleanOperator}
            onChange={(e: any) => handleInput(e, clauseIdx, "booleanOperator")}
            minimal={styleOptions.minimal}
          />
          {value.booleanOperator.includes("date range") && typeof value.booleanMatchValue === "object" ? (
              <MatchValueDateRange handleInput={handleInput} range={value.booleanMatchValue.value as any[]} clauseIdx={clauseIdx} />
            ) : typeof value.booleanMatchValue === "string" || data === null || data?.content.length === 0 ? (
              <InputGroup
                placeholder="Enter a filter value"
                value={
                  typeof value.booleanMatchValue === "string" ? value.booleanMatchValue : value.booleanMatchValue.value[1]
                }
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (typeof value.booleanMatchValue === "string") {
                    handleInput(e, clauseIdx, "booleanMatchValue");
                  } else {
                    handleInput(
                      { target: { value: ["Unstructured Search Term", e.target.value] } },
                      clauseIdx,
                      "booleanMatchValue",
                      "value",
                    );
                  }
                }}
                fill
              />
            ) : (
              <QueryBuilderAutoComplete
                items={boolMatchValueItems}
                {...defaultOptions}
                itemListRenderer={renderMenu}
                itemRenderer={renderItem}
                itemPredicate={filterItem}
                inputValueRenderer={renderInputValue}
                itemsEqual={areTagsEqual}
                createNewItemFromQuery={createTag("booleanMatchValue")}
                createNewItemRenderer={renderCreateNewItem}
                onItemSelect={handleValueChange("booleanMatchValue")}
                inputProps={{
                  className: Classes.TEXT_OVERFLOW_ELLIPSIS,
                  leftIcon: isLoading ? (
                    <Spinner intent={Intent.NONE} size={Spinner.SIZE_SMALL} tagName="span" className="eic-ac-loading small"/>
                  ) : (
                    "search"
                  ),
                }}
                selectedItem={isLoading ? ["", "Fetching data"] : value.booleanMatchValue.value}
                popoverProps={{ minimal: true, position: "bottom-left" }}
                fill
                disabled={isLoading}
              />
            )
          }
        </>
      )}
      <Popover
        content={
          <Menu>
            <MenuItem 
              icon="trash" 
              onClick={() => deleteClause(clauseIdx)} 
              text="Remove" 
              shouldDismissPopover={true} 
              intent={Intent.DANGER}
              disabled={isDeleteDisabled}
            />
            <MenuItem
              icon="duplicate"
              onClick={() => duplicateClause(clauseIdx)}
              text="Duplicate"
              shouldDismissPopover={true}
            />
            {depth < 2 &&
              (((value as unknown) as QueryBuilderInitData).globalOperator === undefined ||
                ((value as unknown) as QueryBuilderInitData).clauses?.length === 1) && (
                <MenuItem
                  icon={
                    <Icon
                      icon={
                        <span className="bp3-icon" style={{ width: "16px" }}>
                          <svg
                            viewBox="0 0 30 30"
                            className="loop"
                            style={{
                              width: "100%",
                              height: "100%",
                              display: "block",
                              fill: "currentColor",
                              flexShrink: 0,
                              backfaceVisibility: "hidden",
                            }}
                          >
                            <path d="M26,16c0,3.309-2.691,6-6,6H5.813l3.594-3.594L8,17l-6,6l6,6l1.406-1.406L5.813,24H20c4.4,0,8-3.6,8-8v-1h-2V16z M4,14 c0-3.309,2.691-6,6-6h14.188l-3.594,3.594L22,13l6-6l-6-6l-1.406,1.406L24.188,6H10c-4.4,0-8,3.6-8,8v1h2V14z"></path>
                          </svg>
                        </span>
                      }
                    />
                  }
                  onClick={() => convertClause(clauseIdx, RENDER_FILTER_GROUP ? "filter" : "group")}
                  text={"Turn into " + (RENDER_FILTER_GROUP ? "filter" : "filter group")}
                  shouldDismissPopover={true}
                />
              )}
          </Menu>
        }
        {...(styleOptions as IPopoverProps)}
      >
        <Button
          icon="more"
          minimal={styleOptions.minimal}
          outlined={styleOptions.outlined !== undefined ? styleOptions.outlined : styleOptions.minimal === true}
        />
      </Popover>
    </ControlGroup>
  );
}

interface MatchValueDateRangeProps extends Omit<IDateRangeContent, "range"> {
  handleInput: ClauseProps['handleInput'];
  clauseIdx: number;
  range: any[];
}

function MatchValueDateRange({
  range, 
  options,
  clauseIdx,
  handleInput
}: MatchValueDateRangeProps) {
  
    const { enableTimePicker, format, ...restOptions } = defaultDateRangeOptions;
  
    // const timePrecision = enableTimePicker ? TimePrecision.MINUTE : undefined;
    const timePrecision = undefined
  
    const handleRangeChange = useCallback((updatedRange: DateRange) => {
      console.log("updated range is: ", updatedRange)
      const e = {target: {value: updatedRange as any}}
      handleInput(e, clauseIdx, "booleanMatchValue", "value");
    }, [clauseIdx, handleInput]);
    
  useEffect(() => {
    if (typeof range[0] === "string" && typeof range[1] === "string") {
      const startDate = new Date(range[0])
      const endDate = new Date(range[1])
      handleRangeChange([startDate, endDate])
    }
  }, [handleRangeChange, range])

  function handleClear(event: any) {
    event.preventDefault();
    if (range) {
      const e = {target: {value: [null, null] as any}}
      handleInput(e, clauseIdx, "booleanMatchValue", "value")
    }
  }

  console.log("range is: ", range)
  return (
    <DateRangeInput
      {...restOptions}
      {...format}
      {...options}
      value={range as any}
      onChange={handleRangeChange}
      parseDate={(str => {
        return new Date(str)
      })}
      timePrecision={timePrecision}
      startInputProps={{
        fill: true,
        leftIcon: "calendar",
      }}
      popoverProps={{minimal: true}}
      endInputProps={{
        rightElement:
          range[0] !== null && range[1] !== null ? (
            <Button icon="small-cross" onClick={handleClear} small minimal />
          ) : undefined,
        fill: true,
      }}
    />
  )
}