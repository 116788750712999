import React from "react";
import { Card, Elevation, H6 } from "@blueprintjs/core";

export interface ICardProps {
  id: string;
  content: ICardContent;
}

export interface ICardContent {
  title?: string;
  widgets: Object[];
  footer?: Object[];
  interactive?: boolean;
  style?: any;
}

const renderTitle = (title: string | undefined) =>
  title ? (
    <div>
      <H6 className={"eic-content-card title "}>{title}</H6>
    </div>
  ) : (
    <span />
  );
const renderFooter = (footer: Object[] | undefined) => (footer ? <div>{footer}</div> : null);

export function CardWidget({ id, content: { title, widgets, footer, interactive, style } }: ICardProps) {
  return (
    <Card
      interactive={interactive}
      elevation={Elevation.TWO}
      style={style ? style : { height: "100%", overflow: "scroll" }}
      className="eic-content-card"
      key={id}
    >
      {renderTitle(title)}
      {widgets}
      {renderFooter(footer)}
    </Card>
  );
}
