import React, { forwardRef, useRef } from "react";
import { FixedSizeList, ListOnItemsRenderedProps } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { Card, Classes, Elevation, Intent, H3, Tag, Icon, Colors, Spinner, Text, Button, H4, Popover, Position, Menu } from "@blueprintjs/core";
// import { ElementStateLookup, usePageNamespaceData } from "../state";
// import produce from "immer";
import { ErrorWidget, GenericWidgetContentType, InfoCardProps, renderWidgets } from ".";
import { useTableContextData } from "./Table";
import { WidgetController } from "./WidgetController";

type Dimensions = {
  height: number;
  width: number;
};

interface ListOptions {
  minimal: boolean;
  outlined: boolean;
  large: boolean;
  small: boolean;
}

interface WrapperOptions {
  elevation: Elevation;
  interactive: boolean;
  visibleItemCount: number;
  scrollSnapEnabled: boolean;
}

interface ListHeader {
  leftElement?: GenericWidgetContentType;
  // leftIcon
  content: GenericWidgetContentType;
  actionMenu?: GenericWidgetContentType;
}

export interface CardListContent {
  defaultDimensions: Dimensions;
  wrapperStyle: Partial<React.CSSProperties>;
  wrapperOptions?: Partial<WrapperOptions>;
  options?: Partial<ListOptions>;
  cardStyle: Partial<React.CSSProperties>;
  cardHeight?: number;
  listHeader?: ListHeader;
  listItems: InfoCardProps[];
  sortable?: boolean;
  itemKeySortAccessor?: string | string[];
  itemTitleAccessor?: string | string[];
}
// condensed
// dark
// light
// intent
// fill

export interface CardListProps {
  id: string;
  content: Partial<CardListContent>;
}

export interface CardListState {
  // activeItem: {key: string, index: number}
}

function renderSortButton(options: Partial<ListOptions>) {
  return (
    <Button
      icon="sort"
      minimal={options.minimal}
      outlined={options.outlined}
      large={options.large}
      small={options.small}
    />
  );
}

const renderListHeader = (
  title: ListHeader | string | undefined,
  options: Partial<ListOptions>,
  sortable?: boolean,
) => {
  let rc = <span />;
  let leftElement = null;
  let rightElement = null;
  if (typeof title !== "undefined") {
    if (typeof title === "string") {
      rc = (
        <header className={Classes.DIALOG_HEADER}>
          <H3 className={"eic-content-card title " + Classes.TEXT_OVERFLOW_ELLIPSIS}>{title}</H3>
        </header>
      );
    } else {
      // implement icon
      if (typeof title.leftElement !== "undefined") {
        leftElement =
          typeof title.leftElement === "string" ? (
            <Tag>{title.leftElement}</Tag>
          ) : (
            renderWidgets(!Array.isArray(title.leftElement) ? [title.leftElement] : title.leftElement)
          );
      }
      if (typeof title.actionMenu !== "undefined") {
        if (typeof title.actionMenu === "string") {
          rightElement = <Tag>{title.actionMenu}</Tag>
        } else {
          const actionMenu = (
            <Menu>
              {renderWidgets(!Array.isArray(title.actionMenu) ? [title.actionMenu] : title.actionMenu)}
            </Menu>
          ) 
          rightElement = (
          <Popover content={actionMenu} position={Position.BOTTOM_RIGHT}>
            <Button 
              icon="more" 
              minimal={options.minimal}
              outlined={options.outlined}
              large={options.large}
              small={options.small}
            />
          </Popover>)
        }
      }
      if (sortable) {
        rightElement = <span style={{ right: "20px", paddingLeft: "18px" }}>{renderSortButton(options)}</span>;
      }

      if (typeof title.content === "string") {
        rc = <H3 className={"eic-content-card title " + Classes.TEXT_OVERFLOW_ELLIPSIS}>{title.content}</H3>;
      } else {
        rc = renderWidgets(!Array.isArray(title.content) ? [title.content] : title.content);
      }
      if (leftElement !== null) {
        rc = <span style={{ paddingLeft: "16px", fontWeight: 600 }}>{rc}</span>;
      }
      rc = <header className={Classes.DIALOG_HEADER} children={[leftElement, rc, rightElement]} />;
    }
  }
  return rc;
};

// const CardListRow = (propsCardData: InfoCardProps[], propsCardStyle?: Partial<React.CSSProperties>) => ({ index, style }: any) => {
//   const cardDatum = { ...propsCardData[index] }
//   cardDatum.content.style = produce(cardDatum.content.style, draft => {
//     if (typeof draft === "undefined") {
//       draft = {}
//     }
//     draft = { ...style, ...propsCardStyle, ...draft }
//   })
//   return (
//     <InfoCardWidget
//       {...cardDatum}
//     />
//   );
// }

// function TestCardRowRenderer({ index, style }: any) {

//   return (
//     <Card
//       style={{
//         ...style,
//         top: style.top + DEFAULT_GUTTER_SIZE,
//         height: style.height - DEFAULT_GUTTER_SIZE,
//         left: DEFAULT_GUTTER_SIZE,
//         width: `calc(${style.width} - ${DEFAULT_GUTTER_SIZE * 2}px)`,
//         // width: style.width - (DEFAULT_GUTTER_SIZE),
//         backgroundColor: Colors.DARK_GRAY5,
//         color: Colors.GREEN2
//       }}
//     >
//       {index}
//     </Card>
//   )
// }

const DEFAULT_GUTTER_SIZE = 8;
const DEFAULT_CARD_SIZE = 100;
const SCROLL_DEBOUNCE_DELAY = 500;

const RowWrapper = forwardRef(({ style, ...rest }: any, ref) => (
  <div
    ref={ref}
    style={{
      ...style,
      paddingTop: DEFAULT_GUTTER_SIZE,
    }}
    {...rest}
  />
));

export function CardListWidget({
  id,
  content: {
    defaultDimensions,
    options,
    wrapperStyle,
    wrapperOptions,
    cardStyle,
    cardHeight,
    listHeader,
    listItems,
    sortable,
    itemKeySortAccessor,
  },
}: any) {
  const listRef = React.createRef<FixedSizeList>();
  const _debounceTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  // for dev purposes:
  // const [data] = useTableContextData("Results", "")
  // const listItems: any = Object.entries(data)[0]?.[1]
  console.log("list items: ", listItems);
  // const [listState, setListState, destroyState] = usePageNamespaceData<ListComponentState>(id, ElementStateLookup.LIST_STATE,)
  // useEffect(() => () => destroyState, [destroyState])

  const onRowsRendered = ({ visibleStartIndex }: ListOnItemsRenderedProps) => {
    // implement animated easing transition https://github.com/bvaughn/react-window/issues/38 https://codesandbox.io/s/545y634jxx?file=/Example.js:1257-1533 https://codesandbox.io/s/545y634jxx?file=/Example.js:297-332
    if (_debounceTimeoutRef.current) {
      clearTimeout(_debounceTimeoutRef.current);
    }

    _debounceTimeoutRef.current = setTimeout(
      () => listRef.current?.scrollToItem(visibleStartIndex),
      SCROLL_DEBOUNCE_DELAY,
    );
  };

  function itemKeyGenerator(index: number, data: any[]) {
    if (typeof itemKeySortAccessor !== "undefined") {
      const itemData = data[index];
      const itemKey =
        typeof itemKeySortAccessor === "string"
          ? itemData[itemKeySortAccessor]
          : itemKeySortAccessor.reduce((collector: any, currentAccessor: any) => collector[currentAccessor], itemData);
      return itemKey;
    }
    return data[index]?.id ?? index;
  }

  function GenericTableRowDataCardRenderer({ index, style, data }: any) {
    const itemDataEntries = Object.entries(data[index]);
    const queryDataItems: any = [];
    const imsDataItems = itemDataEntries.filter((entry) => {
      const include = entry[0].includes("ims_");
      if (!include && entry[0] !== "origRowIdx") {
        queryDataItems.push(entry);
      }
      return include;
    });

    return (
      <Card
        style={{
          ...style,
          top: style.top + DEFAULT_GUTTER_SIZE,
          height: style.height - DEFAULT_GUTTER_SIZE,
          left: DEFAULT_GUTTER_SIZE,
          width: `calc(${style.width} - ${DEFAULT_GUTTER_SIZE * 2}px)`,
          // width: style.width - (DEFAULT_GUTTER_SIZE),
          backgroundColor: Colors.DARK_GRAY5,
          // color: Colors.GREEN2
        }}
      >
        <p
          style={{
            fontSize: ".6em",
            lineHeight: ".6em",
            marginTop: "-8px",
            color: Colors.LIGHT_GRAY1,
            opacity: 0.65,
            marginBottom: "2px",
          }}
        >
          {imsDataItems.map(([category, value]: [string, any], idx) => (
            <span>
              {category}
              {idx + 1 !== imsDataItems.length && <span style={{ color: Colors.BLUE5 }}>{" / "}</span>}
            </span>
          ))}
        </p>
        <H4>
          {imsDataItems.map(([category, value]: [string, any], idx) => (
            <span>
              {value}
              {idx + 1 !== imsDataItems.length && <span style={{ color: Colors.COBALT5 }}>{" / "}</span>}
            </span>
          ))}
        </H4>
        <div style={{ display: "inline-flex", width: "100%" }}>
          {queryDataItems.map(([category, value]: [string, any], idx: number) => (
            <span style={{ paddingRight: idx + 1 !== imsDataItems.length ? "2px" : undefined, width: "max-content" }}>
              <Tag minimal={options?.minimal} large={options?.large} style={{ marginRight: "4px" }}>
                {category}:
              </Tag>
              {value}
            </span>
          ))}
        </div>
      </Card>
    );
  }

  function GenericTableRowDataCardRenderer2({ index, style, data }: any) {
    const item = data[index];
    console.log({ item }, "card renderer");
    return (
      <Card
        style={{
          ...style,
          top: style.top + DEFAULT_GUTTER_SIZE,
          height: style.height - DEFAULT_GUTTER_SIZE,
          left: DEFAULT_GUTTER_SIZE,
          width: `calc(${style.width} - ${DEFAULT_GUTTER_SIZE * 2}px)`,
          // width: style.width - (DEFAULT_GUTTER_SIZE),
          backgroundColor: Colors.DARK_GRAY5,
          // color: Colors.GREEN2
        }}
      >
        {WidgetController({ cell: item })}
      </Card>
    );
  }

  const wrapperHeight = wrapperOptions?.visibleItemCount
    ? (cardHeight ?? DEFAULT_CARD_SIZE) * wrapperOptions?.visibleItemCount + 64 + DEFAULT_GUTTER_SIZE * 2
    : wrapperStyle?.height ?? "100%";
  return (
    <Card
      style={{ ...wrapperStyle, height: wrapperHeight, margin: "8px" }}
      elevation={wrapperOptions?.elevation ?? Elevation.TWO}
      interactive={wrapperOptions?.interactive}
      className={"eic-content-card"}
    >
      {typeof listHeader !== "undefined" && renderListHeader(listHeader, options ?? ({} as ListOptions), sortable)}
      {/* {typeof listItems !== "undefined" ? */}
      {listItems?.length > 0 ? (
        <AutoSizer defaultHeight={defaultDimensions?.height} defaultWidth={defaultDimensions?.width}>
          {({ height, width }: any) => (
            <FixedSizeList
              height={height - 53}
              width={width}
              itemSize={(cardHeight ?? DEFAULT_CARD_SIZE) + DEFAULT_GUTTER_SIZE}
              itemCount={listItems.length}
              itemData={listItems}
              // style={{ backgroundColor: Colors.COBALT1 }}
              innerElementType={RowWrapper}
              ref={listRef}
              itemKey={itemKeyGenerator}
              {...(wrapperOptions?.scrollSnapEnabled && { onItemsRendered: onRowsRendered })}
            >
              {/* {CardListRow(listItems!, cardStyle)} */}
              {/* {TestCardRowRenderer} */}
              {GenericTableRowDataCardRenderer2}
            </FixedSizeList>
          )}
        </AutoSizer>
      ) : (
        <ErrorWidget
          id={id}
          content={{
            icon: <Icon icon="info-sign" iconSize={Spinner.SIZE_LARGE} intent={Intent.WARNING} />,
            title: "No Data",
          }}
        />
      )}
    </Card>
  );
}
