import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { useAuthState } from "../state";

const ProtectedRoute = ({ children, path, ...routeProps }: RouteProps) => {
  const { authenticated } = useAuthState();
  if (!authenticated) {
    return <Redirect to="/login" />;
  }

  return (
    <Route path={path} {...routeProps}>
      {children}
    </Route>
  );
};

export default ProtectedRoute;
