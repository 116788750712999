import React, { useEffect, useState } from "react";
import { useImmer } from "use-immer";
import { ControlGroup, Label, FileInput, Text } from "@blueprintjs/core";
import { InputControlState, Table } from ".";
import { ElementStateLookup, Theme, usePageNamespaceData } from "../state";
import { defaultInputControlState } from "./Html2Widget";
import produce from "immer";

interface IFileInputWidgetProps {
  id: string;
  key: string;
  label?: string;
  disabled?: boolean;
  accept?: HTMLInputElement["accept"];
  text?: string;
  large?: boolean;
  type?: string;
  required?: boolean;
  fileDataDelimiter?: string;
  allowTablePrune?: boolean;
}

const placeholderFile = new File([], "");

export function FileInputWidget({
  id,
  key,
  required,
  disabled,
  label,
  accept,
  text,
  type,
  large,
  allowTablePrune,
  fileDataDelimiter = ",",
}: IFileInputWidgetProps) {
  const [selectedFile, setSelectedFile] = useState(placeholderFile);
  const [csvFileData, setCSVFileData] = useImmer<{ cols: any[]; rows: any[] }>({ cols: [], rows: [] });
  const initialState = {
    ...defaultInputControlState,
    required: !!required,
    data: {
      type: type,
      value: text!,
    },
  } as InputControlState;
  const [inputState, setInputState, destroyState] = usePageNamespaceData<InputControlState>(
    id,
    ElementStateLookup.INPUT,
    initialState,
  );

  useEffect(() => {
    // add flag to conditionally destroy state -> prop/notebook driven
    return () => destroyState();
  }, [destroyState]);
  return (
    <>
      <ControlGroup fill={true} vertical={false} key={key}>
        <Label>
          <span className="eic-label">{label}</span>
          {!disabled && required && "*"}
          <FileInput
            disabled={disabled}
            large={large}
            text={selectedFile?.name || <Text ellipsize={true}>{text}</Text> || "Choose a file..."}
            hasSelection={!!selectedFile.name}
            inputProps={{ accept: accept }}
            onInputChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const file = e.target.files?.[0];
              setSelectedFile(typeof file !== "undefined" ? file : selectedFile);
              setInputState(
                produce(inputState, (draftState) => {
                  if (typeof file !== "undefined") {
                    draftState.data.value = file.name;
                  }
                }),
              );
              if (file?.type === "text/csv") {
                const f = new FileReader();
                f.onloadend = (ev) => {
                  if (typeof f.result === "string") {
                    const delimiter = '"' + fileDataDelimiter + '"';
                    const content = f.result.split("\n");
                    setCSVFileData((draft) => {
                      const theaderData = content[0];
                      const tbodyData = content.slice(1, content.length - 1);
                      draft.cols = theaderData
                        .substring(1, theaderData.length - 2)
                        .split(delimiter)
                        .map((col: string) => {
                          const formattedCol = col
                            .trim()
                            .replace(/^'(.*)'$/, "$1")
                            .replace(/^"(.*)"$/, "$1");
                          const rc = { Header: formattedCol.toUpperCase(), accessor: formattedCol };
                          return rc;
                        });
                      if (allowTablePrune) {
                        draft.cols.push({ Header: "!RemoveRow", accessor: "!RemoveRow" });
                      }
                      draft.cols.push();
                      draft.rows = tbodyData.map((r: string, idx: number) => {
                        const row = r
                          .substring(1, r.length - 2)
                          .split(delimiter)
                          .reduce((acc, cur, idx) => {
                            acc[draft.cols[idx].accessor] = cur
                              .trim()
                              .replace(/^'(.*)'$/, "$1")
                              .replace(/^"(.*)"$/, "$1");
                            return acc;
                          }, {} as any);
                        if (allowTablePrune) {
                          row["!RemoveRow"] = idx;
                        }
                        return row;
                      });
                    });
                  }
                };
                f.readAsText(file);
              }
            }}
          />
        </Label>
      </ControlGroup>
      {csvFileData.cols.length > 0 && (
        <div>
          <Table
            cols={csvFileData.cols}
            rows={csvFileData.rows}
            theme={Theme.DARK}
            id={id + "__csv_data_table"}
            name={"csvDataExport"}
          />
        </div>
      )}
    </>
  );
}

// import React from "react"
// import { useImmer } from "use-immer"
// import { FileInput, Text } from "@blueprintjs/core"
// import { Table } from "."
// import { Theme } from "../state"

// interface IFileInputWidgetProps {
//     id: string;
//     disabled?: boolean;
//     accept?: HTMLInputElement["accept"];
//     text?: string
//     large?: boolean;
//     fileDataDelimiter?: string;
// }

// export function FileInputWidget({ id, disabled, accept, text, large, fileDataDelimiter = "|" }: IFileInputWidgetProps) {

//     const [selectedFile, setSelectedFile] = useImmer<File>({} as File)
//     const [csvFileData, setCSVFileData] = useImmer<{ cols: any[], rows: any[] }>({ cols: [], rows: [] })

//     return (
//         <>
//             <FileInput
//                 disabled={disabled}
//                 large={large}
//                 text={selectedFile?.name || (<Text ellipsize={true}>{text}</Text> || "Choose a file...")}
//                 hasSelection={!!selectedFile.name}
//                 inputProps={{ accept: accept }}
//                 onInputChange={(e: any) => {
//                     setSelectedFile(draft => e.target.files[0])
//                     if (e.target.files[0]?.type === "text/csv") {
//                         const f = new FileReader()
//                         f.onloadend = (e) => {
//                             if (typeof f.result === 'string') {
//                                 const content = f.result.split("\n")
//                                 setCSVFileData(draft => {
//                                     draft.cols = content[0].split(fileDataDelimiter).map((col: string) => ({ Header: col.toUpperCase(), accessor: col }))
//                                     draft.rows = content.slice(1).map((r: string) => r.split(fileDataDelimiter).reduce((acc, cur, idx) => {
//                                         acc[draft.cols[idx].accessor] = cur
//                                         return acc;
//                                     }, {} as any))
//                                 })
//                             }
//                         }
//                         f.readAsText(e.target.files[0])
//                     }
//                 }}
//             />
//             {selectedFile?.name && accept?.indexOf("csv") &&
//                 <div>
//                     <Table
//                         cols={csvFileData.cols}
//                         rows={csvFileData.rows}
//                         theme={Theme.DARK}
//                     />
//                 </div>
//             }
//             {selectedFile?.name &&
//                 <input id={id} value={JSON.stringify(csvFileData)} hidden={true} />
//             }
//         </>
//     )
// }
