import { Intent } from "@blueprintjs/core";
import axios, { AxiosInstance, AxiosResponse } from "axios";
import { immerable } from "immer";
import fileDownload from "js-file-download";
import { AppToaster } from "./App";
import { PublicInterface } from "./state";

export class User {
  user_id: string;
  role_id: number;
  group_id: number;
  fname?: string;
  lname?: string;
  emailId?: string;
  token: string;
  // Landing page for user
  homeView?: string;

  constructor(
    user_id: string = "",
    role_id: number = 0,
    group_id: number = 0,
    token: string = "",
    fname?: string,
    lname?: string,
    emailId?: string,
  ) {
    this.user_id = user_id;
    this.role_id = role_id;
    this.group_id = group_id;
    this.token = token;
    this.fname = fname;
    this.lname = lname;
    this.emailId = emailId;
  }
}

function sanitizeURLSearchParams(params: URLSearchParams, href: Location["href"]) {
  params.delete("serverURL");
  const stringParams = params.toString();
  let sanitizedURL: URL | string = new URL(href);
  sanitizedURL.search = stringParams;
  sanitizedURL = sanitizedURL.toString();
  window.history.pushState({ path: sanitizedURL }, "", sanitizedURL);
}

enum CMSEndpoints {
  DICOM_DICT_TAGNAMES = "/dicom/dictionary/tags",
  DICOM_DICT_ENTRIES = "/dicom/dictionary/entries"
}

export function getServerURLOriginString() {
  const { hostname, href, port, search } = window.location;
  const params = new URLSearchParams(search);
  const paramURLString = params.get("serverURL");
  let serverURL = new URL(href);

  if (paramURLString !== null) {
    try {
      const paramURL = new URL(params.get("serverURL")!);
      sanitizeURLSearchParams(params, href);
      AppToaster.show({ message: `Setting Server URL to: '${paramURLString}'`, intent: Intent.SUCCESS });
      return paramURL.origin;
    } catch (err: any) {
      console.error(
        "malformed url param set serverURL, reverting to default. Param set serverURL: '",
        paramURLString,
        "'.  Error: ",
        err,
      );
      sanitizeURLSearchParams(params, href);
      AppToaster.show({
        message: `Unable to set Server URL, malformed URL string: '${paramURLString}', reverting to default.`,
        intent: Intent.WARNING,
      });
    }
  }
  // if (process.env.REACT_APP_API_HOST !== undefined) {
  //   try {
  //     const envURL = new URL(process.env.REACT_APP_API_HOST);
  //     return envURL.origin;
  //   } catch (err: any) {
  //     console.error(
  //       "malformed url env set serverURL, reverting to default. Env set serverURL: '",
  //       process.env.REACT_APP_API_HOST,
  //       "'.  Error: ",
  //       err,
  //     );
  //   }
  // }
  // if (hostname === "localhost" && port === "3008") {
  //   // serverURL.port = "15001";
  //   serverURL.port = "5001";
  //   return serverURL.origin;
  // }
  // if (hostname === "localhost" && port !== "3008") {
  //   serverURL.port = port
  //   return serverURL.origin
  // }
  return serverURL.origin;
}

function constructServerURL(urlOriginString: string) {
  return urlOriginString + "/api";
}

export class Api {
  [immerable] = true;
  private client: AxiosInstance;
  private serverUrl = window.location.origin;

  constructor(urlOriginString?: string) {
    this.client = axios.create({
      baseURL: constructServerURL(urlOriginString ?? this.serverUrl),
    });
  }

  login = (userId: string, pin: string) => {
    return this.client.post("/auth/login", {
      userId: userId,
      pin: pin,
    });
  };

  setServerUrl = (hostname: string) => {
    this.serverUrl = hostname;
    const baseURL = constructServerURL(this.serverUrl);
    this.client.defaults.baseURL = baseURL;
  };

  setToken = (token: string) => {
    this.client.defaults.headers = { "X-API-KEY": token };
  };

  getHeaders = () => this.client.defaults.headers;

  clearToken = () => {
    this.client.defaults.headers = { "X-API-KEY": "" };
  };

  getSideNavData = async (user_id: string) => {
    const { data } = await this.client.post("/Views/SideNav", {
      user_id,
    });
    return data;
  };

  postCallback = (path: string, payload: object = {}) => this.client.post(path, payload);

  addEICInterceptor = ({ logout, notFound }: any) => {
    this.client.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        console.log("error in http transport interceptor", { error }, error.message, error.response);
        if (error && error.response) {
          if (error.response.status === 401) {
            return logout();
          }
          if (error.response.status === 404) {
            return notFound();
          }
          //  message.warn("Session timed out, please log in again.");
        }
        // if (error && error.message === 'Network Error') {
        //   message.warn(
        //     'Network Error: Please check your network connection and log in again.'
        //   );
        //   cb();
        // }
        return Promise.reject(error);
      },
    );
  };

  setUrl = (url: string) => {
    this.client.defaults.baseURL = url;
    this.serverUrl = url;
  };

  isTestEnv = () => this.serverUrl.includes("localhost");

  ping = () => {
    return this.client.get("/ping");
  };

  addInterceptor(interceptor: (value: AxiosResponse) => AxiosResponse) {
    this.client.interceptors.response.use(
      (rc) => rc,
      (error) => {
        interceptor(error.response);
        return Promise.reject(error);
      },
    );
  }

  notebooks = async () => {
    const { data } = await this.client.get("/notebooks/");
    return data;
  };

  notebook = (name: string) => {
    return this.client.get("/nb/parser/" + name);
  };

  execView = async (script: string, params: Object | null) => {
    const { data } = await this.client.post(`/notebooks/view/${script}/${script}`, params);
    return { type: "html2", content: { text: data } };
  };

  execViews = async (script: string, params: Object | null) => {
    const { data, headers } = await this.client.post(`/Views/${script}`, params);
    if (headers["content-type"].indexOf("text/html") >= 0) {
      return { type: "html2", content: { text: data } };
    }
    return data;
  };

  downloadNbOutput = (book: string, outputCell: string, v: Object, filename: string) => {
    return this.client.post("/notebooks/exec/" + book + "/" + outputCell, v).then((rc) => {
      console.log("file download response: ", rc);
      if (typeof rc.data === "string") {
        const file = new Blob([rc.data], { type: "text/plain" });
        fileDownload(file, filename);
      }
    });
  };

  newExecNb = async (book: string, outputCell: string, v: Object | null) => {
    if (book === "view") {
      return await this.execView(outputCell, v);
    }
    if (book === "Views") {
      return await this.execViews(outputCell, v);
    }
    if (v === null) {
      const { data } = await this.client.post("/notebooks/exec/" + book + "/" + outputCell);
      return data;
    }
    try {
      console.log("in nb exec: ", book, outputCell, ("/notebooks/exec/" + book + "/" + outputCell), v)
      const { data } = await this.client.post("/notebooks/exec/" + book + "/" + outputCell, v);
      return data;
    } catch (err: any) {
      console.log(err)
      return {}
    }
  };

  execNb = (book: string, outputCell: string, v: Object | null) => {
    if (v === null) {
      return this.client.post("/notebooks/exec/" + book + "/" + outputCell);
    }
    return this.client.post("/notebooks/exec/" + book + "/" + outputCell, v);
  };

  runESQuery = async (esIndex: string, queryBody: any) => {
    const { data } = await this.client.post("/elastic/_search", queryBody);
    return data;
  };

  // new content
  getDICOMDict = async () => {
    try {
      const { data } = await this.client.get(CMSEndpoints.DICOM_DICT_ENTRIES);
      return data;
    } catch (err: any) {
      console.log(err)
      return {}
    }
  }
}

export type ImmerableApi = PublicInterface<Api>;
