import React, { useState, memo } from "react";
import ReactFlow, { removeElements, Handle, Position, Background, Edge, Node } from "react-flow-renderer";
import { useHistory } from "react-router";
import { IWidgetLoaderProps, WidgetLoader } from ".";
import { WidgetType } from "../state";

export interface IFlowchartProps {
  id: string;
  content: IFlowchartContent;
  field?: string;
}

export interface IFlowchartContent {
  elements: TFlowchartEl[];
  background?: boolean;
  paneMoveable?: boolean;
  nodesDraggable?: boolean;
  nodesConnectable?: boolean;
  defaultZoom?: number;
}

type TFlowchartEl = {
  id: string;
  type: string | WidgetType;
  data: any;
  position: { x: number; y: number };
  targetPosition?: Position;
  sourcePosition?: Position;
};

const renderCustomElement = memo((props: any) => {
  console.log("custom element props: ", props);
  let handles = {
    leftTop: false,
    leftMiddle: true,
    leftBottom: false,
    rightTop: false,
    rightMiddle: true,
    rightBottom: false,
  };
  let leftHandleType: any = "target";
  let rightHandleType: any = "source";
  const { data } = props;
  const { flowchartProps, sourcePosition, targetPosition } = data;

  if (flowchartProps && flowchartProps.handles) {
    handles = flowchartProps.handles;
  }
  if (sourcePosition && targetPosition) {
    if (sourcePosition === "left") {
      leftHandleType = "source";
    }
    if (targetPosition === "right") {
      rightHandleType = "target";
    }
  } else if (sourcePosition || targetPosition) {
    if (sourcePosition === "left" || targetPosition === "right") {
      leftHandleType = "source";
      rightHandleType = "target";
    }
  }

  return (
    <>
      {handles.leftTop && (
        <Handle
          type={leftHandleType}
          id="a"
          position={Position.Left}
          style={{ top: 10, background: "#fff", ...flowchartProps.handleStyle }}
          onConnect={(params) => console.log("handle onConnect", params)}
        />
      )}
      {handles.leftMiddle && (
        <Handle
          type={leftHandleType}
          id="b"
          position={Position.Left}
          style={{ background: "#fff", ...flowchartProps.handleStyle }}
          onConnect={(params) => console.log("handle onConnect", params)}
        />
      )}
      {handles.leftBottom && (
        <Handle
          type={leftHandleType}
          id="c"
          position={Position.Left}
          style={{ bottom: 10, top: "auto", background: "#fff", ...flowchartProps.handleStyle }}
          onConnect={(params) => console.log("handle onConnect", params)}
        />
      )}
      <div>{WidgetLoader(data as IWidgetLoaderProps)}</div>
      {handles.rightTop && (
        <Handle
          type={rightHandleType}
          position={Position.Right}
          id="a"
          style={{ top: 10, background: "#fff", ...flowchartProps.handleStyle }}
        />
      )}
      {handles.rightMiddle && (
        <Handle
          type={rightHandleType}
          position={Position.Right}
          id="b"
          style={{ background: "#fff", ...flowchartProps.handleStyle }}
        />
      )}
      {handles.rightBottom && (
        <Handle
          type={rightHandleType}
          position={Position.Right}
          id="c"
          style={{ bottom: 10, top: "auto", background: "#fff", ...flowchartProps.handleStyle }}
        />
      )}
    </>
  );
});

const connectionLineStyle = { stroke: "#fff" };
const snapGrid = [16, 16];

const nodeTypes = {
  customNode: renderCustomElement,
};

const graphStyles = { width: "100%", height: "100%" };

const onElementClick = (element: Node<any> | Edge<any>, history: any) => {
  if (element.data.callback) {
    const {callback} = element.data
    const queryParams = new URLSearchParams(callback.params).toString()
    history.push("/Page/" + callback.notebook + "/" + callback.cell + "?" + queryParams);
  }
}

export const Flowchart = ({ content: {background, elements: propsEls, defaultZoom, paneMoveable, nodesConnectable, nodesDraggable} }: IFlowchartProps) => {
  const history = useHistory()
  const [elements, setElements] = useState(propsEls as any);
  const onElementsRemove = (elementsToRemove: any) => setElements((els: any) => removeElements(elementsToRemove, els));
  return (
      <ReactFlow
        elements={elements}
        style={graphStyles}
        onElementsRemove={onElementsRemove}
        onElementClick={(event, element) => onElementClick(element, history)}
        nodeTypes={nodeTypes}
        connectionLineStyle={connectionLineStyle}
        snapToGrid={true}
        snapGrid={snapGrid as [number, number]}
        defaultZoom={defaultZoom}
        paneMoveable={paneMoveable}
        nodesConnectable={nodesConnectable}
        nodesDraggable={nodesDraggable}
      >
        {background && <Background color="#aaa" gap={16}/>}
      </ReactFlow>
  );
};
