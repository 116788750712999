import React from "react";
import { Alignment, Button, IButtonProps, Navbar, Card } from "@blueprintjs/core";
import "../../App.css";
import "./dashboard.css";
import { WidgetController, WidgetLoader } from "../../widgets";
import { IDashboardCell } from "../../state";
import { WsWidget } from "../../widgets/ws/WsWidget";

export type TDashbardProps = {
  id: string;
  grid: TGrid;
  navbar?: TNavbar;
  websocket?: TWebSocket;
};

export type TWebSocket = {
  url: string;
  topic: string;
};

export type TNavbar = {
  title: string;
  buttons?: IButtonProps[];
};

export type TGrid = {
  columns: 1 | 2 | 3 | 4;
  contents: TGridContent[];
};

export type TNotebookContent = {
  notebook: string;
  cell: string;
  params: any;
};
export type TGridContent = {
  type: "notebook" | "embedded" | "view" | "url";
  path?: string;
  content?: TNotebookContent | IDashboardCell;
};

function renderNavbarButtons(props: IButtonProps[] | undefined) {
  if (!props) {
    return "";
  }
  return (
    <Navbar.Group align={Alignment.RIGHT}>
      {props.map((item, idx) => {
        return <Button {...item} />;
      })}
    </Navbar.Group>
  );
}

function renderNavbar(props: TNavbar | undefined) {
  if (!props) {
    return "";
  }
  return (
    <Navbar>
      <Navbar.Group align={Alignment.LEFT}>
        <Navbar.Heading>{props.title}</Navbar.Heading>
      </Navbar.Group>
      {renderNavbarButtons(props.buttons)}
    </Navbar>
  );
}

function renderGridItem(props: TGridContent | undefined) {
  if (!props) {
    return "";
  }
  switch (props.type) {
    case "notebook":
      if (props.content) {
        const params = props.content as TNotebookContent;
        return (
          <Card elevation={1}>
            <WidgetLoader {...params} />
          </Card>
        );
      }
      break;
    case "embedded":
      if (props.content) {
        return (
          <Card elevation={1}>
            <WidgetController cell={props.content as IDashboardCell} />
          </Card>
        );
      }
      break;
  }
  return "";
}

function renderGrid(props: TGrid | undefined) {
  if (!props) {
    return "";
  }
  return (
    <div className={`bp3-dark grid c${props.columns}`}>
      <Card elevation={1} id="graph">
        <WsWidget id="test" url="ws://localhost:4545/vivo/token" />
      </Card>
      {props.contents.map((item, idx) => {
        return <div key={idx}>{renderGridItem(item)}</div>;
      })}
    </div>
  );
}

export function Dashboard(props: TDashbardProps) {
  return (
    <div id={props.id} className="bp3-dark">
      {renderNavbar(props.navbar)}
      {renderGrid(props.grid)}
    </div>
  );
}
