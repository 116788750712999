import React from "react";

import { MainContextProvider } from "../state";
import { PageView } from "./view-components/Page";

export interface ITaggedAssetData {
  tag: string;
}

// fix 404
// consolidate and rename data interface types

export function Main() {
  const view = (
    <MainContextProvider>
      <PageView />
    </MainContextProvider>
  );
  return view;
}
