const isLocalStorageAvailable = window.localStorage ? true : false;

export function setItem(key: string, value: object) {
  if (isLocalStorageAvailable) {
    window.localStorage.setItem(key, JSON.stringify(value));
    return true;
  }
  return false;
}

export function getItem(key: string) {
  if (isLocalStorageAvailable) {
    const rc = window.localStorage.getItem(key);
    if (rc !== undefined && rc !== null) {
      return JSON.parse(rc);
    }
    return undefined;
  }
}

export function clear() {
  if (isLocalStorageAvailable) {
    window.localStorage.clear();
  }
}
