import produce from "immer";
import React, { useCallback, useContext, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useImmerReducer } from "use-immer";
import { checkObjNotEmpty } from "../Utils";
import { InputControlState, InputErrorState } from "../widgets";

export type IPageStore = {
  status: "created" | "loading" | "success" | "error";
  loadtime: number;
  notebook: string;
  cell: string;
  params?: any;
  widgets: Object[];
  data: any;
  rendered: boolean;
};

export type TPageAction = {
  type: string;
  payload: any;
};

export enum ElementStateLookup {
  INPUT = "inputState:",
  DATE_RANGE = "dateRangeState:",
  TOKEN_INPUT = "tokenInputState:",
  NUMERIC_INPUT = "numericInputState:",
  SELECT = "selectState:",
  CHECKBOX = "checkbox:",
  TABLE = "tableState:",
  QUERY_BUILDER = "queryBuilderState:",
  RULE_BUILDER = "ruleBuilderState:",
  VALIDATION_BUILDER = "validationBuilderState:",
  DEID_ACTION_BUILDER = "deidActionBuilderState:",
  DRAWER_STATE = "drawerState:",
  LIST_STATE = "listState:",
  DATA_PROVIDER = "dataProvider:",
  MODAL = "modalState:"
}

type TDispatch = (action: TPageAction) => void;
type PageProviderProps = { children: React.ReactNode };

const reducer = (draftState: IPageStore, action: TPageAction) => {
  switch (action.type) {
    case "loading":
      draftState.notebook = action.payload.notebook;
      draftState.cell = action.payload.cell;
      draftState.status = "loading";
      draftState.params = action.payload.params;
      draftState.data = {};
      draftState.loadtime = 0;
      return;
    case "params":
      draftState.params = action.payload.params;
      return;
    case "success":
      draftState.status = "success";
      draftState.loadtime = Date.now();
      return;
    case "widgets":
      draftState.widgets = action.payload.widgets;
      return;
    case "data":
      if (draftState.data === undefined) {
        draftState.data = {};
      }
      draftState.data[action.payload.field] = action.payload.value;
      return;
    case "destroy_namespace":
      if (draftState.data !== undefined) {
        delete draftState.data[action.payload.field];
      }
      return;
    case "clear":
      draftState = initalState;
      return;
    case "rendered":
      draftState.rendered = true;
      return;
  }
};

const initalState: IPageStore = {
  status: "created",
  loadtime: 0,
  notebook: "",
  cell: "",
  params: {},
  widgets: [],
  data: {},
  rendered: false,
};

const PageContext = React.createContext<IPageStore | undefined>(undefined);
const PageDispatchContext = React.createContext<TDispatch | undefined>(undefined);

export const PageProvider = ({ children }: PageProviderProps) => {
  const [state, dispatch] = useImmerReducer(reducer, initalState);
  return (
    <PageContext.Provider value={state}>
      <PageDispatchContext.Provider value={dispatch}>{children}</PageDispatchContext.Provider>
    </PageContext.Provider>
  );
};

export function usePageDispatch() {
  const context = useContext(PageDispatchContext);
  if (context === undefined) {
    throw new Error("usePageDispatch must be used within a PageDispatchContextProvider");
  }
  return context;
}

export function usePage() {
  const context = useContext(PageContext);
  if (context === undefined) {
    throw new Error("usePage must be used within a PageContextProvider");
  }
  return context;
}

function getPageNamespaceData<D>(
  namespaceID: string,
  namespaceType: ElementStateLookup,
  data: any,
  dispatch: TDispatch,
): [D, any, any] {
  const namespace = namespaceType + namespaceID;
  const namespaceState = data[namespace];
  // console.log({ namespaceID, namespaceState, namespaceType })

  const setNamespaceData = (value: any) => {
    const UPDATE_PAGE_DATA = { type: "data", payload: { field: namespace, value } };
    dispatch(UPDATE_PAGE_DATA);
  };

  const cleanupNamespace = () => {
    const CLEAR_QB_DATA = { type: "destroy_namespace", payload: { field: namespace } };
    dispatch(CLEAR_QB_DATA);
  };

  if (typeof namespaceState === "undefined") {
    throw new Error(`Page Context Namespace: ${namespace} does not exist`);
  }

  return [namespaceState, setNamespaceData, cleanupNamespace];
}

export function usePageNamespaceDataCB() {
  const { data } = usePage();
  const dispatch = usePageDispatch();

  return <D,>(namespaceID: string, namespaceType: ElementStateLookup) =>
    getPageNamespaceData<D>(namespaceID, namespaceType, data, dispatch);
}

export interface NamespaceProps {
  notebook: string;
  cell: string;
  elementStateType: ElementStateLookup;
  name?: string;
  data?: any;
}

export function Namespace({ notebook, cell, name, elementStateType, data }: NamespaceProps) {
  console.log("data in namespace widget is: ", data);
  let formattedData = useMemo(() => {
    const rc = { data: undefined };
    try {
      rc["data"] = JSON.parse(data);
    } catch (err: any) {
      rc["data"] = data;
    }
    return rc;
  }, [data]);

  const [namespaceData] = usePageNamespaceData<any>(name ?? cell, elementStateType, formattedData);
  // useEffect(() => () => setNamespaceData(formattedData), [setNamespaceData, formattedData])
  console.log("data provider name: ", name ?? cell, "initial data: ", namespaceData, typeof namespaceData);
  return null;
}

export function usePageNamespaceData<D>(
  namespaceID: string,
  namespaceType: ElementStateLookup,
  initialState: D,
): [D, any, any] {
  const { data } = usePage();
  const dispatch = usePageDispatch();
  const namespace = namespaceType + namespaceID;
  const namespaceState = data[namespace];
  // console.log({ namespaceID, namespaceState, namespaceType })

  const setNamespaceData = useCallback(
    (value: any) => {
      const UPDATE_PAGE_DATA = { type: "data", payload: { field: namespace, value } };
      dispatch(UPDATE_PAGE_DATA);
    },
    [dispatch, namespace],
  );

  const cleanupNamespace = useCallback(() => {
    const CLEAR_QB_DATA = { type: "destroy_namespace", payload: { field: namespace } };
    dispatch(CLEAR_QB_DATA);
  }, [dispatch, namespace]);

  if (typeof namespaceState === "undefined") {
    // console.log("in initialize namespace", { initialState })
    setNamespaceData(initialState);
    return [initialState, setNamespaceData, cleanupNamespace];
  }

  return [namespaceState, setNamespaceData, cleanupNamespace];
}

export function useGetPageNamespaceDataRO(namespaceID: string, namespaceType: ElementStateLookup) {
  const { data } = usePage();
  const namespace = namespaceType + namespaceID;
  const namespaceState = data[namespace];
  return namespaceState ?? null
}

function getNamespaceData(namespaceType: ElementStateLookup, data: any) {
  return Object.keys(data).reduce<Array<{ id: string; value: any; raw?: any }>>((acc, key) => {
    const prefix = namespaceType;
    if (key.startsWith(prefix)) {
      let value = data[key];
      if (namespaceType === ElementStateLookup.INPUT) {
        value = (value as InputControlState).data.value;
      }
      acc.push({ id: key.substr(prefix.length), value: value, raw: data[key] });
    }
    return acc;
  }, []);
}

export function useNamespaceByType(namespaceType: ElementStateLookup) {
  const { data } = usePage();
  const namespace = getNamespaceData(namespaceType, data);
  return namespace;
}

// add cleanup and return tuple
// properly type
export function useGetNamespacesByType() {
  const { data } = usePage();
  return (namespaces?: Array<ElementStateLookup> | string | null) => {
    if (namespaces === null) {
      return [];
    }
    if (namespaces === undefined) {
      namespaces = Object.values(ElementStateLookup) as Array<ElementStateLookup>;
    }
    if (typeof namespaces === "string") {
      try {
        namespaces = JSON.parse(namespaces) as Array<ElementStateLookup>;
      } catch (err) {
        // implement error boundary
        console.log("could not parse namespace data from props/nb/server", err);
        return [];
      }
    }
    const namespaceData = namespaces.map((n) => getNamespaceData(n, data));
    return namespaceData;
  };
}

// generalize, extract each error setter cb as separate non-hook fn
// return the appropriate error toggle fn via cb that accepts state type as in
function useToggleInputErrorState() {
  const { data } = usePage();
  const dispatch = usePageDispatch();

  return (id: string, customErrorState?: InputErrorState | boolean) => {
    const inputStateID = ElementStateLookup.INPUT + id;
    const inputState: InputControlState | undefined = data[inputStateID];
    if (inputState) {
      const newInputState = produce(inputState, (draftState) => {
        const error = !inputState.errorState.error;
        console.log("setting new error state in toggle", error, inputState);
        if (customErrorState !== undefined) {
          if (typeof customErrorState !== "boolean") {
            draftState.errorState = customErrorState;
          } else {
            draftState.errorState.error = customErrorState;
          }
        } else {
          draftState.errorState.error = error;
        }
      });
      const SET_INPUT_ERROR_STATUS = { type: "data", payload: { field: inputStateID, value: newInputState } };
      dispatch(SET_INPUT_ERROR_STATUS);
    }
  };
}

function checkNamespaceStateValid(
  type: ElementStateLookup,
  namespace: { id: string; value: any; raw?: any },
  errorStateSetter?: any,
) {
  console.log("in namespace valid check!", namespace);
  let pass = true;
  switch (type) {
    // add all other state widget/namespace enum types
    case ElementStateLookup.INPUT:
      if (namespace.raw?.required === true) {
        if (namespace.value === "" || namespace.value === undefined) {
          pass = false;
          errorStateSetter(namespace.id, true);
        }
        errorStateSetter(namespace.id, false);
      }
      break;
    case ElementStateLookup.QUERY_BUILDER:
      if (namespace.value?.clauses.length === 0) {
        pass = false;
      }
      break;
  }
  return pass;
}

export function useCheckNamespacesStateValidForSubmit() {
  const queryParams = useLocation().search;
  const inputErrorSetter = useToggleInputErrorState();
  // genericize for all state type cases
  // implement hook that returns dispatch cb that toggles error state for a given namespace/element

  return (
    namespaceTypes: Array<ElementStateLookup> | string | null | undefined,
    namespacePayload: Array<{ id: string; value: any }>[],
    nextParams: string | {} | undefined,
    submitMessages?: any,
  ) => {
    if (
      !checkObjNotEmpty(namespacePayload) ||
      queryParams === nextParams ||
      (typeof nextParams === "object" && !checkObjNotEmpty(nextParams))
    ) {
      return false;
    }
    if (namespaceTypes === null || namespaceTypes === undefined || submitMessages === undefined) {
      return true;
    }
    if (typeof namespaceTypes === "string") {
      try {
        namespaceTypes = JSON.parse(namespaceTypes) as Array<ElementStateLookup>;
      } catch (err) {
        // implement error boundary
        console.log("could not parse namespace data from props/nb/server", err);
        return true;
      }
    }
    let pass = true;
    namespacePayload.forEach((namespaceData, idx) => {
      for (let i = 0; i <= namespaceData.length; i++) {
        const namespaceDatum = namespaceData[i];
        if (namespaceDatum !== undefined) {
          const isValidState = checkNamespaceStateValid(namespaceTypes![idx] as any, namespaceDatum, inputErrorSetter);
          if (!isValidState) {
            pass = false;
            break;
          }
        }
      }
    });
    return pass;
  };
}
