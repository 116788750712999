import React, { useEffect } from "react";
import { DateRange, DateRangeInput, IDateFormatProps, TimePrecision, IDateRangeShortcut } from "@blueprintjs/datetime";
import { Button } from "@blueprintjs/core";
import { FORMATS } from "./formatSelect";
import { ElementStateLookup, usePageNamespaceData } from "../../state";

export interface DateRangeOptions {
  allowSingleDayRange: boolean;
  closeOnSelection: boolean;
  contiguousCalendarMonths: boolean;
  disabled: boolean;
  enableTimePicker: boolean;
  format: IDateFormatProps;
  reverseMonthAndYearMenus: boolean;
  selectAllOnFocus: boolean;
  shortcuts: boolean | IDateRangeShortcut[];
  singleMonthOnly: boolean;
}

export interface DateRangeProps {
  content: IDateRangeContent;
  id: string;
  title?: string;
}

export interface IDateRangeContent {
  range: DateRange;
  callback?: string;
  options?: Partial<DateRangeOptions>;
}

export const defaultOptions: DateRangeOptions = {
  allowSingleDayRange: true,
  closeOnSelection: true,
  contiguousCalendarMonths: true,
  disabled: false,
  enableTimePicker: true,
  format: FORMATS[0],
  reverseMonthAndYearMenus: false,
  selectAllOnFocus: false,
  shortcuts: true,
  singleMonthOnly: false,
};

export const DateRangeWidget = (props: DateRangeProps) => {
  const {
    id,
    content: { range: propsRange, options },
  } = props;
  const { enableTimePicker, format, ...restOptions } = defaultOptions;
  const formattedPropsRange = propsRange.map((d) => new Date(d as unknown as string)) as DateRange;
  const [range, setRange, destroyState] = usePageNamespaceData<DateRange>(    
    id,
    ElementStateLookup.DATE_RANGE,
    formattedPropsRange)
  useEffect(() => () => destroyState(), [destroyState])

  const timePrecision = enableTimePicker ? TimePrecision.MINUTE : undefined;

  const handleRangeChange = (updatedRange: DateRange) => {
    setRange(updatedRange);
  };

  function handleClear(event: any) {
    event.preventDefault();
    if (range) {
      setRange(propsRange);
    }
  }

  return (
    <DateRangeInput
      {...restOptions}
      {...format}
      {...options}
      value={range}
      onChange={handleRangeChange}
      timePrecision={timePrecision}
      startInputProps={{
        // fill: true,
        leftIcon: "calendar",
      }}
      popoverProps={{minimal: true}}
      endInputProps={{
        rightElement:
          range[0] !== null && range[1] !== null ? (
            <Button icon="small-cross" onClick={handleClear} small minimal />
          ) : undefined,
        // fill: true,
      }}
    />
  );
};
