import { Radio, RadioGroup } from "@blueprintjs/core";
import { IDateFormatProps } from "@blueprintjs/datetime";
import moment from "moment";
import * as React from "react";

export interface IFormatSelectProps {
  /** Selected formatter. */
  format: IDateFormatProps;

  /** The callback to fire when a new formatter is chosen. */
  onChange: (format: IDateFormatProps) => void;
}

/** Event handler that exposes the target element's value as a boolean. */
export function handleBooleanChange(handler: (checked: boolean) => void) {
  return (event: React.FormEvent<HTMLElement>) => handler((event.target as HTMLInputElement).checked);
}

/** Event handler that exposes the target element's value as a string. */
export function handleStringChange(handler: (value: string) => void) {
  return (event: React.FormEvent<HTMLElement>) => handler((event.target as HTMLInputElement).value);
}

/** Event handler that exposes the target element's value as a number. */
export function handleNumberChange(handler: (value: number) => void) {
  return handleStringChange((value) => handler(+value));
}

export class FormatSelect extends React.PureComponent<IFormatSelectProps> {
  private handleChange = handleNumberChange((index) => this.props.onChange(FORMATS[index]));

  public render() {
    const value = FORMATS.indexOf(this.props.format);
    return (
      <RadioGroup label="Date format" onChange={this.handleChange} selectedValue={value}>
        {FORMATS.map((format, index) => (
          <Radio key={index} label={format.placeholder} value={index} />
        ))}
      </RadioGroup>
    );
  }
}

export const FORMATS: IDateFormatProps[] = [
  {
    formatDate: (date) => (date === null ? "" : date.toLocaleDateString()),
    parseDate: (str) => new Date(Date.parse(str)),
    placeholder: "JS Date",
  },
  momentFormatter("MM/DD/YYYY"),
  momentFormatter("YYYY-MM-DD"),
  momentFormatter("YYYY-MM-DD HH:mm:ss"),
];

function momentFormatter(format: string): IDateFormatProps {
  return {
    formatDate: (date) => moment(date).format(format),
    parseDate: (str) => moment(str, format).toDate(),
    placeholder: `${format} (moment)`,
  };
}
