import React, { useContext } from "react";
import { useImmerReducer } from "use-immer";
import { IResizeEntry, ResizeSensor, Colors, Classes } from "@blueprintjs/core";
import { useQuery } from "react-query";
import { useSpring, config } from "react-spring";

import { useView } from "../routing";
import { useAuthState } from ".";
import { SideNav } from "../views";

// update field type
export enum MainActions {
  toggleExpandedNav,
  setMainDimensions,
  toggleTheme,
}
export enum Theme {
  DARK,
  LIGHT,
}
export enum DataFetchState {
  INITIAL,
  UPDATE,
  COMPLETE,
}

interface dispatchFieldUpdate {
  fieldName: TMainStoreFetchFields;
  value: IMainStore[TMainStoreFetchFields];
}

export type TMainDispatchAction = {
  type: MainActions;
  payload?: string | dispatchFieldUpdate;
};

type TDispatch = (action: TMainDispatchAction) => void;

type MainProviderProps = { children: React.ReactNode };

interface IMainStore {
  expandedNavMode: boolean;
  theme: Theme;
  mainDimensions: { width: number; height: number } | null;
}

type TMainStoreFetchFields = keyof Omit<IMainStore, "error" | "expandedNavMode" | "fetchingRequiredData" | "theme">;

const initialState: IMainStore = {
  expandedNavMode: true,
  theme: Theme.DARK,
  mainDimensions: null,
};

// create fn to determine # of skeleton loaders when data being fetched, render out skeletons while loading - # via screen size
// either here or in routing: set up unknown route/view handler -> 404 page + take me back; later: suggestions (potentially)
// implement themeing

function mainReducer(draft: IMainStore, action: TMainDispatchAction) {
  switch (action.type) {
    case MainActions.toggleExpandedNav: {
      draft.expandedNavMode = !draft.expandedNavMode;
      return;
    }
    case MainActions.toggleTheme: {
      if (draft.theme === Theme.LIGHT) {
        draft.theme = Theme.DARK;
      } else {
        draft.theme = Theme.LIGHT;
      }
      return;
    }
    case MainActions.setMainDimensions: {
      const { payload } = action;
      draft.mainDimensions = payload as any;
      return;
    }
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}

const MainContext = React.createContext<IMainStore | undefined>(undefined);
const MainDispatchContext = React.createContext<TDispatch | undefined>(undefined);

export function MainContextProvider({ children }: MainProviderProps) {
  const [state, dispatch] = useImmerReducer(mainReducer, initialState);
  const view = useView();
  // + (view === "Dashboards" ? " dashboard-scroll" : "")

  const springProps = useSpring({
    zIndex: state.expandedNavMode ? 1000 : 10,
    paddingLeft: state.expandedNavMode ? "264px" : "104px",
    delay: 0,
    config: { ...config.default, duration: 25 },
  });

  function handleResize(entries: IResizeEntry[]) {
    const SET_DIMENSIONS: TMainDispatchAction = {
      type: MainActions.setMainDimensions,
      payload: {
        width: entries[0].contentRect.width,
        height: entries[0].contentRect.height,
      } as any,
    };
    dispatch(SET_DIMENSIONS);
  }

  return (
    <MainContext.Provider value={state}>
      <MainDispatchContext.Provider value={dispatch}>
        <div
          className={state.theme === Theme.LIGHT ? "" : Classes.DARK}
          style={{
            backgroundColor: state.theme === Theme.LIGHT ? "#E6F7FF" : Colors.DARK_GRAY3,
          }}
        >
          <ResizeSensor onResize={handleResize}>
            <section
              className={
                "eic-content-wrapper" +
                (state.expandedNavMode ? " expanded" : "") +
                (view !== "Dashboards" ? " fullview" : "")
              }
              style={springProps}
            >
              {children}
            </section>
          </ResizeSensor>
          <SideNav />
        </div>
      </MainDispatchContext.Provider>
    </MainContext.Provider>
  );
}

export function useMainState() {
  const context = useContext(MainContext);
  if (context === undefined) {
    throw new Error("useAuthState must be used within a AuthContextProvider");
  }
  return context;
}

export function useMainDispatch() {
  const context = useContext(MainDispatchContext);
  if (context === undefined) {
    throw new Error("useAuthDispatch must be used within a AuthContextProvider");
  }
  return context;
}

export function useSideNavData() {
  const {
    client,
    user: { user_id },
  } = useAuthState();

  const sideNavData = useQuery("globalnav", async () => await client.getSideNavData(user_id));

  return sideNavData as any;
}
