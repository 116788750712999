import produce from "immer";
import React, { useEffect } from "react";
import { Button, Card, ControlGroup, HTMLSelect, Icon, IconName, IMenuItemProps, IMenuProps, InputGroup, Intent, IPopoverProps, Menu, MenuItem, Popover, Position, Spinner, Tag, Tooltip } from "@blueprintjs/core";
import { ItemListRenderer, ItemRenderer, Select } from "@blueprintjs/select";

import { ElementStateLookup, usePageNamespaceData } from "../../state";
import { ACInputComponentData, FieldData, SelectOption, useValidationAutoCompleteData, ValidationConditionComponentData, ValidationRuleNoPredicate as NoPredicateComponent } from "../ValidationBuilder"
import {
  StringTupleAutoComplete as ActionBuilderAutoComplete,
  renderMenu,
  renderItem,
  filterItem,
  renderInputValue,
  areTagsEqual,
} from "..";


import "../styling/ValidationBuilder.css";
import { useImmer } from "use-immer";

interface DefaultOptions {
  allowCreate: boolean;
  closeOnSelect: boolean;
  createdFilterByItems: [];
  fill: boolean;
  minimal: boolean;
  outlined: boolean;
  inline: boolean;
  large: boolean;
  openOnKeyDown: boolean;
  resetOnClose: boolean;
  resetOnQuery: boolean;
  resetOnSelect: boolean;
}

export interface DEIDActionBuilderProps {
  id: string;
  value: DEIDActionBuilderDatum[];
  values: DEIDActionBuilderData; // value and values in this case are confusing -> find a better naming convention
  options: Partial<DefaultOptions>;
}

interface DEIDActionBuilderData {
  actionTypes: DEIDSelectOption[];
  fields: DEIDFieldData[];
  transformTypes: TransformTypeDatum[];
}

interface DEIDFieldData extends FieldData {
  expanders?: Expander[];
  expander?: Expander; 
}

interface Expander {
  type: EXPANDER_TYPE | string;
  label?: string;
  intent: Intent;
  icon?: IconName;
  bgColor?: string;
}

enum EXPANDER_TYPE {
  EXACT_MATCH = "exactmatch",
  CONTAINS = "contains",
  STARTS_WITH = "startswith",
  ENDS_WITH = "endswith",
  ALL_EXCEPT = "allexcept",
  EXCEPT = "except"
}

interface TransformTypeDatum {
  type: TRANSFORM_TYPE | string;
  label: string;
  component: TRANSFORM_INPUT_COMPONENT_TYPE;
  data: ValidationConditionComponentData;
}

interface DEIDActionBuilderDatum {
  action: DEIDSelectOption;
  selectedField: DEIDFieldData;
  transform: TransformValue;
}

interface DEIDSelectOption extends SelectOption {
  type: ACTION_TYPE | SelectOption["type"]; 
}

enum ACTION_TYPE {
  ADD = "ADD",
  BLANK = "BLANK",
  JITTER = "JITTER",
  KEEP = "KEEP",
  REMOVE = "REMOVE",
  REPLACE = "REPLACE"
}

interface TransformValue {
  type: TRANSFORM_TYPE | string;
  value: any;
}

enum TRANSFORM_TYPE {
  FREE_TEXT = "FreeText",
  REMAP_VARIABLE = "RemapVariable",
  REMAP_FUNCTION = "RemapFunction",
  LOOKUP_DICOM = "LookupDicom",
  JITTER = "Jitter"
}

enum TRANSFORM_INPUT_COMPONENT_TYPE {
  FREE_TEXT_INPUT = "FreeTextInput",
  AUTOCOMPLETE_INPUT = "AutoCompleteInput",
  SELECT = "Select",
}

const defaultOptions: DefaultOptions = {
  allowCreate: false,
  closeOnSelect: true,
  createdFilterByItems: [],
  fill: false,
  minimal: true,
  outlined: false,
  inline: false,
  large: true,
  openOnKeyDown: false,
  resetOnClose: false,
  resetOnQuery: true,
  resetOnSelect: false,
};

export function DEIDActionBuilder({id, value, values, options: propsOptions, ...props}: DEIDActionBuilderProps) {
  const options = { ...defaultOptions, ...propsOptions };
  const { large, outlined, minimal, fill } = options;

  const [actionBuilderState, setActionBuilderState, destroyState] = usePageNamespaceData<DEIDActionBuilderDatum[]>(
    id,
    ElementStateLookup.DEID_ACTION_BUILDER,
    value,
  );

  useEffect(() => {
    setActionBuilderState(value);
    return () => destroyState();
  }, [value, setActionBuilderState, destroyState]);

  function handleActionTypeChange(e: any, DEIDActionIdx: number) {
    const newActionType = values.actionTypes.find((c) => c.type === e.type);
    if (actionBuilderState[DEIDActionIdx] && typeof newActionType !== "undefined") {
      setActionBuilderState(
        produce(actionBuilderState, (draftState) => {
          draftState[DEIDActionIdx].action = newActionType;
        }),
      );
    }
  }

  function handleExpanderTypeChange(e: any, DEIDActionIdx: number) {
    const fieldData = values.fields.find((ft) => ft.type === actionBuilderState[DEIDActionIdx].selectedField.type)!;
    const newExpanderType = fieldData.expanders?.find((exp) => exp.type === e.type);
    if (actionBuilderState[DEIDActionIdx] && typeof newExpanderType !== "undefined") {
      setActionBuilderState(
        produce(actionBuilderState, (draftState) => {
          draftState[DEIDActionIdx].selectedField.expander = newExpanderType;
          draftState[DEIDActionIdx].selectedField.value = ["", ""]
        }),
      );
    }
  }

  function handleSelectedFieldACInputChange(v: any, DEIDActionIdx: number) {
    if (actionBuilderState[DEIDActionIdx]) {
      setActionBuilderState(
        produce(actionBuilderState, (draftState) => {
          draftState[DEIDActionIdx].selectedField.value = v;
        }),
      );
    }
  }

  function handleTransformTypeChange(e: any, DEIDActionIdx: number) {
    const t = e.target.value as TransformValue["type"];
    if (actionBuilderState[DEIDActionIdx]?.transform) {
      setActionBuilderState(
        produce(actionBuilderState, (draftState) => {
          draftState[DEIDActionIdx].transform.type = t;
          draftState[DEIDActionIdx].transform.value = values.transformTypes.find(
            (tt) => tt.type === t,
          )?.data?.defaultValue;
        }),
      );
    }
  }
  function handleTransformValueChange(
    e: any,
    type: TRANSFORM_INPUT_COMPONENT_TYPE,
    DEIDActionIdx: number,
  ) {
    let updatedValue = e;
    if (actionBuilderState[DEIDActionIdx]?.transform) {
      setActionBuilderState(
        produce(actionBuilderState, (draftState) => {
          if (
            [TRANSFORM_INPUT_COMPONENT_TYPE.SELECT, TRANSFORM_INPUT_COMPONENT_TYPE.FREE_TEXT_INPUT].includes(
              type,
            )
          ) {
            updatedValue = e.target.value;
          } 
          draftState[DEIDActionIdx].transform.value = updatedValue;
        }),
      );
    }
  }

  function addAction(duplicateActionIdx?: number) {
    setActionBuilderState(
      produce(actionBuilderState, (draftState: any) => {
        if (typeof duplicateActionIdx === "undefined") {
          draftState.push(value[0]);
        } else if (typeof duplicateActionIdx !== "undefined" && draftState[duplicateActionIdx]) {
          draftState.push(draftState[duplicateActionIdx]);
        }
      }),
    );
  }
  function deleteAction(DEIDActionIdx: number) {
    setActionBuilderState(
      produce(actionBuilderState, (draftState) => {
        draftState.splice(DEIDActionIdx, 1);
      }),
    );
  }

  return (    
  <Card>
    {actionBuilderState.map((v: any, idx: number) => (
      <DEIDAction
        {...v}
        values={values}
        key={"DEIDActionComponent__" + idx}
        idx={idx}
        options={options}
        handleActionTypeChange={handleActionTypeChange}
        handleExpanderTypeChange={handleExpanderTypeChange}
        handleSelectedFieldACInputChange={handleSelectedFieldACInputChange}
        handleTransformTypeChange={handleTransformTypeChange}
        handleTransformValueChange={handleTransformValueChange}
        addAction={addAction}
        deleteAction={deleteAction}
      />
    ))}
    <Button
      text="Action"
      intent={Intent.PRIMARY}
      large={large}
      minimal={minimal}
      outlined={outlined}
      fill={fill}
      icon="add"
      className="validation-builder-add-rule-btn"
      onClick={() => addAction()}
    />
  </Card>)
}

interface ActionTagSelectOption extends DEIDSelectOption, Partial<DefaultOptions> {}
const renderTagSelectOption: ItemRenderer<ActionTagSelectOption> = (selectOption, itemProps) => {
  const { intent, type, label, bgColor, minimal, large, fill, info } = selectOption;
  const { handleClick, modifiers } = itemProps;
  return (
    <MenuItem
      active={modifiers.active}
      disabled={modifiers.disabled}
      onClick={handleClick}
      style={{ padding: "5px 12px" }}
      labelElement={
        typeof info !== "undefined" ? (
          <Tooltip content={info} intent={intent} placement="bottom" minimal={minimal} usePortal={true}>
            <Icon icon="info-sign" style={{ paddingTop: "4px", paddingLeft: "2px" }} />
          </Tooltip>
        ) : undefined
      }
      text={
        <Tag
          intent={intent}
          minimal={minimal}
          large={large}
          fill={fill}
          style={{ backgroundColor: bgColor, textAlign: "center" }}
        >
          {label ?? type}
        </Tag>
      }
    />
  );
};
const renderTagSelectMenu: ItemListRenderer<ActionTagSelectOption> = ({ items, itemsParentRef, renderItem }) => {
  const renderedItems = items.map(renderItem).filter((item) => item != null);
  const large = items[0].large;
  return (
    <Menu ulRef={itemsParentRef} large={large} style={{ minWidth: large ? "120px" : "96px", width: "max-content" }}>
      {renderedItems}
    </Menu>
  );
};

const ActionBuilderSelect = Select.ofType<DEIDSelectOption>();

interface DEIDActionComponentProps extends DEIDActionBuilderDatum {
  key: string;
  idx: number;
  options: Partial<DefaultOptions>;
  values: DEIDActionBuilderData;
  handleActionTypeChange: (e: any, DEIDActionIdx: number) => void;
  handleExpanderTypeChange: (e: any, DEIDActionIdx: number) => void;
  handleSelectedFieldACInputChange: (v: any, DEIDActionIdx: number) => void;
  handleTransformTypeChange: (t: any, DEIDActionIdx: number) => void;
  handleTransformValueChange: (
    e: any,
    type: TRANSFORM_INPUT_COMPONENT_TYPE,
    DEIDActionIdx: number,
  ) => void;
  addAction: (DEIDActionIdx: number) => void;
  deleteAction: (DEIDActionIdx: number) => void;
}

function DEIDAction({
  idx,
  values,
  options,
  handleActionTypeChange,
  handleExpanderTypeChange,
  handleSelectedFieldACInputChange,
  handleTransformTypeChange,
  handleTransformValueChange,
  addAction,
  deleteAction,
  ...value}: DEIDActionComponentProps) {
  const { large, inline, minimal, fill, outlined } = options;
  const largeClassName = large ? "bp3-input-group bp3-large bp3-input" : "";
  const { fields, actionTypes, transformTypes } = values;
  const { selectedField, action, transform } = value;

  return (
  <Card>
    <Tag
      minimal={minimal}
      large={large}
      round={true}
      intent={action.intent ?? Intent.NONE}
      className="validation-rule-component-badge"
      style={{ marginTop: large ? ".5%" : undefined, marginLeft: large ? undefined : "-32px" }}
    >
      {idx + 1}
    </Tag>
    <ControlGroup fill={fill} vertical={inline} style={{ paddingTop: "2px", paddingBottom: "2px" }}>
      <div className={"validation-builder-inline-text-block " + largeClassName} style={{ width: "106px" }}>
        <span>Action: </span>
      </div>
      <ActionBuilderSelect
        items={actionTypes.map((ac) => ({ ...ac, ...options }))}
        {...options}
        itemRenderer={renderTagSelectOption}
        itemListRenderer={renderTagSelectMenu}
        filterable={false}
        onItemSelect={(ac) => handleActionTypeChange(ac, idx)}
        activeItem={action}
        popoverProps={{
          minimal,
          fill,
          placement: "bottom-start",
          position: Position.BOTTOM_LEFT,
          popoverClassName: "validation-rule-select-popover-container",
        }}
      >
        <Button
          rightIcon="caret-down"
          large={large}
          minimal={minimal}
          outlined={outlined}
          fill={fill}
          className="validation-rule-select-button"
        >
          <Tag
            fill={fill}
            large={large}
            minimal={minimal}
            intent={action.intent}
            style={{ backgroundColor: action.bgColor, textAlign: "center" }}
          >
            {action.type}
          </Tag>
        </Button>
      </ActionBuilderSelect>
      <div className={"validation-builder-inline-text-block " + largeClassName} style={{ width: "106px" }}>
        <span>{fields[0].type}: </span>
      </div>
      {
        typeof fields[0].expanders !== "undefined" && typeof selectedField.expander !== "undefined" &&
        <ActionBuilderSelect
          items={fields[0].expanders.map((ac) => ({ ...ac, ...options }))}
          {...options}
          itemRenderer={renderTagSelectOption}
          itemListRenderer={renderTagSelectMenu}
          filterable={false}
          onItemSelect={(exp) => handleExpanderTypeChange(exp, idx)}
          activeItem={selectedField.expander}
          popoverProps={{
            minimal,
            fill,
            placement: "bottom-start",
            position: Position.BOTTOM_LEFT,
            popoverClassName: "validation-rule-select-popover-container",
          }}
        >
          <Button
            rightIcon="caret-down"
            large={large}
            minimal={minimal}
            outlined={outlined}
            fill={fill}
            className="validation-rule-select-button"
          >
            <Tag
              fill={fill}
              large={large}
              minimal={minimal}
              intent={selectedField.expander.intent}
              style={{ backgroundColor: selectedField.expander.bgColor, textAlign: "center" }}
            >
              {selectedField.expander.label ?? selectedField.expander.type}
            </Tag>
          </Button>
        </ActionBuilderSelect>
      }
      {typeof selectedField.expander === "undefined" || selectedField.expander.type === EXPANDER_TYPE.EXACT_MATCH ? 
        <ActionBuilderAutoComplete
          items={fields[0].values}
          {...options}
          itemRenderer={renderItem}
          itemListRenderer={renderMenu}
          itemPredicate={filterItem}
          inputValueRenderer={renderInputValue}
          itemsEqual={areTagsEqual}
          onItemSelect={(val) => handleSelectedFieldACInputChange(val, idx)}
          selectedItem={selectedField.value}
          popoverProps={{ minimal, fill: true, placement: "bottom-start" }}
          inputProps={{ 
            className: large ? "bp3-large" : undefined, 
            leftIcon: "tag", 
            fill: true, 
            large 
          }}
        /> :
        <InputGroup
          placeholder="Enter a field value"
          value={selectedField.value![1]}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleSelectedFieldACInputChange(
              [selectedField.expander?.type, e.target.value],
              idx
            )
          }
          fill
          large={large}
        />
      }
      <Popover
        content={
          <Menu {...(options as IMenuProps)}>
            <MenuItem
              icon="trash"
              onClick={() => deleteAction(idx)}
              shouldDismissPopover={true}
              {...(options as IMenuItemProps)}
              intent={Intent.DANGER}
              text="Remove"
            />
            <MenuItem
              icon="duplicate"
              onClick={() => addAction(idx)}
              shouldDismissPopover={true}
              {...(options as IMenuItemProps)}
              text="Duplicate"
            />
          </Menu>
        }
        placement="bottom"
        {...(options as IPopoverProps)}
      >
        <Button
          icon="more"
          large={large}
          minimal={minimal}
          outlined={outlined}
          className="validation-rule-select-button"
        />
      </Popover>
    </ControlGroup>
    {action.hasPredicate ? (
      <div style={{ paddingTop: "2px" }}>
        <Card className="eic-content-card" style={{ padding: "0px", margin: "0px" }}>
          <div style={{ display: "inline-flex", width: "100%" }}>
            <div
              className={"validation-builder-inline-text-block " + largeClassName}
              style={{
                minWidth: "106px",
                height: "auto",
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                display: "flex",
              }}
            >
              <div style={{ alignSelf: "center" }}>Transform: </div>
            </div>
            <div style={{ display: "block", width: "100%", height: "auto", paddingTop: "2px", paddingBottom: "2px" }}>
              <DEIDTransform
                key={"ValidationRuleComponent__" + idx + "__TransformComponent__"}
                actionIdx={idx}
                options={options}
                transformTypes={transformTypes}
                largeClassName={largeClassName}
                selectedField={selectedField}
                handleTransformTypeChange={handleTransformTypeChange}
                handleTransformValueChange={handleTransformValueChange}
                {...transform}
              />
            </div>
            {/* ^ shouldnt take up full width; should have plus button; each rule/condition should have delete or duplicate option */}
          </div>
        </Card>
      </div>
    ) : (
      <NoPredicateComponent />
    )}
  </Card>);
}

// individual validation rule condition where predicate calls for a collection of validation rule conditions
interface DEIDTransformComponentProps extends TransformValue {
  options: Partial<DefaultOptions>;
  actionIdx: number;
  key?: string;
  transformTypes: TransformTypeDatum[];
  largeClassName?: string;
  selectedField: FieldData;
  handleTransformTypeChange: (t: any, DEIDActionIdx: number) => void;
  handleTransformValueChange: (
    e: any,
    type: TRANSFORM_INPUT_COMPONENT_TYPE,
    DEIDAction: number,
  ) => void;
}

function DEIDTransform({
  options,
  largeClassName,
  actionIdx,
  selectedField,
  type,
  value,
  transformTypes,
  handleTransformTypeChange,
  handleTransformValueChange,
}: DEIDTransformComponentProps) {
  const { minimal, large } = options;
  return (
    <ControlGroup
      fill={true}
      vertical={false}
      style={{ paddingTop: "2px", paddingBottom: "2px", paddingLeft: "4px", paddingRight: "4px" }}
    >
      <div
        className={"validation-builder-inline-text-block " + largeClassName}
        style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
      >
        <span>Type: </span>
      </div>
      <div style={{ width: "280px" }}>
        <HTMLSelect
          options={transformTypes.map((o) => o.type)}
          value={type}
          onChange={(e: any) => handleTransformTypeChange(e, actionIdx)}
          minimal={minimal}
          large={large}
          className="validation-vanilla-select validation-rule-select-button"
          fill
        />
      </div>
      <TransformInputWidgetRenderer
        options={options}
        actionIdx={actionIdx}
        value={value}
        selectedField={selectedField}
        type={type}
        transformTypes={transformTypes}
        largeClassName={largeClassName}
        handleTransformValueChange={handleTransformValueChange}
      />
    </ControlGroup>
  );
}


function TransformInputWidgetRenderer({
  type: validationConditionType,
  value,
  actionIdx,
  handleTransformValueChange,
  options: {
    minimal,
    fill,
    large,
  },
  transformTypes,
  largeClassName,
  selectedField,
}: Omit<
  DEIDTransformComponentProps,
  "handleTransformTypeChange"
>) {
  const widgetTypeData = transformTypes.find((t) => t.type === validationConditionType);
  let widget = <div />;
  const err = <pre>Malformed DEID Transform Data</pre>;
  if (widgetTypeData) {
    const { type: widgetType, component, data } = widgetTypeData;
    const {
      type,
      icon,
      tagIntent,
      color,
      bgColor: backgroundColor,
      intent,
      defaultValue,
      values,
    } = data;
    const widgetId = `${validationConditionType}__${widgetType}__${type ?? "N/A"}:${actionIdx}`;
    const leftElement =
      typeof type !== "undefined" ? (
        <span className="bp3-input-left-container">
          <Tag minimal={minimal} large={large} intent={tagIntent ?? intent}>
            {type.toUpperCase()}
          </Tag>
        </span>
      ) : undefined;
    const leftIcon = typeof leftElement !== "undefined" ? undefined : icon;
    switch (component) {
      case TRANSFORM_INPUT_COMPONENT_TYPE.AUTOCOMPLETE_INPUT:
        widget = (
          <FieldAutoComplete
            id={widgetId}
            minimal={minimal}
            value={value}
            large={large}
            endpointData={data}
            selectedField={selectedField}
            actionIdx={actionIdx}
            handleTransformValueChange={handleTransformValueChange}
          />
        );
        break;
      case TRANSFORM_INPUT_COMPONENT_TYPE.FREE_TEXT_INPUT:
        widget = (
          <InputGroup
            id={widgetId}
            value={value}
            fill={true}
            large={large}
            leftElement={leftElement}
            leftIcon={leftIcon}
            intent={intent}
            style={{
              color,
              backgroundColor,
            }}
            // onchange
            defaultValue={defaultValue}
            placeholder={
              "Enter a " +
              (type === "Regex" ? "pattern" : "value") +
              (selectedField?.value?.[1] ? " for Tag: " + selectedField?.value?.[1] : "")
            }
            onChange={(e) =>
              handleTransformValueChange(
                e,
                TRANSFORM_INPUT_COMPONENT_TYPE.FREE_TEXT_INPUT,
                actionIdx,
              )
            }
          />
        );
        break;
      case TRANSFORM_INPUT_COMPONENT_TYPE.SELECT:
        // implement label as tool tip
        widget = (
          <ControlGroup id={widgetId} fill={true} vertical={false}>
            <div
              className={"validation-builder-inline-text-block " + largeClassName}
              style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0, width: widgetType.length > 12 ? 240: undefined }}
            >
              <span>{widgetType}: </span>
            </div>
            <HTMLSelect
              options={values?.map((o) => o)}
              value={value}
              minimal={minimal}
              large={large}
              className="validation-vanilla-select validation-rule-select-button"
              fill
              onChange={(e) =>
                handleTransformValueChange(
                  e,
                  TRANSFORM_INPUT_COMPONENT_TYPE.SELECT,
                  actionIdx,
                )
              }
            />
          </ControlGroup>
        );
        break;
      default:
        widget = err;
    }
  } else {
    widget = err;
  }
  return widget;
}

interface FieldAutoCompleteProps extends Partial<DefaultOptions> {
  endpointData: Partial<ACInputComponentData>;
  selectedField: FieldData;
  id: string;
  value: any;
  actionIdx: number;
  handleTransformValueChange: (
    e: any,
    type: TRANSFORM_INPUT_COMPONENT_TYPE,
    validationRuleIdx: number,
  ) => void;
  // onchange
}
function FieldAutoComplete({
  id,
  actionIdx,
  endpointData,
  selectedField,
  handleTransformValueChange,
  large,
  fill,
  minimal,
  value,
  // onchange
  ...options
}: FieldAutoCompleteProps) {
  const [displayStandardInput, setDisplayStandardInput] = useImmer<boolean>(false);
  const { data, isLoading } = useValidationAutoCompleteData(selectedField, endpointData, id);
  const items = data?.content ?? [];
  return data === null || items.length === 0 || displayStandardInput ? (
    <InputGroup
      placeholder="Enter a match value"
      value={value[1]}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        handleTransformValueChange(
          ["Unstructured Search Term", e.target.value],
          TRANSFORM_INPUT_COMPONENT_TYPE.AUTOCOMPLETE_INPUT,
          actionIdx
        )
      }
      fill
      large={large}
      rightElement={
        <Button
          large={large}
          minimal={minimal}
          outlined={true}
          icon="search"
          text="Autocomplete"
          disabled={data === null || items.length === 0}
          onClick={() => {
            setDisplayStandardInput((d) => false);
          }}
        />
      }
    />
  ) : (
    <ActionBuilderAutoComplete
      items={items}
      {...options}
      itemRenderer={renderItem}
      itemListRenderer={renderMenu}
      itemPredicate={filterItem}
      inputValueRenderer={renderInputValue}
      itemsEqual={areTagsEqual}
      onItemSelect={(val) =>
        handleTransformValueChange(
          val,
          TRANSFORM_INPUT_COMPONENT_TYPE.AUTOCOMPLETE_INPUT,
          actionIdx,
        )
      }
      selectedItem={value}
      popoverProps={{ minimal, fill, placement: "bottom-start" }}
      inputProps={{
        className: large ? "bp3-large" : undefined,
        leftIcon: isLoading ? <Spinner intent={Intent.NONE} size={Spinner.SIZE_SMALL} tagName="span" className="eic-ac-loading"/> : "search",
        rightElement: (
          <Button
            large={large}
            minimal={minimal}
            outlined={true}
            icon="add"
            text="Create New"
            onClick={() => {
              setDisplayStandardInput((d) => true);
            }}
          />
        ),
        fill: true,
        large,
      }}
      disabled={isLoading}
      fill
    />
  );
}

