import React from "react";
import { useHistory, useLocation, Link } from "react-router-dom";
import { IBreadcrumbProps, Breadcrumb } from "@blueprintjs/core";

// const views = [
//   "Dashboards",
//   // "Reports",
//   "Admin",
//   "CANs",
//   "Assets",
//   // "Workflows",
//   "Search",
//   "Page",
//   "Jupyter",
//   // "Study Management",
//   // "Users",
//   // "Rules",
//   "404",
//   ""
// ];
export function isUndefined<T, D>(item: T, defaultValue: D): T | D {
  if (item === undefined) {
    return defaultValue;
  }
  return item;
}

export function isNull<T, D>(item: T, defaultValue: D): T | D {
  if (item === null) {
    return defaultValue;
  }
  return item;
}

export function whenNotUndefined<T, D>(item: T, value: D): D | void {
  if (item !== undefined) {
    return value;
  }
}

export function whenTrue<T, D>(check: boolean | undefined, trueItem: T, falseItem: D): D | T {
  if (check !== undefined && check === true) {
    return trueItem;
  }
  return falseItem;
}

export function capitalize(s: any) {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
}

// refactor to base or main view - should only take the first /__/ value
export function useView() {
  const location = useLocation();
  const history = useHistory();
  let view = capitalize(location.pathname.split("/")[1]);
  if (view === "Cans") {
    view = "CANs";
  }
  if (view === "") {
    history.push("/Page/Staging:Cans_Resoundant/Cans");
    view = "Page";
  }

  return view;
}

export function useGetBasePathname() {
  const { pathname } = useLocation();
  const basePathname = "/" + pathname.split("/")[1];
  return basePathname;
}

export function useGetNestedPathname(depth?: number) {
  const location = useLocation();
  const pathStubs = location.pathname.split("/");
  if (depth !== undefined) {
    return pathStubs[depth] || "";
  }
  return pathStubs[pathStubs.length - 1];
}

// breadcrumb generators and utils
export function renderCurrentBreadcrumb({ text, ...restProps }: IBreadcrumbProps) {
  return <Breadcrumb {...restProps}>{text}</Breadcrumb>;
}

export function renderBreadcrumb({ text, href, ...restProps }: IBreadcrumbProps) {
  const breadcrumb = <Breadcrumb {...restProps}>{text}</Breadcrumb>;
  if (href) {
    return <Link to={href as string}>{breadcrumb}</Link>;
  }
  return breadcrumb;
}

export function useBreadcrumbs() {
  const location = useLocation();

  const BREADCRUMBS: IBreadcrumbProps[] = location.pathname
    .split("/")
    .slice(1)
    .map((pathname: string, idx: number, input: string[]) => {
      const text = capitalize(pathname);
      if (idx + 1 === input.length) {
        return { text };
      }
      const href = "/" + input.slice(0, idx + 1).join("/");
      return { href, text };
    });

  return BREADCRUMBS;
}
// end breadcrumb utils

export function useQueryParams() {
  return new URLSearchParams(useLocation().search);
}

export function useReplaceParams() {
  const params = useQueryParams();
  const location = useLocation();
  const history = useHistory();
  return (newParams: any) => {
    Object.entries(newParams).forEach(([k, p]) => {
      const formattedParamVal = typeof p !== "string" ? JSON.stringify(p) : p;
      params.set(k, formattedParamVal);
    });
    history.push(location.pathname + "?" + params.toString());
  };
}

export function useRouterVariables() {
  const queryParams = useQueryParams();
  // pull vars off query params
  return queryParams;
}

export function useUpdateURLTabParam() {
  const qp = new URLSearchParams();
  const updateHistory = useHistory().push;

  return (newTabId: string, prevTabId: string, e: any) => {
    qp.set("tab_id", newTabId);
    let tabNBParams = e.target.dataset.eicAttrs
    try {
      tabNBParams = JSON.parse(tabNBParams)
      for (let key in tabNBParams) {
        qp.append(key, tabNBParams[key])
      }
    } catch (exc: any) {
      console.error("failed to parse tab component NB params: ", exc, {tabNBParams})
    }
    const qpString = qp.toString();
    updateHistory({ search: qpString });
  };
}
