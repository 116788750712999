import React, { ReactNode, useEffect } from "react";
import { Route, useParams } from "react-router-dom";
import { PageProvider } from "../../state";
import { useGetNotebookViewContent, WidgetController } from "../../widgets/WidgetController";
import { Html2Widget } from "../../widgets/Html2Widget";
import { Icon, Intent, Spinner } from "@blueprintjs/core";
import { Dashboard } from "./Dashboard";
import { useClearUIStateLocalStorage } from "../../Utils";
import { ErrorWidget } from "../../widgets";
// import { FlowChart } from "./FlowChart";

export const PageView = () => {
  const page = (
    <>
      <Route path={"/Page/:notebook/:cell/:ids?"}>
        <PageProvider>
          <PageComponent type="page" />
        </PageProvider>
      </Route>
      <Route path={"/Dash/:notebook/:cell/:ids?"}>
        <PageProvider>
          <PageComponent type="dashboard" />
        </PageProvider>
      </Route>
    </>
  );
  return page;
};

type TPageProps = {
  type: "page" | "dashboard";
};

function PageComponent(props: TPageProps) {
  const localStorageDestroyer = useClearUIStateLocalStorage();
  useEffect(() => {
    return () => {
      localStorageDestroyer();
    };
  }, [localStorageDestroyer]);
  const { notebook, cell }: any = useParams();
  const [{ status, data: d }] = useGetNotebookViewContent(notebook, cell, {});
  const data: any = d;

  let widget: ReactNode;

  if (status === "loading") {
    widget = (
      <div style={{ height: "100vh" }}>
        <ErrorWidget
          id={notebook + cell}
          content={{
            icon: <Spinner intent={Intent.PRIMARY} size={Spinner.SIZE_LARGE} />,
            title: "Loading...",
          }}
        />
      </div>
    );
  } else if (status === "error") {
    widget = (
      <div style={{ height: "100vh" }}>
        <ErrorWidget
          id={notebook + cell}
          content={{
            icon: <Icon icon="warning-sign" intent={Intent.DANGER} iconSize={Spinner.SIZE_LARGE} />,
            title: "Failed to load assets",
            description: `Error loading from path: ${notebook}/${cell}`,
          }}
        />
      </div>
    );
  } else {
    if (data["type"] === "dashboard") {
      return <Dashboard {...data} />;
    }
    widget = <span />;
    data["id"] = notebook + ":" + cell;
    data["tag"] = cell;
    if (!Array.isArray(data)) {
      switch (data["type"]) {
        case "html2":
          widget = <Html2Widget {...data} />;
          break;
        // case "flowchart":
        //   widget = <FlowChart {...data} />;
        //   break;
        default:
          widget = (
            <div style={{ height: "100vh" }}>
              <WidgetController cell={data} />
            </div>
          );
      }
    } else {
      widget = (
        <div>
          {data.map((item: any, idx: number) => (
            <div key={idx} style={{ overflowX: "scroll" }}>
              <WidgetController cell={item} />
            </div>
          ))}
        </div>
      );
    }
  }

  return <div id="PAGE">{widget}</div>;
}
