import React from "react";
import { Navbar, Alignment } from "@blueprintjs/core";

import { WidgetLoader } from "./WidgetController";

export interface INavBarProps {
  id: string;
  content: INavBarContent;
  expandedNavMode: boolean;
}

export interface INavBarContent {
  fixedToTop?: boolean;
  height?: number;
  leftContent?: any;
  rightContent?: any;
  title?: string;
}

export function NavBar(props: INavBarProps) {
  const { expandedNavMode, content } = props;
  const { height, fixedToTop, leftContent, rightContent, title } = content;

  return (
    <Navbar
      fixedToTop={fixedToTop}
      style={{
        left: expandedNavMode ? "240px" : "80px",
        width: `calc(100vw - ${expandedNavMode ? "240px" : "80px"})`,
        height: (height !== undefined ? height : 72) + "px",
        paddingTop: "8px",
      }}
    >
      {title && (
        <Navbar.Group align={Alignment.LEFT}>
          <Navbar.Heading>
            <span style={{ paddingTop: "8px", textTransform: "uppercase" }}>{title}</span>
          </Navbar.Heading>
        </Navbar.Group>
      )}
      {leftContent && (
        <Navbar.Group align={Alignment.LEFT}>{leftContent.map((widget: any) => WidgetLoader(widget))}</Navbar.Group>
      )}
      {rightContent && (
        <Navbar.Group align={Alignment.RIGHT}>{rightContent.map((widget: any) => WidgetLoader(widget))}</Navbar.Group>
      )}
    </Navbar>
  );
}
