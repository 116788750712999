import { Button, Card, Collapse, H1, H3, Intent } from "@blueprintjs/core"
import React from "react"
import { useImmer } from "use-immer"

import {QueryBuilder, QueryBuilderProps} from "../"

export interface DEIDFilterBuilderProps {
    id: string;
    filterName: string;
    dicomDictValues: any; // type properly
    options: any;
    data: DEIDFilterDatum[];
}

interface DEIDFilterDatum extends QueryBuilderProps {
    label: string;
    coordinates: any; // nested array
}

export function DEIDFilterBuilder({id, filterName, dicomDictValues, options, data, ...props}: DEIDFilterBuilderProps) {
    const [collapsedListState, setCollapsedListState] = useImmer(data.map((d, idx) => idx === 0 ? true : false))
    const toggleCollapsedState = (idx: number) => {
        setCollapsedListState(draft => {
            draft[idx] = !draft[idx]
        })
    } 

    const toggleAll = (open = true) => {
        setCollapsedListState(draft => {
            return data.map((d) => open)
        })
    } 
    return (
        <div>
            <Button onClick={() => toggleAll()} rightIcon="expand-all">
                Open All Filter Sets
            </Button>
            <Button onClick={() => toggleAll(false)} rightIcon="collapse-all">
                Collapse All Filter Sets
            </Button>
            {data.map((d, idx) => {
                const { value, id, label, ...options } = d;
                return (
                    <Card>
                        <H3>{label}</H3>
                        <div>
                            <Collapse isOpen={collapsedListState[idx]}>
                                <QueryBuilder value={value} values={dicomDictValues} id={id} options={options}/>
                            </Collapse>
                            <Button onClick={() => toggleCollapsedState(idx)} fill minimal intent={Intent.PRIMARY} rightIcon={collapsedListState[idx] ? "caret-up": "caret-down"}>
                                {collapsedListState[idx] ? "Hide" : "Show"} Filter Criteria
                            </Button>
                        </div>
                    </Card>
                )
            }
            )}
        </div>
    )
}