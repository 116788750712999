import React, { useEffect } from "react";
import { Button, TagInput, IconName, MaybeElement, Intent, Label } from "@blueprintjs/core";
import { ElementStateLookup, usePageNamespaceData } from "../state";
import produce from "immer";

export interface ITokenInputContent {
  icon?: IconName | MaybeElement;
  rightIcon?: IconName | MaybeElement;
  placeholder?: string;
  disabled?: boolean;
  fill?: boolean;
  intent?: Intent;
  large?: boolean;
  tagMinimal?: boolean;
  label?: string;
  required?: boolean;
}

export interface ITokenInputProps {
  id: string;
  content: ITokenInputContent;
}

export function TokenInput({ id, content }: ITokenInputProps) {
  const { disabled, fill, large, icon, rightIcon, placeholder, label, required } = content;
  // initial value should come from props or default to the currently hardcoded value, type properly
  const initialValue = { values: [] };
  const [inputState, setInputState, destroyState] = usePageNamespaceData<any>(
    id,
    ElementStateLookup.TOKEN_INPUT,
    initialValue,
  );
  useEffect(() => {
    // add flag to conditionally destroy state -> prop/notebook driven
    return () => destroyState();
  }, [destroyState]);

  const handleChange = (values: React.ReactNode[]) => {
    setInputState(
      produce(inputState, (draftState: any) => {
        draftState["values"] = values;
        return draftState;
      }),
    );
  };
  const handleClear = () => handleChange([]);

  const clearButton =
    inputState.values.length > 0 || rightIcon ? (
      <Button
        icon={inputState.values.length > 0 ? "cross" : rightIcon}
        minimal={true}
        disabled={inputState.values.length === 0}
        onClick={() => handleClear()}
      />
    ) : undefined;

  const input = (
    <TagInput
      // {...props}
      className="eic-tag-input-wrapper flex"
      leftIcon={icon || "search"}
      onChange={(e) => handleChange(e)}
      placeholder={placeholder || "Search"}
      rightElement={clearButton}
      fill={fill}
      large={large}
      disabled={disabled}
      // tagProps={getTagProps}
      values={inputState.values}
    />
  );

  return label ? (
    <Label>
      <span className="eic-label">{label}</span>
      {!disabled && required && "*"}
      {input}
    </Label>
  ) : (
    input
  );
}
