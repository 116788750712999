import * as React from "react";
import { Card, Elevation, H1, H3, Intent, Spinner } from "@blueprintjs/core";
import "./styling/StatisticWidget.css";
import { useTableContextData } from ".";
import { ElementStateLookup, useAuthState, useGetNamespacesByType, usePageNamespaceDataCB } from "../state";
import { useEffect } from "react";
import { useQuery } from "react-query";

interface StatisticOptions {
  fill: boolean; // implement
  minimal: boolean; // implement
  outlined: boolean; // implement
  inline: boolean; // implement
  large: boolean; // implement
  intent: Intent;
  elevation: Elevation;
  interactive: boolean;
  applyIntentToLabel: boolean;
  backgroundColor: React.CSSProperties["backgroundClip"];
}

interface StatisticCalculation {
  type: StatisticCalcType;
}

enum StatisticCalcType {
  LENGTH = "length",
  LENGTH_NOT_EMPTY = "length_not_empty",
  TOTAL_NUM_SELECTED = "total_num_selected",
}

export interface StatisticWidgetContent {
  defaultValue?: number | string;
  value?: number | string;
  label?: string;
  options?: Partial<StatisticOptions>;
  notebook?: string;
  cell?: string;
  stateNamespaces?: string;
  stateSubscriberId?: string;
  dataProviderName?: string;
  dataProviderType?: ElementStateLookup;
  calc?: StatisticCalculation;
  style?: Partial<React.CSSProperties>;
  // html as value
}

export interface StatisticWidgetProps {
  id: string;
  content: StatisticWidgetContent;
}

function useNBCalc(nb: string | undefined, cell: string | undefined, value: any) {
  const { client } = useAuthState();

  const calcData = useQuery(["statisticCalc", nb, cell, value], async () => {
    if (nb && cell) {
      return await client.newExecNb(nb, cell, { value });
    }
    return null;
  });
  return calcData as any;
}

export function StatisticWidget({
  id,
  content: {
    notebook,
    cell,
    label,
    value,
    defaultValue,
    options,
    stateNamespaces,
    stateSubscriberId,
    dataProviderName,
    dataProviderType,
    calc,
    style,
  },
}: StatisticWidgetProps) {
  const [tableSubscriberData] = useTableContextData(stateSubscriberId, "");
  const namespaceDataGetter = useGetNamespacesByType();
  const namespaceDatumGetter = usePageNamespaceDataCB();
  console.log("state in stat namespace: ", stateNamespaces)
  let calcValue: any;
  let subscriberDataEntries: Array<[string, any]> = [];
  if (typeof dataProviderName !== "undefined" && typeof dataProviderType !== "undefined") {
    subscriberDataEntries = Object.entries(
      namespaceDatumGetter<{ data: any[] }>(dataProviderName, dataProviderType)[0]["data"] as any,
    ).filter(([key, val]) => key !== "");
  } else if (tableSubscriberData) {
    subscriberDataEntries = Object.entries(tableSubscriberData).filter(([key, val]) => key !== "");
    console.log("sub", { subscriberDataEntries });
  } else if (stateNamespaces) {
    const nameSpaceData = namespaceDataGetter(stateNamespaces);
    console.log("stat namespace: ", nameSpaceData)
    nameSpaceData.forEach((namespace) => {
      console.log("in loop stat namespace: ", namespace)
      namespace.forEach((d) => {
        if (d.id !== "") {
          subscriberDataEntries[d.id as any] = d.value;
        }
      });
    })
  }

  if (typeof calc !== "undefined" && subscriberDataEntries.length > 0) {
    switch (calc.type) {
      case StatisticCalcType.LENGTH:
        calcValue = subscriberDataEntries.length;
        break;
      case StatisticCalcType.LENGTH_NOT_EMPTY:
        calcValue = subscriberDataEntries.reduce((length, el) => (el[1]?.length > 0 ? length + 1 : length), 0);
        break;
      case StatisticCalcType.TOTAL_NUM_SELECTED:
        calcValue = subscriberDataEntries.reduce((totalNum, el) => totalNum + el[1].length, 0);
        break;
      default:
        calcValue = "" + tableSubscriberData;
        break;
    }
  }

  const { data, isLoading } = useNBCalc(notebook, cell, subscriberDataEntries);
  if (data && data.type !== "nodata") {
    calcValue = data;
    console.log("calc value is: ", calcValue);
  }

  return (
    <Card
      elevation={options?.elevation ?? Elevation.TWO}
      interactive={options?.interactive}
      className={"statistic-card intent-" + (options?.intent ?? "default")}
      style={{ backgroundColor: options?.backgroundColor, ...style }}
    >
      {isLoading ? (
        <Spinner size={Spinner.SIZE_LARGE} />
      ) : (
        <H1 className={"statistic-value intent-" + (options?.intent ?? "default")}>
          {calcValue ?? value ?? defaultValue}
        </H1>
      )}
      <H3
        className={"statistic-label" + (options?.applyIntentToLabel ? " intent-" + (options?.intent ?? "default") : "")}
      >
        {label}
      </H3>
    </Card>
  );
}
