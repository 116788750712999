import React, { useEffect } from "react"
import produce from "immer";
import { Button, Position, Tag } from "@blueprintjs/core"


import { ElementStateLookup, usePageNamespaceData } from "../../state";
import { renderSelectOption, renderSelectMenu, SelectOption, SelectPrimitive, BaseSelectWidgetStyleOptions } from ".";

import "../styling/ValidationBuilder.css"



export interface SelectWidgetContent {
    options: BaseSelectWidgetStyleOptions;
    data: SelectOption[];
    selectedValue: SelectOption;
}

export interface SelectWidgetProps {
    id: string;
    content: SelectWidgetContent
}

export function SelectWidget(props: SelectWidgetProps) {
    const {content: {options, data, selectedValue}, id} = props
    const {large, fill, minimal, outlined, intent, icon, rightIcon, disabled } = options
    const [selectState, setSelectState, destroyState] = usePageNamespaceData<SelectOption>(
        id,
        ElementStateLookup.SELECT,
        selectedValue,
    );
    useEffect(() => () => destroyState(), [destroyState]);
    

    return (
    <SelectPrimitive
        items={data.map((c) => ({ large, fill, minimal, outlined, ...c }))}
        itemRenderer={renderSelectOption}
        itemListRenderer={renderSelectMenu}
        filterable={false}
        onItemSelect={(item) => setSelectState(produce(selectState, draft => item))}
        activeItem={selectState}
        popoverProps={{
            placement: "bottom-start",
            position: Position.BOTTOM_LEFT,
            popoverClassName: "validation-rule-select-popover-container",
        }}
        disabled={disabled}
        {...options}
    >
        <Button
            rightIcon={disabled ? "disable" : rightIcon ?? "caret-down"}
            large={large}
            minimal={minimal}
            outlined={outlined}
            fill={fill}
            intent={intent}
            icon={icon}
            className="validation-rule-select-button"
            disabled={disabled}
        >
        <Tag
            fill={selectState.fill}
            large={selectState.large}
            minimal={selectState.minimal}
            intent={selectState.intent}
            round={selectState.round}
            style={{ backgroundColor: selectState.bgColor, textAlign: "center" }}
        >
            {selectState.text}
        </Tag>
        </Button>
    </SelectPrimitive>
  )
}