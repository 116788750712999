import * as React from "react";
import {
  // Button,
  Hotkey,
  Hotkeys,
  MenuItem,
  Position,
  Toaster,
} from "@blueprintjs/core";
import { ItemPredicate, ItemRenderer, Omnibar } from "@blueprintjs/select";

import { handleBooleanChange } from "./Utils";
import { HotkeysTarget } from "@blueprintjs/core/lib/esnext/components/hotkeys/hotkeysTarget.js";

const CmdPalette = Omnibar.ofType<IHost>();

export interface ICommandPaletteState {
  allowCreate: boolean;
  isOpen: boolean;
  resetOnSelect: boolean;
}

export interface IHost {
  label: string;
  hostname: string;
  port?: number;
  tld?: "" | ".ai" | ".com";
  protocol: "http" | "https";
}

const hosts: IHost[] = [
  {
    label: "stack.eicon.ai",
    hostname: "35.174.20.78",
    protocol: "http",
    port: 22005,
  },
  {
    label: "LOCAL DEV",
    hostname: "localhost",
    protocol: "http",
    port: 5001,
  },
];

const formatURL = (host: IHost) =>
  `${host.protocol}://${host.label}${host.tld !== undefined ? host.tld : ""}${
    host.port !== undefined ? ":" + host.port : ""
  }`;

const itemRenderer: ItemRenderer<IHost> = (host, { handleClick, modifiers, query, index }) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }
  const text = formatURL(host);
  return (
    <MenuItem
      active={modifiers.active}
      disabled={modifiers.disabled}
      label={host.label}
      key={text + index}
      onClick={handleClick}
      text={highlightText(text, query)}
    />
  );
};

function highlightText(text: string, query: string) {
  let lastIndex = 0;
  const words = query
    .split(/\s+/)
    .filter((word) => word.length > 0)
    .map(escapeRegExpChars);
  if (words.length === 0) {
    return [text];
  }
  const regexp = new RegExp(words.join("|"), "gi");
  const tokens: React.ReactNode[] = [];
  while (true) {
    const match = regexp.exec(text);
    if (!match) {
      break;
    }
    const length = match[0].length;
    const before = text.slice(lastIndex, regexp.lastIndex - length);
    if (before.length > 0) {
      tokens.push(before);
    }
    lastIndex = regexp.lastIndex;
    tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
  }
  const rest = text.slice(lastIndex);
  if (rest.length > 0) {
    tokens.push(rest);
  }
  return tokens;
}

function escapeRegExpChars(text: string) {
  return text.replace(/([.*+?^=!:${}()|[\]/\\])/g, "\\$1");
}

function areHostsEqual(hostA: IHost, hostB: IHost) {
  // Compare only the titles (ignoring case) just for simplicity.
  return formatURL(hostA) === formatURL(hostB);
}

const filterHost: ItemPredicate<IHost> = (query, host, _index, exactMatch) => {
  const normalizedTitle = host.label.toLowerCase();
  const normalizedQuery = query.toLowerCase();

  if (exactMatch) {
    return normalizedTitle === normalizedQuery;
  } else {
    return formatURL(host).indexOf(normalizedQuery) >= 0;
  }
};
// export function createHost(: string): IFilm {
//   return {
//     rank: 100 + Math.floor(Math.random() * 100 + 1),
//     title,
//     year: new Date().getFullYear(),
//   };
// }

@HotkeysTarget
export class CommandPalette extends React.PureComponent<any, ICommandPaletteState> {
  public state: ICommandPaletteState = {
    allowCreate: false,
    isOpen: false,
    resetOnSelect: true,
  };

  private handleAllowCreateChange = handleBooleanChange((allowCreate) => this.setState({ allowCreate }));
  private handleResetChange = handleBooleanChange((resetOnSelect) => this.setState({ resetOnSelect }));

  private toaster: Toaster | undefined;
  private refHandlers = {
    toaster: (ref: Toaster) => (this.toaster = ref),
  };

  public renderHotkeys() {
    return (
      <Hotkeys>
        <Hotkey
          global={true}
          combo="shift + up"
          label="Show Omnibar"
          onKeyDown={this.handleToggle}
          // prevent typing "O" in omnibar input
          preventDefault={true}
        />
      </Hotkeys>
    );
  }

  public render() {
    // const { allowCreate } = this.state;

    // const maybeCreateNewItemFromQuery = allowCreate ? createFilm : undefined;
    // const maybeCreateNewItemRenderer = allowCreate ? renderCreateFilmOption : null;

    return (
      <>
        <CmdPalette
          itemPredicate={filterHost}
          items={hosts}
          itemRenderer={itemRenderer}
          {...this.state}
          // createNewItemFromQuery={maybeCreateNewItemFromQuery}
          // createNewItemRenderer={maybeCreateNewItemRenderer}
          itemsEqual={areHostsEqual}
          noResults={<MenuItem disabled={true} text="No results." />}
          onItemSelect={this.handleItemSelect}
          onClose={this.handleClose}
        />
        <Toaster position={Position.TOP} ref={this.refHandlers.toaster} />
      </>
    );
  }

  private handleClick = (_event: React.MouseEvent<HTMLElement>) => {
    this.setState({ isOpen: true });
  };

  private handleItemSelect = (item: IHost) => {
    this.setState({ isOpen: false });

    this.toaster?.show({
      message: (
        <span>
          You selected <strong>{item}</strong>.
        </span>
      ),
    });
  };

  private handleClose = () => this.setState({ isOpen: false });

  private handleToggle = () => this.setState({ isOpen: !this.state.isOpen });
}
