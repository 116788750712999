import React, { useRef } from "react";
import { H6, InputGroup, NumericInput, ControlGroup, Checkbox, Label, Button } from "@blueprintjs/core";
import { useAuthState, usePage, usePageDispatch } from "../state";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router";
import { AppToaster } from "../App";
import { TreeWidget, ITreeContent } from "./TreeWidget";

export interface IFormProps {
  id: string;
  content: IFormContent;
}

export enum FormControlType {
  TEXT_INPUT = "text",
  NUMBER_INPUT = "number",
  CHECKBOX = "checkbox",
  BUTTON_GROUP = "button_group",
  TREE = "tree",
}

export interface IFormButton {
  type: string;
  label: string;
  action?: string;
}
export interface IFormControl {
  type: FormControlType;
  field: string;
  label: string;
  content: any;
  value?: string;
  checked?: boolean;
  required?: boolean;
  pattern?: string;
  min?: number;
  max?: number;
  buttons: IFormButton[];
}

export interface IFormContent {
  title?: string;
  field: string;
  callback: {
    notebook: string;
    cell: string;
  };
  widgets: IFormControl[];
}

export interface IResponse {
  msg?: string;
  error?: string;
  action:
    | string
    | {
        notebook: string;
        cell: string;
      };
}

export function FormWidget(props: IFormProps) {
  const { client } = useAuthState();
  const queryClient = useQueryClient();
  const { content } = props;
  const { title, field, callback, widgets } = content;
  const controlRefs: any[] = [];
  const formRef = useRef(null);
  const history = useHistory();
  const state = usePage();
  const dispatch = usePageDispatch();
  // Save Data
  const saveForm = (ev: any) => {
    const payload: any = {};
    ev.preventDefault();
    if (formRef !== undefined && formRef.current !== undefined) {
      if (!((formRef.current! as unknown) as any).reportValidity()) {
        return;
      }
    }
    payload[field] = state.data;
    client.execNb(callback.notebook, callback.cell, payload).then((rc) => {
      const resp = rc.data as IResponse;
      AppToaster.show({ message: resp.msg });
      let path = resp.action;
      if (typeof path === "object") {
        path = `/Page/${path.notebook}/${path.cell}`;
      }
      queryClient.clear();
      history.replace(path);
    });
  };

  const updateState = (field: string, value: any) => {
    dispatch({ type: "data", payload: { field: field, value: value } });
  };

  const renderTitle = () => {
    if (title) {
      return (
        <div style={{ display: "inline-block", width: "100%" }} className="eic-markdown">
          <H6>{title}</H6>
          <hr />
        </div>
      );
    }
    return <span />;
  };

  const renderButton = (item: IFormButton, idx: number) => {
    switch (item.type) {
      case "submit":
        return (
          <Button
            text={item.label}
            intent="success"
            icon="saved"
            type="submit"
            key={idx}
            onClick={(ev: any) => saveForm(ev)}
          />
        );
      case "close":
        return (
          <Button text={item.label} intent="danger" icon="ban-circle" key={idx} onClick={() => history.goBack()} />
        );
      default:
        return <span key={idx} />;
    }
  };

  const renderControl = (item: IFormControl, idx: number) => {
    switch (item.type) {
      case FormControlType.TREE:
        const content = item.content as ITreeContent;
        content.callback = (value: string) => updateState(content.field, value);
        return <TreeWidget key={idx} id={"tree" + idx} content={item.content as ITreeContent} />;
      case FormControlType.TEXT_INPUT:
        return (
          <ControlGroup fill={true} vertical={false} key={idx}>
            <span className="label">
              {item.label}
              <InputGroup
                id={item.field}
                placeholder={item.label}
                required={item.required}
                pattern={item.pattern}
                ref={(b) => {
                  controlRefs.push(b);
                }}
                onChange={(ev: any) => updateState(item.field, ev.target.value)}
              />
            </span>
          </ControlGroup>
        );
      case FormControlType.NUMBER_INPUT:
        return (
          <ControlGroup fill={true} vertical={false} key={idx}>
            <Label>
              {item.label}
              <NumericInput
                id={item.field}
                placeholder={item.label}
                min={item.min}
                max={item.max}
                clampValueOnBlur={true}
                onChange={(ev: any) => updateState(item.field, ev.target.value)}
              />
            </Label>
          </ControlGroup>
        );
      case FormControlType.CHECKBOX:
        return (
          <ControlGroup fill={true} vertical={false} key={idx}>
            <Label className=".bp3-inline">
              {item.label}
              <Checkbox id={item.field} onChange={(ev: any) => updateState(item.field, ev.target.value)} key={idx} />
            </Label>
          </ControlGroup>
        );
      case FormControlType.BUTTON_GROUP:
        return (
          <ControlGroup fill={false} vertical={false} key={idx}>
            {item.buttons.map((b: IFormButton, idx: number) => renderButton(b, idx))}
          </ControlGroup>
        );
      default:
        return <span key={idx} />;
    }
  };

  return (
    <div className="eic-form">
      {renderTitle()}
      <form ref={formRef}>{widgets.map((item: IFormControl, idx: number) => renderControl(item, idx))}</form>
    </div>
  );
}
