import React from "react";
import { Slider, Intent } from "@blueprintjs/core";
import { useState } from "react";
import { useHistory } from "react-router-dom";

export interface ISliderProps {
  content: ISliderContent;
  id: string;
}

export interface ISliderContent {
  disabled: boolean;
  initialValue: number;
  intent: Intent;
  min: number;
  max: number;
  labelStepSize: number;
  stepsize: number;
  value: number;
  labelRenderer: boolean;
  vertical: boolean;
  callback?: string;
  field?: string;
}

export function SliderWidget(props: ISliderProps) {
  const [slide, setSlide] = useState();
  const history = useHistory();

  function onRelease() {
    if (props.content.callback && props.content.field) {
      history.push(props.content.callback + "?" + props.content.field + "=" + slide);
    }
  }

  return (
    <Slider
      disabled={props.content.disabled}
      initialValue={props.content.initialValue}
      intent={props.content.intent}
      min={props.content.min}
      max={props.content.max}
      labelStepSize={props.content.labelStepSize}
      stepSize={props.content.stepsize}
      value={slide}
      onChange={(e: any) => setSlide(e)}
      onRelease={(e: any) => onRelease()}
      labelRenderer={props.content.labelRenderer}
      vertical={props.content.vertical}
    />
  );
}
